import React, { useEffect } from "react";
import { Button, Grid, Box, Avatar, Paper, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import loginImg from "../assets/images/login-img.png";
import logo from "../assets/images/talentLabLogoBlack.png";

import "./login.css";

export interface LoginProps {}

export const Login = ({}: LoginProps): JSX.Element => {
	const { loginWithPopup } = useAuth0();

	return (
		<Grid
			container
			id="container"
			component="main"
			sx={{
				backgroundColor: "#EAF6FB",
				display: "flex",
				justifyContent: "space-between",
				alignContent: "center",
				flexWrap: "wrap",
				height: "100vh",
				position: "relative",
				zIndex: 2,
			}}
		>
			<Grid
				item
				sx={{
					backgroundColor: "rgba(255, 255, 255, 0.50)",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
					width: { xs: "100%", lg: "50%" },
					maxWidth: { xs: "unset", lg: "950px" },
					borderTopRightRadius: { xs: 0, lg: "1000px" },
					borderBottomRightRadius: { xs: 0, lg: "1000px" },
					boxShadow: "0px 0px 16px 0px rgba(212, 243, 254, 0.10)",
				}}
			>
				{" "}
				<img
					src={logo}
					alt=""
					style={{
						height: "36px",
						position: "absolute", // Add this line
						top: "32px", // Adjust the top value as needed
						left: "56px", // Ad
						width: "214px",
					}}
				/>
				<Box
					sx={{
						display: { xs: "block", lg: "none" },
						minWidth: { xs: "unset", lg: "750px" },
					}}
				>
					<img
						src={loginImg}
						alt="login-img-banner"
						style={{
							height: "100%",
							width: "100%",

							padding: "0 112px",
						}}
					/>
				</Box>
				<Typography
					sx={{
						fontSize: "64px",
						marginBottom: 0,
						fontWeight: 400,
						lineHeight: "96px",
					}}
				>
					Hello!
				</Typography>
				<Typography
					sx={{
						fontSize: "36px",
						fontWeight: 400,
						lineHeight: "48px",
						marginBottom: 0,
					}}
				>
					Let&rsquo;s get you inside the Lab.
				</Typography>
				<Button
					color="secondary"
					variant="contained"
					sx={{
						marginTop: "24px",
						maxWidth: "300px",
						width: "100%",
						padding: "16px 32px",
						marginBottom: 0,
						color: "#fff",
					}}
					onClick={() => {
						const element = document.getElementById("container");
						element?.classList.add("animated-exit");

						setTimeout(() => {
							element?.classList.remove("animated-exit");
							loginWithPopup();
						}, 1200);
					}}
				>
					Sign In
				</Button>
			</Grid>
			<Grid
				item
				sx={{
					alignItems: "center",
					justifyContent: "center",
					height: "100%",
					width: "50%",
					display: { xs: "none", lg: "flex" },
				}}
			>
				<img
					src={loginImg}
					alt="login-img-banner"
					style={{
						height: "100%",
						width: "100%",
						maxWidth: "924px",
						maxHeight: "500px",
						padding: "0 112px",
					}}
				/>
			</Grid>
		</Grid>
	);
};

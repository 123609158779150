import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { RoleStatus } from "../../enums/addRole";
export interface VectorProps {
  status?: string;
}
export const VectorStrokeIcon = ({ status }: VectorProps) => {
  return (
    <SvgIcon  width="19.17" height="20" viewBox="0 0 17 18">
      <svg
        width="19"
        height="20"
        viewBox="0 0 23 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3271 2.21967C17.0464 1.92678 16.5913 1.92678 16.3106 2.21967L2.13684 17.0097C2.00253 17.1498 1.92684 17.3398 1.92633 17.538L1.91675 21.248C1.91624 21.4476 1.992 21.6392 2.12727 21.7803C2.26253 21.9215 2.44614 22.0005 2.63743 22L6.19286 21.99C6.38281 21.9895 6.56484 21.9105 6.69915 21.7703L20.8729 6.98033C21.1536 6.68743 21.1536 6.21256 20.8729 5.91967L17.3271 2.21967ZM3.35619 20.498L3.36302 17.8515L14.1496 6.59599C14.1663 6.61786 14.1844 6.63898 14.2038 6.65924L16.6188 9.17923C16.638 9.19932 16.6581 9.21804 16.6789 9.23538L5.8924 20.4908L3.35619 20.498ZM17.689 8.18131L19.3482 6.45L16.8188 3.81066L15.1596 5.54202C15.1806 5.55947 15.2008 5.57832 15.2202 5.59858L17.6352 8.11857C17.6545 8.13866 17.6724 8.15961 17.689 8.18131Z"
          fill={status === RoleStatus.Processing ? "#ABABAB" : "#FFF"}
        />
      </svg>
    </SvgIcon>
  );
};

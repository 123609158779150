import React, { useState, useRef, useEffect } from "react";
import {
  Typography,
  Box,
  CircularProgress,
  Modal,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import Dropzone, { useDropzone } from "react-dropzone";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { UploadIcon } from "../utilities/icons/uploadIcon";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
export interface UploadProps {
  onUpload: (file: File, updateProgress: (progress: number) => void) => void;
  onChoseFile: (file: File | null) => void;
  handleModalIsOpen: (modalIsOpen: boolean) => void;
  uploadModalIsOpen: boolean;
}

export const Upload = ({
  onUpload,
  onChoseFile,
  handleModalIsOpen,
  uploadModalIsOpen,
}: UploadProps): JSX.Element => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { open } = useDropzone({
    noClick: true,
  });
  const handleUpload = async (files: any) => {
    if (files && files.length > 0) {
      const allowedFileTypes = ["doc", "pdf", "docx"];
      const file = files[0];
      const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
      const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes

      if (!allowedFileTypes.includes(fileExtension)) {
        setError("We only accept .doc and .pdf files.");

        onChoseFile(null);
        setModalOpen(false);
        setSelectedFile(null);
        return;
      }
      if (file.size > maxFileSize) {
        onChoseFile(null);
        setModalOpen(false);
        setError(
          "Oops, the file size is too large. Please reduce the file size to less than 10MB and try again."
        );
        setSelectedFile(null);
        return;
      }
      if (files.length) {
        setError("");
      }

      setSelectedFile(file);
      onChoseFile(file);
    }
  };

  useEffect(() => {
    if (uploadModalIsOpen) {
      setModalOpen(true);
    }
  }, [uploadModalIsOpen]);

  const handleModalClose = (): void => {
    setModalOpen(false);
  };

  const handleChooseFile = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    handleModalIsOpen(modalOpen);
  }, [modalOpen]);

  const handleRemoveFileFromModal = (): void => {
    setSelectedFile(null);
    onChoseFile(null);
  };

  const handleConfirmUpload = async () => {
    if (selectedFile) {
      setUploading(true);
      const totalSize = selectedFile.size;
      let uploadedSize = 0;

      const updateProgress = (progress: any) => {
        uploadedSize = progress;
        const progressPercentage = Math.round((uploadedSize / totalSize) * 100);
        setUploadProgress(progressPercentage);
      };

      try {
        await onUpload(selectedFile, updateProgress);
        setError(null);
      } catch (error) {
      } finally {
        setUploading(false);
        setUploadProgress(0);
        setError(null);
        handleModalClose();
      }
    }
  };

  return (
    <>
      <Box
        sx={{ textAlign: "center" }}
        onClick={() => {
          handleChooseFile();
        }}
      >
        <Dropzone
          multiple={true}
          onDrop={(files) => {
            handleUpload(files);
          }}
          noClick
        >
          {({ getRootProps, getInputProps, acceptedFiles, isDragActive }) => {
            return (
              <Box
                {...getRootProps()}
                sx={{
                  textAlign: "center",
                  backgroundColor: isDragActive ? "#F0EAF2" : "#F6F6F6",
                  padding: "6%",
                  cursor: "pointer",
                  border: "1px dashed  #ABABAB",
                  borderRadius: "16px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  height: "184px",
                  alignItems: "center",
                  ":hover": {
                    backgroundColor: "#F0EAF2",
                    border: "1px solid #9F14E0",
                  },
                }}
              >
                <input
                  {...getInputProps()}
                  key={selectedFile ? selectedFile.name : "default"}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
                <FileUploadOutlinedIcon onClick={open} />
                <Typography variant="h3" sx={{ fontSize: "20px" }}>
                  Drag and Drop Job Role Here
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "12px" }}>
                  Maximum file size: 10MB
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "12px" }}>
                  Accepted File Types: .doc, .pdf
                </Typography>{" "}
                {error && (
                  <Typography sx={{ color: "#DC362E", fontSize: "11px" }}>
                    {error}
                  </Typography>
                )}
              </Box>
            );
          }}
        </Dropzone>

        {uploading && (
          <Box sx={{ marginTop: 2 }}>
            <CircularProgress
              variant="determinate"
              value={uploadProgress}
              size={80}
              thickness={4}
            />
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              Uploading: {uploadProgress}%
            </Typography>
          </Box>
        )}
      </Box>
      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
              borderRadius: "16px",
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.25rem",
                fontWeight: "700",
                lineHeight: "30px",
                padding: "8px 0px",
                color: "#000000",
              }}
            >
              Job Description
            </Typography>
            <CloseOutlinedIcon
              onClick={handleModalClose}
              sx={{
                width: "1.4rem",
                height: "1.4rem",
                position: "absolute",
                right: "26px",
                top: "28px",
              }}
            />
          </Box>
        </DialogTitle>
        <Divider sx={{ margin: "0 8px" }}></Divider>
        <DialogContent sx={{ padding: "16px 24px" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "24px",
                color: "#585858",
              }}
            >
              This process may take a moment to complete. While waiting, feel
              free to explore the dashboard and add search parameters and ideal
              candidates.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "16px 0",
                alignItems: "center",
              }}
            >
              {selectedFile && (
                <Typography
                  variant="body1"
                  sx={{ display: "flex", fontSize: "0.75rem" }}
                >
                  <UploadIcon />{" "}
                  <span style={{ whiteSpace: "nowrap", alignSelf: "center", paddingLeft: "5px", paddingTop: "2px" }}>
                    {" "}
                    {selectedFile.name}{" "}
                  </span>
                </Typography>
              )}

              {selectedFile && (
                <Box sx={{ display: "flex" }}>
                  <CloseOutlinedIcon
                    onClick={handleRemoveFileFromModal}
                    sx={{
                      width: "1.4rem",
                      height: "1.4rem",
                      color: "#000000",
                    }}
                  />
                </Box>
              )}
            </Box>
            <Button
              onClick={handleChooseFile}
              sx={{
                padding: "5px 16px",
                backgroundColor: "#8A00CC",
                color: "#FFF",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "#CE66FF",
                  boxShadow: "none",
                },
              }}
            >
              Choose Another File
            </Button>
          </DialogContentText>
        </DialogContent>
        <Divider sx={{ margin: "0 8px" }}></Divider>
        <DialogActions sx={{ padding: "16px" }}>
          <Box>
            <Button
              variant="text"
              sx={{
                color: "#181818",
                padding: "5px 16px",
                borderRadius: "10px",
                marginRight: "16px",
              }}
              onClick={() => {
                handleModalClose();
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                padding: "5px 16px",
                backgroundColor: "#8A00CC",
                borderRadius: "10px",
                color: "#FFF",
                "&:hover": {
                  backgroundColor: "#CE66FF",
                  boxShadow: "none",
                },
                "&:disabled": {
                  backgroundColor: "#FFF",
                  border: "1px solid #ABABAB",
                  color: "#ABABAB",
                },
              }}
              disabled={selectedFile === null}
              onClick={() => {
                handleConfirmUpload();
              }}
              autoFocus
            >
              Go To Dashboard
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

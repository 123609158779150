import React, { useEffect, useState } from "react";
import { LinearProgress, Grid, Typography } from "@mui/material";
import * as consts from "../consts";
import logo from "../assets/images/logo-animation.gif";

export interface BusyProps {
	withoutText?: boolean;
}

export const Busy = ({ withoutText }: BusyProps): JSX.Element => {
	const listOfMessages = [
		"Grabbing the key...",
		"Unlocking the door...",
		"Entering the Lab...",
	];

	const [currentMessage, setCurrentMessage] = useState<number>(0);

	useEffect(() => {
		const interval = setInterval(() => {
			if (currentMessage === 2) {
				setCurrentMessage(0);
			} else {
				setCurrentMessage((prevNumber) => prevNumber + 1);
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [listOfMessages]);
	return (
		<Grid
			item
			xs={12}
			container
			spacing={2}
			sx={{
				padding: "16px",
				maxWidth: "1696px",
				width: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				gap: "124px",
			}}
		>
			<img src={logo} alt="" style={{ width: "6rem", height: "6rem" }} />

			{!withoutText && (
				<Typography sx={{ fontSize: "36px", fontWeight: "400" }}>
					{" "}
					{listOfMessages[currentMessage]}
				</Typography>
			)}
		</Grid>
	);
};

export enum NavItems {
  Dashboard = "Dashboard",
  AddNewRole = "Add new role",
}

export enum OverviewMenu {
  Timeline = "Timeline",
  Job_Description = "Job Description",
  Search_Parameters = "Search Parameters",
  Ideal_Candidate_Profiles = "Ideal Candidate Profiles",
}

export interface OverviewItems {
  name: OverviewMenu;
}

export enum JobDescriptionList {
  General_Information = "General Information",
  Job_Details = "Job Details",
  Qualifications = "Qualifications",
  Job_Description = "Job Description"
}
export interface JobDescriptionListItems {
  id: number;
  name: JobDescriptionList;
}





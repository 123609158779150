import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const SignoutIcon = (props: any) => {
	const { fillColor, ...otherProps } = props;
	return (
		<SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
			<path
				d="M12.8 12.0002H20M20 12.0002L17.3336 8.80018M20 12.0002L17.3336 15.2002M13.6 8.00018V6.53938C13.6 6.31808 13.5541 6.09918 13.4651 5.89653C13.3762 5.69388 13.2462 5.51189 13.0834 5.36204C12.9205 5.2122 12.7284 5.09776 12.519 5.02598C12.3097 4.95419 12.0877 4.92661 11.8672 4.94497L5.4672 5.47777C5.06724 5.51109 4.69441 5.69349 4.42264 5.98882C4.15087 6.28415 4.00002 6.67083 4 7.07218V16.9282C4.00002 17.3295 4.15087 17.7162 4.42264 18.0115C4.69441 18.3069 5.06724 18.4893 5.4672 18.5226L11.8672 19.0562C12.0878 19.0746 12.3098 19.047 12.5192 18.9751C12.7286 18.9033 12.9208 18.7888 13.0837 18.6389C13.2465 18.4889 13.3765 18.3068 13.4654 18.1041C13.5543 17.9013 13.6001 17.6824 13.6 17.461V16.0002"
				stroke="black"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
};

import React, { useState } from "react";
import { Button, Grid, useTheme } from "@mui/material";
import {
  ButtonsGroupInterfaceProps,
  ButtonsInterface,
} from "../types/buttonsGroup";

export const ButtonsGroup = ({
  buttonsLabels,
  handleChoose,
  label,
  preselectedButton,
  notAbleToClick,
}: ButtonsGroupInterfaceProps): JSX.Element => {
  const [activeButton, setActiveButton] = useState(preselectedButton || "");
  const defaultButtonList = ["Unsatisfactory", "Okay", "Exceptional"];
  const buttonsList: ButtonsInterface[] = [];
  const theme = useTheme();
  const notAbleToClickOrChangeButton = notAbleToClick ? notAbleToClick : false;
  buttonsLabels
    ? buttonsLabels.map((label: string) =>
        buttonsList.push({ labelName: label, active: false })
      )
    : defaultButtonList.map((label: string) =>
        buttonsList.push({ labelName: label, active: false })
      );

  const handleButtonsChange = (selectedButton: ButtonsInterface) => {
    if (!notAbleToClickOrChangeButton) {
      buttonsList.map((button: ButtonsInterface) => {
        if (button.labelName === selectedButton.labelName) {
          return (button.active = true);
        } else {
          return (button.active = false);
        }
      });
      setActiveButton(selectedButton.labelName);
    }
  };

  return (
    <Grid
      container
      sx={{
        borderRadius: "100px",
        maxWidth: "fit-content",
        flexDirection: { xs: "column", sm: "row", md: "row" },
        gap: { xs: 1, sm: 0 },
      }}
    >
      {buttonsList.map((button: ButtonsInterface) => (
        <Button
          key={button.labelName}
          color="secondary"
          onClick={() => {
            handleButtonsChange(button);
            handleChoose(button.labelName, label);
          }}
          sx={{
            borderRadius: {
              xs: "100px",
              sm:
                buttonsList[0].labelName === button.labelName
                  ? "10px 0 0 10px"
                  : buttonsList[buttonsList.length - 1].labelName ===
                    button.labelName
                  ? "0px 10px 10px 0px"
                  : 0,
            },
			marginRight: "1px",
            border: "1px solid #18181880",
            borderRight: "1px solid #18181880",

            textTransform: "none",
            backgroundColor:
              button.labelName === activeButton
                ? theme.palette.secondary.main
                : "#fff",
            color:
              button.labelName !== activeButton
                ? theme.palette.secondary.main
                : "#fff",
            "&:hover": {
              bgcolor: notAbleToClick ? "none" : theme.palette.secondary.main,
              color: notAbleToClick ? "none" : "#fff",
            },
          }}
        >
          {" "}
          {button.labelName}
        </Button>
      ))}
    </Grid>
  );
};

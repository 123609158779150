export enum ActiveStep {
	JobDescription = 0,
	SimularProfiles,
	Other,
	Completed,
}

export interface JobDescriptionDetails {
	jobDescriptionUrl: string;
	jobPostingUrl: string;
	jobTitle: string;
	roleCategory: string;
	location: string;
	workEnviroment: string;
	degree: string;
	minYearsOfExp: number | string;
	maxYearsOfExp: number | string;
	workType: string;
	notes: string;
	requiredSkils: string[];
}

export interface IdealProfile {
	linkendinProfile: string;
	comments: string;
}

export interface IdealProfilesData {
	simularProfiles: IdealProfile[];
}

export enum RoleStatus {
	Active = "Active",
	Inactive = "Inactive",
	Pending = "Pending",
	Processing = "Processing"
}

export enum StageItems {
	RoleOnboarding = "Role Onboarding",
	Calibration = "Calibration",
	Engage = "Engage",
	Scheduling = "Scheduling"
}


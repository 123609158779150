import * as consts from "../consts";
import axios from "axios";
import { Candidate, Candidates, StatusMatrix } from "../types";
import { IdealCandidateProfiles, RoleModalRequest, SearchCriteria } from "./roles";

export const getProjectIds = async (token: string): Promise<string[]> => {
  let response = await axios.get(
    `https://tl-prd-app.azurewebsites.net/Projects`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

export const getProjectsData = async (
  id: string
): Promise<RoleModalRequest> => {
  let response = await axios.get(
    `https://tl-prd-app.azurewebsites.net/Projects/${id}`
  );
  return response.data;
};

export const getSearchCriteriaData = async (
  projectId: string | undefined
): Promise<SearchCriteria[]> => {
  let response = await axios.get(
    `https://tl-prd-app.azurewebsites.net/SearchCriteria/${projectId}`
  );
  return response.data;
};

export const getSearchCriteriaById = async (
  projectId: string | undefined,
  searchCriteriaId: string | undefined
): Promise<SearchCriteria> => {
  let response = await axios.get(
    `https://tl-prd-app.azurewebsites.net/SearchCriteria/${projectId}/${searchCriteriaId}`
  );
  return response.data;
};


export const updateSearchCriteriaData = async (
  searchCriteria: SearchCriteria,
  projectId: string
): Promise<SearchCriteria> => {
  let response = await axios.post(
    `https://tl-prd-app.azurewebsites.net/SearchCriteria/${projectId}`,
    { ...searchCriteria }
  );
  return response.data;
};

export const getIdealCandidateProfiles = async (
  projectId: string | undefined
): Promise<IdealCandidateProfiles[]> => {
  let response = await axios.get(
    `https://tl-prd-app.azurewebsites.net/IdealCandidateProfile/${projectId}`
  );
  return response.data;
};

export const getIdealCandidateProfilesById = async (
  projectId: string | undefined,
  idealCandidateProfilesId: string | undefined
): Promise<IdealCandidateProfiles> => {
  let response = await axios.get(
    `https://tl-prd-app.azurewebsites.net/IdealCandidateProfile/${projectId}/${idealCandidateProfilesId}`
  );
  return response.data;
};


export const saveIdealCandidateProfilesData = async (
  idealCandidateProfiles: IdealCandidateProfiles,
  projectId: string
): Promise<IdealCandidateProfiles> => {
  let response = await axios.post(
    `https://tl-prd-app.azurewebsites.net/IdealCandidateProfile/${projectId}`,
    { ...idealCandidateProfiles }
  );
  return response.data;
};

export const getCandidates = async (
  projectId: string,
  token: string
): Promise<Candidate[]> => {
  let response = await axios.get(
    `https://tl-prd-app.azurewebsites.net/Candidates/${projectId}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};
export const getCandidate = async (
  projectId: string,
  candidateId: string
): Promise<Candidate> => {
  let response = await axios.get(
    `https://tl-prd-app.azurewebsites.net/Candidates/${projectId}/${candidateId}`
  );
  return response.data;
};

export const updateCandidate = async (
  projectId: string,
  candidateId: string,
  candidate: Candidate,
  token: string
): Promise<Candidate> => {
  let response = await axios.post(
    `https://tl-prd-app.azurewebsites.net/Candidates/${projectId}/${candidateId}`,
    candidate,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

export const updateAllCandidates = async (
  projectId: string,
  status: string,
  cadidateIds: string[]
): Promise<Candidates[]> => {
  let response = await axios.post(
    `https://tl-prd-app.azurewebsites.net/Candidates/${projectId}/status/${status}`,
    cadidateIds
  );

  return response.data;
};

export const submitRoleViaFile = async (
  file: File | null,
  title: string,
  token: string
): Promise<any> => {
  try {
    if (!file) {
      throw new Error("No file provided");
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("title", title); 

    const response = await axios.post(
      "https://tl-prd-app.azurewebsites.net/Projects",
      formData, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error submitting role file:", error);
    throw error;
  }
};


export const submitRole = async (
  role: RoleModalRequest,
  token: string
): Promise<any> => {
  try {
    const response = await axios.put(
      "https://tl-prd-app.azurewebsites.net/Projects",
      role,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error submitting role", error);
    throw error;
  }
};

export const submitTimeline = async (
  projectId: string,
  stageId: string,
  status: string
): Promise<any> => {
  try {
    const response = await axios.put(
      `https://tl-prd-app.azurewebsites.net/Timeline/${projectId}/${stageId}/${status}`
    );

    return response.data;
  } catch (error) {
    console.error("Error submitting role", error);
    throw error;
  }
};
export const getTimeline = async (projectId: string): Promise<any> => {
  try {
    const response = await axios.get(
      `https://tl-prd-app.azurewebsites.net/Timeline/${projectId}`
    );

    return response.data;
  } catch (error) {
    console.error("Error submitting role", error);
    throw error;
  }
};

import { Grid } from "@mui/material";
import React, { useState } from "react";
import { NavItems } from "../../enums/nav-items";
import { ActiveStep, JobDescriptionDetails } from "../../enums/addRole";
import {
  JobDescription,

} from "./index";
import {
  IdealProfiles,

} from "../../utilities/roles";
import { AddNewRoleStep } from "../../types";

export interface AddRoleProps {
  sendRequest: (data: JobDescriptionDetails, route: NavItems) => void;
  handleSubmitRoleWithFile: (file: File | null, title: string) => void;
  handleCancel: () => void;
}
export const AddNewRole = ({
  handleSubmitRoleWithFile,
  sendRequest,
  handleCancel,
}: AddRoleProps): JSX.Element => {
  const [activeStep, setActiveStep] = useState<ActiveStep>(
    ActiveStep.JobDescription
  );

  const handleUpdateJobDescription = (data: any) => {};

  return (
    <Grid container sx={{ backgroundColor: "#fff" }}>
      <Grid
        item
        sx={{
          overflow: "auto",
          width: "100%",
        }}
      >
        {activeStep !== ActiveStep.Completed ? (
          <Grid
            item
            sx={{
              marginX: "auto",
              width: "100%",
              maxWidth: "602px",
            }}
          >
            {" "}
            {activeStep === ActiveStep.JobDescription && (
              <JobDescription
                handleUpdateJobDescription={handleUpdateJobDescription}
                handleUpload={handleSubmitRoleWithFile}
                isSearchCriteriaTab={false}
                activeRoleStep={AddNewRoleStep.UploadDocument}
                sendRequest={sendRequest}
                handleCancel={handleCancel}
              />
            )}
            {/* {activeStep === ActiveStep.SimularProfiles && (
              <SimularProfiles
                handleUpdateSimilarProfiles={handleUpdateSimilarProfiles}
                handleCancel={cancel}
                isOverviewTab={false}
                changeStep={handleSetActiveStep}
                onChangeData={() => {}}
              />
            )}
            {activeStep === ActiveStep.Other && (
              <OtherDetails
                isSearchCriteria={false}
                changeStep={handleSetActiveStep}
              />
            )} */}
          </Grid>
        ) : (
          <></>
          // <CompletedStep
          //   goToDashboard={() => {
          //     handleRouteChange(NavItems.Dashboard, addNewRoleData);
          //   }}
          // />
        )}
      </Grid>
    </Grid>
  );
};

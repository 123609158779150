interface CSVHeader {
	key: string;
	label: string;
}

export const CSVHeadersList: CSVHeader[] = [
	{ key: "id", label: "Candidate Id" },
	{ key: "name", label: "Candidate Name" },
	{ key: "confidence", label: "Confidence Score" },
	{ key: "confidenceReason", label: "Confidence Reason" },
	// { key: "summary", label: "Summary" },
	{ key: "status", label: "Candidate Status" },
	{ key: "location", label: "Candidate Location" },
	{ key: "skills", label: "Candidate Skills" },
	{ key: "educations", label: "Candidate Educations" },
	{ key: "experiences", label: "Candidate Experiences" },
	{ key: "certifications", label: "Candidate Certifications" },
	{ key: "dateCreated", label: "Date Created" },
];

interface data {
	id: "1234";
	name: "Mike Thompson1234";
	confidence: 0.5;
	confidenceReason: "Education";
	sourcerComments: "This is a test";
	summary: "Phasellus varius est eget condimentum placerat. Duis fermentum erat eleifend, tincidunt nibh sed, sollicitudin sapien. Praesent at tincidunt magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla egestas ac velit a vehicula. Nulla dapibus nunc et lorem malesuada, eget lacinia libero ultricies. Integer dictum ante quis scelerisque efficitur. Nullam rutrum tempor dui sed consectetur. Mauris eget convallis dui, sed viverra arcu. Mauris vitae sodales nunc. Quisque ultrices molestie laoreet. Nunc vehicula ex ut scelerisque egestas. Cras convallis ipsum ac lorem scelerisque, nec pretium augue consectetur.";
	status: "Accepted";
	statusMatrix: {};
	statusComments: "These are test comments         ";
	linkedInUrl: "https://linkedIn.com";
	location: "Squamish, BC";
	salary: "$275,000 - $300,000";
	skills: ["JavaScript", "C#", "Azure"];
	educations: ["MIT"];
	experiences: [
		"Citibank - Developer",
		"Chicago Title - Architect",
		"Content Panda - CTO"
	];
	certifications: ["Azure AI Fundamentals", "Azure Data Fundamentals"];
	dateCreated: "2023-08-30T08:14:11.2456961Z";
}

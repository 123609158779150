import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const UploadIcon = (props: any) => {
  return (
    <SvgIcon
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.3615 15.4799L16.9599 9.01134C17.6049 8.37908 17.6049 7.35774 16.9599 6.72548C16.315 6.09322 15.2731 6.09322 14.6281 6.72548L5.69791 15.4799C4.40799 16.7444 4.40799 18.7871 5.69791 20.0516C6.98783 21.3161 9.07154 21.3161 10.3615 20.0516L19.2719 11.3167C21.2167 9.41016 21.2167 6.32667 19.2719 4.42988C17.3271 2.52337 14.1816 2.52337 12.2468 4.42988L5.66814 10.879"
          stroke="#181818"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

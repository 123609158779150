import React from "react"; // eslint-disable-line no-unused-vars
import {
  ThemeProvider,
  ScopedCssBaseline,
  createTheme,
  Container,
} from "@mui/material";
import { light } from "../themes";
import { AuthProvider } from "../providers";
import { Main } from "./";
import { styled } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
export interface AppProps {}
const lightTheme = createTheme(light);

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const App = ({}: AppProps): JSX.Element => {
  return (
    <AuthProvider>
      <ThemeProvider theme={lightTheme}>
        <ScopedCssBaseline sx={{ height: "100%" }}>
          <StyledContainer
            disableGutters={true}
            maxWidth={false}
            sx={{ height: "100%", padding: "0px" }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Main />
            </LocalizationProvider>
          </StyledContainer>
        </ScopedCssBaseline>
      </ThemeProvider>
    </AuthProvider>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes slideRight {
	0% {
		transform: translateX(0);
		opacity: 1;
	}
	100% {
		transform: translate(100%, 25%);
		opacity: 0;
	}
}

.animated-exit {
	animation: slideRight 1.2s ease-out, fadeOut 1.2s ease-out;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/login.css"],"names":[],"mappings":"AAAA;CACC;EACC,wBAAwB;EACxB,UAAU;CACX;CACA;EACC,+BAA+B;EAC/B,UAAU;CACX;AACD;;AAEA;CACC,0DAA0D;AAC3D;;AAEA;CACC;EACC,UAAU;CACX;CACA;EACC,UAAU;CACX;AACD","sourcesContent":["@keyframes slideRight {\n\t0% {\n\t\ttransform: translateX(0);\n\t\topacity: 1;\n\t}\n\t100% {\n\t\ttransform: translate(100%, 25%);\n\t\topacity: 0;\n\t}\n}\n\n.animated-exit {\n\tanimation: slideRight 1.2s ease-out, fadeOut 1.2s ease-out;\n}\n\n@keyframes fadeOut {\n\t0% {\n\t\topacity: 1;\n\t}\n\t100% {\n\t\topacity: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from "react"; // eslint-disable-line no-unused-vars
import ReactDOM from "react-dom/client";
import { App } from "./pages";
import "./index.scss"

if (process.env.NODE_ENV === "development") {
  console.log("TL-CLIENT: Loading");
}
let loadApp = function () {
  if (process.env.NODE_ENV === "development") {
    console.log("TL-CLIENT: Initializing React component");
  }
  let app = document.getElementById("tlclient");
  if (app) {
    const root = ReactDOM.createRoot(app);
    root.render(<App />);
  } else {
    if (process.env.NODE_ENV === "development") {
      console.log("TL-CLIENT: No element to insert component into");
    }
  }
};
if (document.readyState === "loading") {
  if (process.env.NODE_ENV === "development") {
    console.log("TL-CLIENT: Waiting for document ready state");
  }
  document.addEventListener("DOMContentLoaded", loadApp);
} else {
  loadApp();
}

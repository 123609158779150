import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Chip,
  Link,
  Box,
  Modal,
  Collapse,
} from "@mui/material";
import { Candidate, Educations, Experiences } from "../../types/candidate";
import { Status } from "../../enums/candidate-status";
import { Close, Done, LinkedIn } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { ReviewedCandidates } from "../../types/tab";
import { ResizableTextarea } from "../../utilities/textAreaAutosize";
import { ButtonsGroup } from "../../utilities/buttonsGroup";
import { Education } from "../../types";
export interface CandidateCardProps {
  candidate: Candidate;
  selectedTab: ReviewedCandidates;
  isVisibleQualified: boolean;
  isConfirmationPopupOpen: boolean;
  isRejectCandidatesModalOpen: boolean;
  handleAccept: (
    candidateId: string,
    candidateData: Candidate,
    action: Status
  ) => void;
  handleReject: (
    candidateId: string,
    candidateData: Candidate,
    action: Status
  ) => void;
  handleOpenReviewModal: (
    candidateId: string,
    candidateData: Candidate,
    action: string
  ) => void;
  handleButtonGroupChoose: (label: string, key: string) => void;
  handleDisqualifyUserFromModal: (statusComentRequest: string) => void;
  handleUpdateChangesDisqualifyModal: (statusComentRequest: string) => void;
  handleResetProgres: () => void;
}

export const CandidateCard = ({
  candidate,
  isVisibleQualified,
  selectedTab,
  isConfirmationPopupOpen,
  isRejectCandidatesModalOpen,
  handleAccept,
  handleReject,
  handleOpenReviewModal,
  handleButtonGroupChoose,
  handleDisqualifyUserFromModal,
  handleUpdateChangesDisqualifyModal,
  handleResetProgres,
}: CandidateCardProps): JSX.Element => {
  const theme = useTheme();
  const [statusComent, setStatusComent] = useState<string>(
    candidate?.statusComments ? candidate.statusComments : ""
  );
  const [statusComentRequest, setStatusComentRequest] = useState<string>(
    candidate?.statusComments ? candidate.statusComments : ""
  );
  const [candidateData, setCandidateData] = useState<Candidate>();
  const [isRejectModalOpen, setRejectModalIsOpen] = useState<boolean>(false);
  const [
    isConfirmationCandidatePopupOpen,
    setIsConfirmationCandidatePopupOpen,
  ] = useState<boolean>(false);
  const [showAllEducation, setShowAllEducation] = useState<boolean>(false);
  const [showAllExperience, setShowAllExperience] = useState<boolean>(false);

  useEffect(() => {
    setCandidateData(candidate);
    setStatusComent(candidate.statusComments);
  }, [candidate]);

  useEffect(() => {
    const comment = statusComent;
    setCandidateData((prevVal: Candidate | undefined) => {
      if (!prevVal) return prevVal;
      return {
        ...prevVal,
        statusComments: comment,
      };
    });
  }, [statusComent]);

  const handleCloseConfirmationPopup = (): void => {
    setIsConfirmationCandidatePopupOpen(false);
  };

  const handleStatusCommentChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setStatusComent(e.target.value);
  };

  const handleStatusCommentChangeForRequest = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setStatusComentRequest(e.target.value);
  };

  const handleClose = () => {
    if (isConfirmationPopupOpen) {
      setIsConfirmationCandidatePopupOpen(true);
    } else {
      setRejectModalIsOpen(false);
      setIsConfirmationCandidatePopupOpen(false);
    }
  };

  useEffect(() => {
    if (!isRejectCandidatesModalOpen) {
      setRejectModalIsOpen(false);
      setIsConfirmationCandidatePopupOpen(false);
    }
  }, [isRejectCandidatesModalOpen]);

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 695,
    width: "90%",
    bgcolor: "background.paper",
    borderRadius: 6,
    outline: "none",
    overflow: "hidden",
    p: 1,
  };

  const confirmationStyleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 438,
    bgcolor: "background.paper",
    borderRadius: 6,
    outline: "none",
    overflow: "hidden",
    paddingX: 1,
  };

  return (
    <>
      <Grid
        container
        sx={{
          padding: "16px",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          borderRadius: "20px",
          gap: "16px",
          position: "relative",
          minHeight: "180px",
          "&:hover": {
            backgroundColor: "#F6F6F6",
            boxShadow: "0px 24px 32px -15px rgba(168, 175, 182, 0.15)",
          },
        }}
      >
        <Grid item md={12} sm={12} xs={12} lg={1.5}>
          <Grid
            container
            sx={{
              paddingRight: "16px",
              flexDirection: "column",
              gap: { md: 2, sm: 2, xs: 2, lg: 0 },
              borderRight: { lg: "1px solid #D2D2D2" },
              borderBottom: {
                md: "1px solid #D2D2D2",
                sm: "1px solid #D2D2D2",
                xs: "1px solid #D2D2D2",
                lg: "none",
              },
              height: "100%",
            }}
          >
            <Grid item>
              <Grid
                container
                paddingBottom={2}
                sx={{
                  flexDirection: {
                    xs: "row",
                    sm: "row",
                    md: "row",
                    lg: "column",
                  },
                  alignItems: "space-between",
                  justifyContent: "space-between",
                }}
                flexWrap="wrap"
              >
                <Grid item gap={1} flexDirection="column">
                  {" "}
                  <Typography
                    variant="body1"
                    sx={{ fontSize: 14, paddingBottom: "8px" }}
                  >
                    {candidate.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: 14, fontWeight: 400, color: "#8F8F8F" }}
                  >
                    {candidate.location}
                  </Typography>
                </Grid>
                <Grid item gap={1} display="flex" paddingTop={1}>
                  <Link href={candidate.linkedInUrl} target="_blank">
                    <Button
                      sx={{
                        color: "#000",
                        backgroundColor: "#F2F2F2",
                        borderRadius: "8px",
                        minWidth: "32px",
                        margin: 0,
                        "&:hover": {
                          backgroundColor: "#D9D9D9",
                        },
                      }}
                    >
                      <LinkedIn sx={{ fontSize: "16px" }} />
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item flexDirection="column" sm={12} md={12} lg={2} xs={12}>
          <Typography
            variant="body1"
            sx={{ fontSize: 14, paddingBottom: "8px" }}
          >
            Education
          </Typography>
          {candidate.educations
            .slice(0, 3)
            .map((education: Educations, index: number) => {
              return (
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    color: "#8F8F8F",
                    marginBottom: "6px",
                  }}
                  key={index}
                >
                  <Typography
                    variant="caption"
                    sx={{ fontWeight: "bold", display: "block" }}
                  >
                    {education.degree}
                  </Typography>
                  <Typography variant="caption" sx={{ display: "block" }}>
                    {education.school}
                  </Typography>
                  <Typography variant="caption" sx={{ display: "block" }}>
                    {education.dateRange}
                  </Typography>
                </Typography>
              );
            })}
          {candidate.educations.length > 3 && (
            <React.Fragment>
              <Collapse in={showAllEducation}>
                {candidate.educations
                  .slice(3)
                  .map((education: Educations, index: number) => {
                    return (
                      <Typography
                        sx={{
                          fontSize: "0.875rem",
                          color: "#8F8F8F",
                          marginBottom: "6px",
                        }}
                        key={index}
                      >
                        <Typography
                          variant="caption"
                          sx={{ fontWeight: "bold", display: "block" }}
                        >
                          {education.degree}
                        </Typography>
                        <Typography variant="caption" sx={{ display: "block" }}>
                          {education.school}
                        </Typography>
                        <Typography variant="caption" sx={{ display: "block" }}>
                          {education.dateRange}
                        </Typography>
                      </Typography>
                    );
                  })}
              </Collapse>
              {showAllEducation ? (
                <Button
                  onClick={() => {
                    setShowAllEducation(false);
                  }}
                >
                  Show Less
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setShowAllEducation(true);
                  }}
                >
                  Show More
                </Button>
              )}
            </React.Fragment>
          )}
        </Grid>
        <Grid item flexDirection="column" sm={12} md={12} lg={2} xs={12}>
          <Typography
            variant="body1"
            sx={{ fontSize: 14, paddingBottom: "8px" }}
          >
            Experiences
          </Typography>
          {candidate.experiences
            .slice(0, 3)
            .map((experience: Experiences, index: number) => {
              return (
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    color: "#8F8F8F",
                    marginBottom: "6px",
                  }}
                  key={index}
                >
                  <Typography
                    variant="caption"
                    sx={{ fontWeight: "bold", display: "block" }}
                  >
                    {experience.role}
                  </Typography>
                  <Typography variant="caption" sx={{ display: "block" }}>
                    {experience.company}
                  </Typography>
                  <Typography variant="caption" sx={{ display: "block" }}>
                    {experience.tenure}
                  </Typography>
                  <Typography variant="caption" sx={{ display: "block" }}>
                    {experience.location}
                  </Typography>
                </Typography>
              );
            })}
          {candidate.experiences.length > 3 && (
            <React.Fragment>
              <Collapse in={showAllExperience}>
                {candidate.experiences
                  .slice(3)
                  .map((experience: Experiences, index: number) => {
                    return (
                      <Typography
                        sx={{
                          fontSize: "0.875rem",
                          color: "#8F8F8F",
                          marginBottom: "6px",
                        }}
                        key={index}
                      >
                        <Typography
                          variant="caption"
                          sx={{ fontWeight: "bold", display: "block" }}
                        >
                          {experience.role}
                        </Typography>
                        <Typography variant="caption" sx={{ display: "block" }}>
                          {experience.company}
                        </Typography>
                        <Typography variant="caption" sx={{ display: "block" }}>
                          {experience.tenure}
                        </Typography>
                        <Typography variant="caption" sx={{ display: "block" }}>
                          {experience.location}
                        </Typography>
                      </Typography>
                    );
                  })}
              </Collapse>
              {showAllExperience ? (
                <Button
                  onClick={() => {
                    setShowAllExperience(false);
                  }}
                >
                  Show Less
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setShowAllExperience(true);
                  }}
                >
                  Show More
                </Button>
              )}
            </React.Fragment>
          )}
        </Grid>
        <Grid item flexDirection="column" sm={12} md={12} lg={2.5} xs={12}>
          <Grid container gap={1} alignItems="center">
            <Typography
              sx={{ fontSize: 14, paddingBottom: "8px" }}
              variant="body1"
            >
              {candidate.name.split(" ")[0]}'s Match Score{" "}
            </Typography>{" "}
            <Chip
              label={
                candidate.confidence * 100 >= 70 ? "Qualified" : "NeedsReview"
              }
              sx={{
                display: "flex",
                alignContent: "center",
                height: "18px",
                color: "#fff",
                borderRadius: "20px",
                marginBottom: "11px",
                backgroundColor:
                  candidate.confidence >= 0.7
                    ? theme.palette.success.main
                    : theme.palette.warning.dark,
              }}
            />
          </Grid>
          <ul style={{ paddingLeft: "25px", paddingBottom: "8px" }}>
            {candidate.sourcerComments
              .split("*")
              .map((comment: string, i: number) => {
                const cleanedComment =
                  comment.length > 1 ? comment.replace('"', "") : "";
                return cleanedComment ? (
                  <li
                    key={candidate.name + cleanedComment}
                    style={{ fontSize: "14px", color: "#8F8F8F" }}
                  >
                    {cleanedComment}
                  </li>
                ) : null;
              })}
          </ul>
        </Grid>
        <Grid item flexDirection="row" sm={12} md={12} lg={3} xs={12}>
          <Grid
            container
            display="flex"
            justifyContent={"space-around"}
            sx={{ maxWidth: "100%", width: "100%" }}
            height="100%"
          >
            <Grid
              sx={{
                maxWidth: "300px",
                width: "100%",
              }}
            >
              <ResizableTextarea
                label=""
                aria-label="maximum height"
                placeholder="Comment"
                isCandidateCard={true}
                value={statusComent}
                onChange={handleStatusCommentChange}
              />
            </Grid>

            <Grid
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "row",
                  sm: "row",
                  md: "row",
                  lg: "row-reverse",
                  xl: "column-reverse",
                },
                justifyContent: "flex-end",
                alignItems: {
                  xs: "flex-end",
                  sm: "flex-end",
                  md: "flex-end",
                  lg: "flex-start",
                  xl: "flex-end",
                },
              }}
              alignItems={"end"}
              gap={1}
              paddingLeft={1}
            >
              {" "}
              <Button
                onClick={() => {
                  if (selectedTab === ReviewedCandidates.Qualified) {
                    candidateData &&
                      handleReject(
                        candidate.id,
                        candidateData,
                        Status.Rejected
                      );

                    setStatusComent("");
                  }
                  if (selectedTab === ReviewedCandidates.Reviewed) {
                    candidateData &&
                      handleOpenReviewModal(
                        candidate.id,
                        candidateData,
                        "review"
                      );
                  }
                  setRejectModalIsOpen(true);
                }}
                sx={{
                  color:
                    candidate.status === Status.Accepted
                      ? theme.palette.secondary.contrastText
                      : candidate.status === Status.Rejected
                      ? "#DC362E"
                      : "#E46962",
                  backgroundColor:
                    candidate.status === Status.Accepted
                      ? theme.palette.info.light
                      : candidate.status === Status.Rejected
                      ? "#F2B8B5"
                      : "#F9DEDC",
                  "&:hover": {
                    backgroundColor: "#F2B8B5",
                  },

                  height: "40px",
                  width: "56px",
                  borderRadius: "10px",

                  // "&.MuiButtonBase-root:hover": {
                  // 	backgroundColor:
                  // 		candidate.status === Status.Rejected
                  // 			? "#F2B8B5"
                  // 			: "#F9DEDC",
                  // 	color:
                  // 		candidate.status === Status.Rejected
                  // 			? "#DC362E"
                  // 			: "#E46962",
                }}
              >
                <Close />
              </Button>
              <Button
                onClick={() => {
                  candidateData &&
                    handleAccept(candidate.id, candidateData, Status.Accepted);
                  if (selectedTab === ReviewedCandidates.Qualified) {
                    setStatusComent("");
                  }
                }}
                color="success"
                sx={{
                  color:
                    candidate.status === Status.Rejected
                      ? theme.palette.secondary.contrastText
                      : candidate.status === Status.Accepted
                      ? theme.palette.success.contrastText
                      : "#6AA171",
                  backgroundColor:
                    candidate.status === Status.Rejected
                      ? theme.palette.info.light
                      : candidate.status === Status.Accepted
                      ? "#E5F4E7"
                      : "#EFF8F1",
                  "&:hover": {
                    backgroundColor: "#E5F4E7",
                  },

                  height: "40px",
                  width: "56px",
                  borderRadius: "10px",
                  padding: "16px 8px",
                }}
              >
                <Done />
              </Button>
            </Grid>
          </Grid>
          <Typography
            variant="body1"
            sx={{
              fontSize: 14,
              fontWeight: 400,
              color: "#8F8F8F",
              textAlign: "end",
              position: "absolute",
              bottom: "10px",
              right: "24px",
              display: { lg: "none", xl: "block" },
            }}
          >
            Added {""}
            {new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              day: "numeric",
              month: "long",
            }).format(new Date(candidate.dateCreated))}
          </Typography>
        </Grid>
      </Grid>

      <Modal open={isRejectModalOpen}>
        <Box sx={styleModal}>
          <Modal open={isConfirmationCandidatePopupOpen}>
            <Box sx={confirmationStyleModal}>
              <Box
                sx={{
                  overflow: "scroll",
                  width: "100%",
                  maxHeight: "90vh",
                  Height: "100%",
                  padding: 1,
                }}
              >
                <Box sx={{ display: "flex", padding: "8px 8px 24px" }}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    fontWeight={500}
                    fontSize="20px"
                  >
                    Are you sure you want to reset your progress?
                  </Typography>
                  <Close
                    sx={{
                      marginLeft: "auto",
                      cursor: "pointer",
                      marginTop: "5px",
                    }}
                    onClick={handleCloseConfirmationPopup}
                  />
                </Box>
                <Box
                  sx={{
                    borderTop: "1px solid #D9D9D9",
                    padding: "24px 8px",
                    display: "flex",
                    flexDirection: "row",
                    flexwrap: "nowrap",
                    gap: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    lineHeight="24px"
                    fontWeight={400}
                    fontSize="16px"
                  >
                    Looks like you're in the middle of evaluating a <br />{" "}
                    candidate. This will reset your progress.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    marginLeft: "auto",
                    padding: "24px 8px 8px",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="text"
                    onClick={handleResetProgres}
                    sx={{ textTransform: "none", color: "#000" }}
                  >
                    Yes, Reset
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCloseConfirmationPopup}
                    sx={{
                      borderRadius: "100px",
                      height: "40px",
                      boxShadow: "none",
                      textTransform: "none",
                      color: "#fff",
                    }}
                  >
                    No, Keep Progress
                  </Button>
                </Box>
              </Box>{" "}
            </Box>
          </Modal>
          <Box
            sx={{
              width: "100%",
              maxHeight: "90vh",
              Height: "100%",
              padding: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "8px 8px 24px",
                borderBottom: "1px solid #D9D9D9",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize="20px"
              >
                Did these match your requirements?
              </Typography>
              <Close
                onClick={() => setRejectModalIsOpen(false)}
                sx={{ marginLeft: "auto", cursor: "pointer" }}
              />
            </Box>
            <Box
              sx={{
                maxHeight: "60vh",
                overflowY: "scroll",
              }}
            >
              <Box
                sx={{
                  padding: "24px 8px 0",
                  display: "flex",
                  flexDirection: "row",
                  flexwrap: "nowrap",
                  gap: 2,
                }}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  lineHeight={1}
                  fontSize="16px"
                >
                  {candidate?.name || ""}
                </Typography>
                <Box
                  sx={{
                    marginLeft: "auto",
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                  }}
                >
                  <Link
                    href={candidate?.linkedInUrl}
                    target="blank"
                    color="#000"
                  >
                    <span
                      style={{
                        backgroundColor: "#F2F2F2",
                        borderRadius: "10px",
                        display: "block",
                        width: "32px",
                        height: "32px",
                      }}
                    >
                      <LinkedIn
                        scale="16px"
                        sx={{ marginTop: "4px", marginLeft: "4px" }}
                      />
                    </span>
                  </Link>
                </Box>
              </Box>
              <Typography
                color="#18181880"
                sx={{ marginLeft: "8px", fontSize: "0.875rem" }}
              >
                {candidate?.location || ""}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  padding: "16px 8px 0",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      sm: "column",
                      md: "row",
                    },
                    justifyContent: {
                      xs: "flex-start",
                      sm: " space-between",
                    },
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                    Education
                  </Typography>
                  <ButtonsGroup
                    label="Education"
                    handleChoose={handleButtonGroupChoose}
                    preselectedButton={
                      candidate?.status !== Status.Pending
                        ? candidate?.statusMatrix?.Education || ""
                        : ""
                    }
                  ></ButtonsGroup>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      sm: "column",
                      md: "row",
                    },
                    justifyContent: {
                      xs: "flex-start",
                      sm: " space-between",
                    },
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                    Experience
                  </Typography>
                  <ButtonsGroup
                    preselectedButton={
                      candidate?.status !== Status.Pending
                        ? candidate?.statusMatrix?.Experience || ""
                        : ""
                    }
                    label="Experience"
                    handleChoose={handleButtonGroupChoose}
                  ></ButtonsGroup>
                </Box>{" "}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      sm: "column",
                      md: "row",
                    },
                    justifyContent: {
                      xs: "flex-start",
                      sm: " space-between",
                    },
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                    Previous Company
                  </Typography>
                  <ButtonsGroup
                    preselectedButton={
                      candidate?.status !== Status.Pending
                        ? candidate?.statusMatrix["Previous Company"] || ""
                        : ""
                    }
                    label="Previous Company"
                    handleChoose={handleButtonGroupChoose}
                  ></ButtonsGroup>
                </Box>{" "}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      sm: "column",
                      md: "row",
                    },
                    justifyContent: {
                      xs: "flex-start",
                      sm: " space-between",
                    },
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                    Skills
                  </Typography>
                  <ButtonsGroup
                    label="Skills"
                    preselectedButton={
                      candidate?.status !== Status.Pending
                        ? candidate?.statusMatrix?.Skills || ""
                        : ""
                    }
                    handleChoose={handleButtonGroupChoose}
                  ></ButtonsGroup>
                </Box>{" "}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      sm: "column",
                      md: "row",
                    },
                    justifyContent: {
                      xs: "flex-start",
                      sm: " space-between",
                    },
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                    Level
                  </Typography>
                  <ButtonsGroup
                    label="Level"
                    preselectedButton={
                      candidate?.status !== Status.Pending
                        ? candidate?.statusMatrix?.Level || ""
                        : ""
                    }
                    handleChoose={handleButtonGroupChoose}
                  ></ButtonsGroup>
                </Box>{" "}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      sm: "column",
                      md: "row",
                    },
                    justifyContent: {
                      xs: "flex-start",
                      sm: " space-between",
                    },
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                    Region
                  </Typography>
                  <ButtonsGroup
                    label="Region"
                    preselectedButton={
                      candidate?.status !== Status.Pending
                        ? candidate?.statusMatrix?.Region || ""
                        : ""
                    }
                    handleChoose={handleButtonGroupChoose}
                  ></ButtonsGroup>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {" "}
                  <Typography> Other</Typography>{" "}
                  <Typography
                    color="#18181880"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                    }}
                  >
                    {" "}
                    {candidate?.statusComments || ""}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ padding: "16px 8px" }}>
                {" "}
                <ResizableTextarea
                  label="Other"
                  value={statusComentRequest}
                  placeholder={`Share your thoughts on ${candidate?.name}.`}
                  onChange={handleStatusCommentChangeForRequest}
                ></ResizableTextarea>
              </Box>
            </Box>
            <Box
              sx={{
                padding: "24px 8px 8px ",
                display: "flex",
                flexDirection: "row",
                flexwrap: "nowrap",
                gap: 2,
                borderTop: "1px solid #D9D9D9",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  marginLeft: "auto",
                  marginRight: "5px",
                }}
              >
                {" "}
                <Button
                  variant="text"
                  onClick={() => {
                    handleClose();
                  }}
                  sx={{
                    borderRadius: "8px",
                    color: "#181818",
                    border: "1px solid #ABABAB",
                    padding: "8px 16px",
                    maxHeight: "40px",
                    maxWidth: "82px",
                  }}
                >
                  Close
                </Button>
                {candidate?.status === Status.Pending && (
                  <Button
                    onClick={() => {
                      handleDisqualifyUserFromModal(statusComentRequest);
                      setRejectModalIsOpen(false);
                    }}
                    variant="contained"
                    color="secondary"
                    sx={{
                      color: "#fff",
                      borderRadius: "8px",
                      padding: "8px 16px",
                      maxHeight: "40px",
                      maxWidth: "176px",
                      "&:hover": {
                        backgroundColor: "#CE66FF",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Disqualify Candidate
                  </Button>
                )}
                {candidate?.status !== Status.Pending && (
                  <Button
                    onClick={() => {
                      handleUpdateChangesDisqualifyModal(statusComentRequest);
                      setRejectModalIsOpen(false);
                    }}
                    variant="contained"
                    color="secondary"
                    sx={{
                      color: "#fff",
                      borderRadius: "8px",
                      padding: "8px 16px",
                      maxHeight: "40px",
                      maxWidth: "136px",
                      "&:hover": {
                        backgroundColor: "#CE66FF",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Save Changes
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

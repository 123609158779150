import { ThemeOptions, createTheme } from "@mui/material/styles";

const theme = createTheme();

export const light: ThemeOptions | any = createTheme({
  palette: {
    mode: "light",
    secondary: {
      main: "#AD00FF",
      light: "#18181880",
      contrastText: "#ABABAB",
    },
    success: {
      main: "#6AA171",
      "100": "#450066",
      light: "#E8F4E8",
      dark: "#2A5924",
      "200": "#CE66FF",
      contrastText: "#4C8E5E",
    },
    warning: {
      main: "#D3C016",
      dark: "#DE901A",
    },

    error: { main: "#DC362E", light: "#CD417C" },
    info: { main: "#fff", light: "#D9D9D9", dark: "#450066" },
    text: { secondary: "#8F8F8F", disabled: "#E6E6E6", primary: "#181818" },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: "2px 2px 6px 0px rgba(0, 0, 0, 0.12)",
          marginTop: "8px",
          borderRadius: "8px",

          border: "1px solid #eee",
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          padding: "12px 24px",
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          borderRadius: "8px",
          border: "1px solid  transparent",
          "&.MuiFilledInput-input:focus": {
            border: " 1px solid #AD00FF",
            borderRadius: "8px",
            backgroundColor: "#E6E6E6E6",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "red",
          fontSize: "12px",
          margin: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          boxShadow: "none",
          fontFamily: "Arial",
          borderRadius: "100px",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontFamily: "Arial",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: { fontFamily: "Arial", fontWeight: "bold" },
        h2: { fontFamily: "Arial", fontWeight: "bold" },
        h3: { fontFamily: "Arial", fontWeight: "bold" },
        h4: { fontFamily: "Arial", fontWeight: "bold" },
        h5: { fontFamily: "Arial", fontWeight: "bold" },
        h6: { fontFamily: "Arial", fontWeight: "bold" },
        root: {
          fontFamily: "Arial",
        },
      },
    },
  },
  typography: { fontFamily: "Arial" },
});

export const dateFormater = (
  date: Date | string,
  options?: Intl.DateTimeFormatOptions
): string | undefined => {
  if (date) {
    const formatToDate = new Date(date);

    return new Intl.DateTimeFormat("en-US", options).format(formatToDate);
  } else {
    return;
  }
};

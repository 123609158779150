export interface SingleTab {
	value: AciveRoles;
	active: boolean;
}

export enum AciveRoles {
	Active = "Active Roles",
	Inactive = "Inactive Roles",
}

export enum ReviewedCandidates {
	Overview = "Overview",
	Qualified = "Qualified",
	Quick_Review = "Quick Review",
	Reviewed = "Reviewed",
}

export interface ReviewedTab {
	value: ReviewedCandidates;
	active: boolean;
	amount: number | null;
	visible: boolean;
}



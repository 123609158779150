export const modalStyle = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	maxWidth: 600,
	width: "90%",
	height: "90%",
	maxHeight: "772px",
	bgcolor: "background.paper",
	borderRadius: "16px",
	outline: "none",
	overflow: "hidden",
	display: "flex",
	flexDirection: "column",
	padding: "0.5rem",
};

export const headerStyle = {
	flex: "0 0 auto",
	padding: "0 8px 8px",
	display: "flex",
	flexDirection: "row",
	maxHeight: "90vh",
	Height: "100%",
};

export const bodyStyle = {
	flex: "1 1 auto",
	overflowY: "hidden",
	overflowX: "hidden",
	padding: "16px 0px 16px 0px",
	display: "flex",
	flexDirection: "column",
	gap: 1,
};

export const footerStyle = {
	flex: "0 0 auto",
	display: "flex",
	justifyContent: "flex-end",
	paddingTop: "40px",
	paddingX: "16px",
	position: "sticky",
	bottom: "16px",
	gap: 2,
};

import React, { useEffect, useState } from "react";

import { IdealCandidateProfiles, RoleModalRequest, SearchCriteria } from "../../utilities/roles";

import Grid from "@mui/material/Grid";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import { JobDescriptionSc } from "../searchCriteria/jobDescription";
import { OverviewItems, OverviewMenu } from "../../enums/nav-items";
import { Notes } from "../searchCriteria/notes";
import { DiscoverSimularProfilesSc } from "../searchCriteria/discoverSimularProfiles";
import { Timeline } from "../searchCriteria/timeline";
import { ReviewedCandidates } from "../../types/tab";
export interface SearchCriteriaProps {
  activeRoleRequest: RoleModalRequest;
  activeSearchCriteria?: SearchCriteria;
  searchCriteria?: SearchCriteria[];
  activeIdealPorfile: IdealCandidateProfiles;
  idealCandidateProfiles: IdealCandidateProfiles[];
  handleUpdateJobDescriptionData: (data: RoleModalRequest) => void;
  updateSearchParameters: (data: SearchCriteria, projectId?: string) => void;
  saveIdealCandidateProfiles: (data: IdealCandidateProfiles, projectId?: string) => void;
  onNavigateToReviewTab: (data: ReviewedCandidates) => void;
}

const navItems: OverviewItems[] = [
  {
    name: OverviewMenu.Timeline,
  },
  {
    name: OverviewMenu.Job_Description,
  },
  {
    name: OverviewMenu.Search_Parameters,
  },
  {
    name: OverviewMenu.Ideal_Candidate_Profiles,
  },
];

export const Overview = ({
  activeRoleRequest,
  activeSearchCriteria,
  searchCriteria,
  activeIdealPorfile,
  idealCandidateProfiles,
  handleUpdateJobDescriptionData,
  updateSearchParameters,
  saveIdealCandidateProfiles,
  onNavigateToReviewTab,
}: SearchCriteriaProps) => {
  const [selectedItem, setSelectedItem] = useState<OverviewItems>({
    name: OverviewMenu.Timeline,
  });
  const [activeItem, setActiveItem] = useState<OverviewMenu>(
    OverviewMenu.Timeline
  );

  const handleListItemClick = (item: OverviewItems) => {
    setSelectedItem(item);
    setActiveItem(item.name);
  };
  const onNavigate = (data: OverviewMenu | ReviewedCandidates) => {
    switch (data) {
      case OverviewMenu.Job_Description:
      case OverviewMenu.Ideal_Candidate_Profiles:
      case OverviewMenu.Search_Parameters:
        setSelectedItem({ name: data });
        setActiveItem(data);
        break;
      case ReviewedCandidates.Qualified:
        onNavigateToReviewTab(ReviewedCandidates.Qualified);
        break;
      case ReviewedCandidates.Quick_Review:
        onNavigateToReviewTab(ReviewedCandidates.Quick_Review);
      default:
        break;
    }
  };

  return (
    <Grid
      sx={{
        height: "calc(100vh - 205px)",
        display: "flex",
        maxWidth: "1696px",
        boxSizing: "border-box",
        marginX: "auto",
        width: "100%",
      }}
    >
      <aside
        style={{ display: "flex", flexDirection: "column", flex: "0 0 50px" }}
      >
        <Grid
          sx={{
            flex: "1",
            height: "100%",
            overflow: "auto",
            marginX: "auto",
            borderRight: "1px solid #D9D9D9",
            minWidth: "250px",
          }}
        >
          <List
            sx={{
              fontSize: "14px",
              padding: "24px 0 24px 41px",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {navItems.map((navItem: OverviewItems, index: number) => (
              <ListItem
                key={index}
                sx={{
                  cursor: "pointer",
                  backgroundColor:
                    activeItem === navItem.name ? "#F0EAF2" : "transparent",
                  color: activeItem === navItem.name ? "#AD00FF" : "#8F8F8F",
                  width: "184px",
                  "&:hover": {
                    color: "#8A00CC"
                  },
                  borderRadius: "10px",
                }}
                onClick={() => handleListItemClick(navItem)}
              >
                {navItem.name}
              </ListItem>
            ))}
          </List>
        </Grid>
      </aside>
      <main style={{ overflow: "hidden", width: "100%" }}>
        {selectedItem?.name === OverviewMenu.Timeline && (
          <Timeline
            activeRoleRequest={activeRoleRequest}
            onNavigate={onNavigate}
          />
        )}
        {selectedItem?.name === OverviewMenu.Job_Description && (
          <JobDescriptionSc
            activeRoleRequest={activeRoleRequest}
            onNavigate={onNavigate}
            handleUpdateJobDescriptionData={handleUpdateJobDescriptionData}
          />
        )}
        {selectedItem?.name === OverviewMenu.Search_Parameters && (
          <Notes
            activeRoleRequest={activeRoleRequest}
            activeSearchCriteria={activeSearchCriteria}
            searchCriteria={searchCriteria}
            updateSearchParameters={updateSearchParameters}
          />
        )}
        {selectedItem?.name === OverviewMenu.Ideal_Candidate_Profiles && (
          <DiscoverSimularProfilesSc
            activeIdealPorfile={activeIdealPorfile}
            idealCandidateProfiles={idealCandidateProfiles}
            activeRoleRequest={activeRoleRequest}
            saveIdealCandidateProfiles={saveIdealCandidateProfiles}
          />
        )}
      </main>
    </Grid>
  );
};

import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { VectorStrokeIcon } from "../../utilities/icons";
import {
  bodyStyle,
  headerStyle,
  modalStyle,
} from "../../utilities/modalStyles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { SimularProfiles } from "../roles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  IdealCandidateProfiles,
  IdealProfiles,
  IdealProfilesData,
  IdealProfilesSc,
  RoleModalRequest,
} from "../../utilities/roles";
import { dateFormater } from "../../utilities/dateFormatHelper";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { PersonIcon } from "../../utilities/icons";
import { AddIcon } from "../../utilities/icons/addIcon";

export interface DiscoverSimularProfilesScProps {
  activeRoleRequest: RoleModalRequest;
  idealProfiles?: IdealProfiles[];
  activeIdealPorfile: IdealCandidateProfiles;
  idealCandidateProfiles: IdealCandidateProfiles[];
  saveIdealCandidateProfiles: (
    data: IdealCandidateProfiles,
    projectId?: string
  ) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const DiscoverSimularProfilesSc = ({
  activeIdealPorfile,
  idealCandidateProfiles,
  activeRoleRequest,
  saveIdealCandidateProfiles,
}: DiscoverSimularProfilesScProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [buttonIsActive, setButtonIsActive] = useState(false);
  const [idealProfilesDataModal, setIdealProfilesDataModal] =
    useState<IdealProfiles[]>();
  const [idealPorfilesData, setIdealProfilesData] = useState<IdealProfilesSc[]>(
    []
  );
  const [filteredIdealProfilesData, setFilteredIdealProfilesData] = useState<
    IdealProfilesSc[] | undefined
  >();

  const [activeIdealProfileData, setActiveIdealProfileData] =
    useState<IdealCandidateProfiles>({} as IdealCandidateProfiles);
  const [idealProfilesDataRequest, setIdealProfilesDataRequest] =
    useState<IdealCandidateProfiles>(
      activeIdealProfileData || ({} as IdealCandidateProfiles)
    );
  const [datesForSelect, setDatesForSelect] = useState<
    IdealProfilesSc[] | undefined
  >([]);
  const [selectedValue, setSelectedValue] = useState<string | Date | undefined>(
    "All"
  );

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const setActiveStatus = (status: boolean) => {
    setButtonIsActive(true);
  };

  useEffect(() => {
    setActiveIdealProfileData(activeIdealPorfile as IdealCandidateProfiles);
    setIdealProfilesDataModal(activeIdealPorfile?.idealProfiles);
    let idealPrifilesDataa: IdealProfilesSc[][] = [];

    if (idealCandidateProfiles) {
      idealPrifilesDataa = idealCandidateProfiles.map(
        (profile: IdealCandidateProfiles) => {
          if (profile.idealProfiles) {
            const formattedDate = profile.createdDate
              ? dateFormater(profile.createdDate, {
                  dateStyle: "long",
                  timeStyle: "short",
                })
              : "";
            const formattedDateForSelect = profile.createdDate
              ? dateFormater(profile.createdDate, {
                  dateStyle: "long",
                })
              : "";

            const datePart = formattedDate?.split(" at ")[0];
            const timePart = formattedDate?.split(" at ")[1];
            return [
              {
                profiles: profile.idealProfiles.map(
                  (profile: IdealProfiles) => ({
                    linkedInProfileid: profile.linkedInProfileid,
                    linkedInUrl: profile.linkedInUrl,
                    comments: profile.comments,
                  })
                ),
                createdDate: `${datePart} ${timePart}`,
                dateForSelect: formattedDateForSelect,
                idealCandidateProfileId: profile.idealCandidateProfileId,
              },
            ];
          } else {
            return [];
          }
        }
      );
    }

    const finalData = idealPrifilesDataa
      .flat()
      .map((item: IdealProfilesSc) => item);

    setIdealProfilesData(finalData);
    setDatesForSelect(finalData);
  }, [activeIdealPorfile, idealCandidateProfiles]);

  const handleUpdateSimilarProfiles = (idealProfiles: IdealProfiles[]) => {
    setIdealProfilesData((prev: IdealProfilesSc[]) => {
      const formattedDate = dateFormater(new Date(), {
        dateStyle: "long",
        timeStyle: "short",
      });

      const datePart = formattedDate?.split(" at ")[0];
      const timePart = formattedDate?.split(" at ")[1];
      const newProfileData = {
        profiles: idealProfiles.map((profile: IdealProfiles) => ({
          linkedInProfileid: profile.linkedInProfileid,
          linkedInUrl: profile.linkedInUrl,
          comments: profile.comments,
        })),
        createdDate: `${datePart} ${timePart}`,
      };

      return [...prev, newProfileData];
    });

    setIdealProfilesDataRequest({
      idealCandidateProfileId: activeIdealPorfile
        ? activeIdealPorfile.idealCandidateProfileId
        : "",
      idealProfiles: idealProfiles.length ? idealProfiles : [],
    });
  };

  useEffect(() => {
    saveIdealCandidateProfiles(
      idealProfilesDataRequest,
      activeRoleRequest.projectId
    );
  }, [idealProfilesDataRequest]);

  useEffect(() => {
    if (idealPorfilesData && idealPorfilesData.length > 0) {
      setSelectedValue("All");
    }
    setFilteredIdealProfilesData(idealPorfilesData);
  }, [idealPorfilesData]);

  // NEEDS NEW MODEL REFACTOR

  // const getSearchCriteria = (
  //   projectId: string | undefined,
  //   scId: string | undefined
  // ) => {
  //   const getData = async (): Promise<void> => {
  //     let results = await getIdealCandidateProfilesById(projectId, scId);
  //     setIdealProfilesDataModal(results.idealProfiles);
  //   };
  //   getData();
  // };

  // const handleChange = (event: any) => {
  //   const selectedOption = event.target.value;

  //   const selectedSearchCriteria = idealPorfilesData?.find(
  //     (sp: SearchCriteria) => sp.createdDate === selectedOption
  //   );

  //   if (selectedSearchCriteria) {
  //     getSearchCriteria(
  //       activeRoleRequest.projectId,
  //       selectedSearchCriteria?.searchCriteriaId
  //     );
  //   }
  //   setSelectedValue(selectedOption);
  //   if (selectedOption !== "All") {
  //     const formattedOption = dateFormater(selectedOption, {
  //       dateStyle: "long",
  //     });

  //     const filteredSPByDate = idealPorfilesData?.filter(
  //       (sp: SearchCriteria) => {
  //         return sp.dateForSelect
  //           ?.toLocaleString()
  //           .startsWith(formattedOption as string);
  //       }
  //     );

  //     if (filteredSPByDate) {
  //       setFilteredIdealProfilesData(filteredSPByDate);
  //     }
  //   } else {
  //     setFilteredIdealProfilesData(idealPorfilesData);
  //   }
  // };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "24px 40px",
            width: "100%",
            position: "sticky",
            top: 0,
            backgroundColor: "#F7F7FF",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "1.5rem",
              }}
            >
              Ideal Candidate Profiles
            </Typography>
          </Box>
          <Box>
            <Box sx={{ display: "flex", height: "40px", alignItems: "center" }}>
              {/* <Typography sx={{ lineHeight: "20px" }}>View By</Typography>
              <FormControl
                sx={{
                  m: 1,
                  maxWidth: 180,
                  width: "fit-content",
                }}
              >
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  sx={{
                    "& .MuiInputBase-input": {
                      padding: "5px",
                      fontSize: "1rem",
                    },
                    "&. MuiFormLabel-root": {
                      display: "hidden",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  value={selectedValue?.toString()}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {datesForSelect
                    ?.sort((a: IdealProfilesSc, b: IdealProfilesSc) => {
                      return (
                        new Date(b.createdDate as Date).getTime() -
                        new Date(a.createdDate as Date).getTime()
                      );
                    })
                    .filter(
                      (
                        profile: IdealProfilesSc,
                        index: number,
                        array: IdealProfilesSc[]
                      ) => {
                        return (
                          index === 0 ||
                          profile.dateForSelect !==
                            array[index - 1].dateForSelect
                        );
                      }
                    )
                    .map((searchParameter: IdealProfilesSc, index: number) => (
                      <MenuItem
                        key={index}
                        value={searchParameter.createdDate as string}
                      >
                        {searchParameter.dateForSelect as string}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}

              {filteredIdealProfilesData?.length === 0 && (
                <Button
                  variant="text"
                  sx={{
                    borderRadius: "10px",
                    width: "92px",
                    padding: "5px 20px 5px 15px",
                    color: "#FFF",
                    backgroundColor: "#9F14E0",
                    transition: "none",
                    "& .MuiSvgIcon-root": {
                      marginRight: "8px",
                      color: "#FFF",
                    },
                    "&:hover": {
                      backgroundColor: "#CE66FF",
                      boxShadow: "none",
                    },
                  }}
                  onClick={openModal}
                >
                  <AddIcon />

                  <Typography sx={{ fontSize: "1rem" }}>Add</Typography>
                </Button>
              )}
              {filteredIdealProfilesData &&
                filteredIdealProfilesData.length > 0 && (
                  <Button
                    variant="text"
                    sx={{
                      borderRadius: "10px",
                      width: "116px",
                      padding: "5px 20px 5px 15px",
                      color: "#FFF",
                      backgroundColor: "#9F14E0",
                      transition: "none",
                      "& .MuiSvgIcon-root": {
                        fontSize: "22px",
                        marginRight: "8px",
                        color: "#FFF",
                      },
                      "&:hover": {
                        backgroundColor: "#CE66FF",
                        boxShadow: "none",
                      },
                    }}
                    onClick={openModal}
                  >
                    <VectorStrokeIcon status={""} />

                    <Typography sx={{ fontSize: "1rem" }}>Update</Typography>
                  </Button>
                )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100vw",
            position: "absolute",
            borderBottom: "1px solid #D9D9D9",
          }}
        ></Box>
        {filteredIdealProfilesData?.length === 0 && (
          <Box sx={{ padding: "24px 40px" }}>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "1rem",
                paddingBottom: "8px",
              }}
            >
              Share up to 3 ideal candidates with us and we'll suggest
              candidates who closely match the profile.
            </Typography>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "0.875rem",
              }}
            >
              No similar profiles added. Add one{" "}
              <span
                onClick={openModal}
                style={{ cursor: "pointer", textDecoration: "underline" }}
              >
                here
              </span>
              !
            </Typography>
          </Box>
        )}
        {filteredIdealProfilesData && filteredIdealProfilesData.length > 0 && (
          <Box
            sx={{
              display: "flex",
              padding: "24px 40px",
              flexWrap: "wrap",
              margin: "0 auto",
              overflow: "auto",
              height: "calc(100vh - 291px)",
            }}
          >
            {filteredIdealProfilesData
              .sort((a: IdealProfilesSc, b: IdealProfilesSc) => {
                return (
                  new Date(b.createdDate as Date).getTime() -
                  new Date(a.createdDate as Date).getTime()
                );
              })
              .map((profile: IdealProfilesSc, index: number) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    maxWidth: "686px",
                    width: "90%",
                    margin: "0 auto",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "1.25rem",
                      lineHeight: "30px",
                      padding: "16px 0",
                    }}
                  >
                    {dateFormater(profile.createdDate as string, {
                      dateStyle: "long",
                    })}
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "#FFF",
                      boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.1)",
                      padding: "24px",
                      display: "flex",
                      borderRadius: "8px",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      width: "100%",
                      gap: 1,
                    }}
                    key={index}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {index === 0 && (
                        <FiberManualRecordIcon
                          sx={{
                            border: "1px solid #E5F4E7",
                            backgroundColor: "#E5F4E7",
                            borderRadius: "100%",
                            width: "24px",
                            height: "24px",
                            color: "#4C8E5E",
                          }}
                        />
                      )}

                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "1rem",
                          paddingLeft: "5px",
                        }}
                      >
                        {index > 0 ? "Previous Version" : "Current Version"}{" "}
                        <span
                          style={{
                            color: "#8F8F8F",
                            paddingLeft: "10px",
                            lineHeight: "24px",
                          }}
                        >
                          {dateFormater(profile.createdDate as string, {
                            timeStyle: "short",
                          })}
                        </span>
                      </Typography>
                    </Box>
                    <Divider></Divider>
                    {profile.profiles.map(
                      (idealProfile: IdealProfilesData, index: number) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            backgroundColor: "#FFF",
                            padding: "10px",
                          }}
                        >
                          <PersonIcon
                            sx={{
                              fontSize: "1.8rem",
                              marginTop: "11px",
                              marginRight: "5px",
                              backgroundColor: "#F6F6F6",
                            }}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              backgroundColor: "#FFF",
                              padding: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#181818",
                                fontSize: "14px",
                                fontWeight: 700,
                                lineHeight: "24px",
                              }}
                            >
                              Ideal Profile
                            </Typography>
                            <Typography
                              sx={{
                                color: "#7A7A7A",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "24px",
                              }}
                            >
                              {idealProfile.linkedInUrl}
                            </Typography>{" "}
                            <Typography
                              sx={{
                                color: "#7A7A7A",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "24px",
                              }}
                            >
                              {idealProfile.comments}
                            </Typography>
                          </Box>
                        </Box>
                      )
                    )}
                  </Box>
                </Box>
              ))}
          </Box>
        )}

        <Modal open={modalIsOpen} onClose={closeModal}>
          <Box sx={{ ...modalStyle, height: "85%" }}>
            <Box
              sx={{
                ...headerStyle,
                borderBottom: "1px solid #D9D9D9",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  padding: 2,
                }}
              >
                <Box>
                  <ArrowBackIosIcon
                    onClick={closeModal}
                    sx={{ paddingTop: "4px" }}
                  />
                </Box>
                <Typography
                  sx={{
                    color: "#000",
                    fontWeight: 700,
                    width: "100%",
                    fontSize: "20px",
                    textAlign: "center",
                    margin: "0 auto",
                  }}
                >
                  Discover Similar Profiles
                </Typography>
                <CloseOutlinedIcon
                  onClick={closeModal}
                  sx={{ marginLeft: "auto" }}
                />
              </Box>
            </Box>

            <Box sx={bodyStyle}>
              {" "}
              <SimularProfiles
                handleCancel={closeModal}
                handleUpdateSimilarProfiles={handleUpdateSimilarProfiles}
                onChangeData={setActiveStatus}
                idealProfilesData={idealProfilesDataModal}
                isOverviewTab={true}
                changeStep={() => {}}
              ></SimularProfiles>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

import React from "react"; // eslint-disable-line no-unused-vars
import styles from "./header.module.scss";
import {
  AppBar,
  Container,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Menu,
  MenuItem,
  Button,
  Link,
  Modal,
  Avatar,
  useTheme,
} from "@mui/material";
import {
  BorderTop,
  Menu as MenuIcon,
  TheaterComedy,
} from "@mui/icons-material";

import talentLabLogoBlack from "../assets/images/talentLabLogoBlack.png";
import talentLabLogoSm from "../assets/images/talentLabLogoSmall.png";

import { NavItems } from "../enums/nav-items";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import { SignoutIcon } from "../utilities/icons/index";

import { InboxIcon } from "../utilities/icons";
export interface HeaderProps {
  showMenu: boolean;
  filter: string;
  handleFilterChange: (display: string) => void;
  logout: () => void;
}

export const Header = ({
  showMenu,
  handleFilterChange,
  logout,
}: HeaderProps): JSX.Element => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(
    null
  );
  const [openContactUsModal, setopenContactUsModal] = React.useState(false);
  const handleOpenContactUsModal = () => setopenContactUsModal(true);
  const handleCloseopenContactUsModal = () => setopenContactUsModal(false);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorElMenu(null);
  };
  const open = Boolean(anchorElMenu);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 552,
    bgcolor: "background.paper",
    borderRadius: "16px",
    outline: "none",
    p: 2,
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };
  return (
    <>
      {" "}
      <Modal
        open={openContactUsModal}
        onClose={handleCloseopenContactUsModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            maxWidth: { xs: "552px", lg: "unset" },
            width: { xs: "100%", lg: "552px" },
          }}
        >
          <Box sx={{ display: "flex", padding: "8px 0 24px" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontWeight: 500,
                lineHeight: "30px",
                fontSize: "20px",
              }}
            >
              Contact Us
            </Typography>
            <CloseIcon
              sx={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={handleCloseopenContactUsModal}
            />
          </Box>
          <Box
            sx={{
              borderTop: `1px solid #D9D9D9`,
              borderBottom: "1px solid #D9D9D9",
              padding: " 8px",
              display: "flex",
              flexDirection: "row",
              flexwrap: "nowrap",
              gap: 2,
            }}
          >
            <Box
              sx={{
                padding: "32px 8px 24px 0 ",
                display: "flex",
                flexDirection: "column",
                flexwrap: "nowrap",
                gap: 2,
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: "#eaf6fb",
                  color: "#000",
                  width: "56px",
                  height: "56px",
                }}
              >
                <a href="mailto: support@talentlabs.ai">
                  <InboxIcon />
                </a>
              </Avatar>

              <Box>
                {" "}
                <Typography
                  variant="h6"
                  component="h2"
                  color="#000000"
                  fontSize={"20px"}
                >
                  Have any questions or concerns?
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  color="#18181880"
                  sx={{
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "24p",
                  }}
                >
                  Please reach out to your Account Manager with any questions.
                </Typography>
              </Box>
            </Box>
          </Box>{" "}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              justifyContent: "flex-end",
              padding: "24px 0 8px",
            }}
          >
            <Button
              variant="text"
			  color="secondary"
			  sx={{
				borderRadius: "10px",
				color: "#181818",
				border: "1px solid #ABABAB",
				padding: "8px 0",
				maxHeight: "40px",
				maxWidth: "82px",
			
			  }}
              onClick={handleCloseopenContactUsModal}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
      <AppBar
        sx={{
          backgroundColor: "#fff",
          position: "fixed",
          minHeight: "72px",
          boxShadow: "none",
          borderBottom: "0.5px solid #D2D2D2",
        }}
      >
        <Container
          sx={{
            marginY: "auto",
            maxWidth: { lg: "1696px" },
            width: "100%",
          }}
        >
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                textDecoration: "none",
              }}
            >
              <img src={talentLabLogoBlack} style={{ height: "30px" }} />
            </Typography>

            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img src={talentLabLogoSm} style={{ height: "42px" }} />
            </Typography>
            {showMenu && (
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  alignItems: "flex-end",
                  marginLeft: "50px",
                }}
              >
                <Button
                  onClick={() => {
                    handleFilterChange(NavItems.Dashboard);
                  }}
                  sx={{
                    color: "white",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "24px",
                  }}
                >
                  <span
                    className={styles["nav-item"]}
                    style={{ fontWeight: 700 }}
                  >
                    {NavItems.Dashboard}
                  </span>
                </Button>

                <Button
                  onClick={handleOpenContactUsModal}
                  sx={{
                    color: "white",
                    display: "block",
                    marginRight: "24px",
                  }}
                >
                  <span className={styles["nav-item"]}>Contact Us</span>
                </Button>
                <Avatar
                  onClick={handleOpenMenu}
                  sx={{
                    color: "#34004D",
                    backgroundColor: "#F7E6FF",
                    fontWeight: "medium",
                  }}
                >
                  {" "}
                  TL
                </Avatar>
                <Menu
                  anchorEl={anchorElMenu}
                  id="account-menu"
                  open={open}
                  onClose={handleCloseMenu}
                  onClick={handleCloseMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      backgroundColor: "#fff",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                        padding: "12px 24px",
                        boxShadow: "2px 2px 6px 0px rgba(0, 0, 0, 0.12)",
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",

                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,

                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                  }}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      logout();
                    }}
                    sx={{ marginX: 1, backgroundColor: "#fff" }}
                  >
                    <SignoutIcon sx={{ marginRight: 1 }} /> Sign Out
                  </MenuItem>
                </Menu>
              </Box>
            )}
            <Box
              sx={{
                flexGrow: 0,
                marginLeft: "auto",
              }}
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2, display: { xs: "flex", md: "none" } }}
                onClick={handleMenu}
              >
                <MenuIcon
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    handleFilterChange(NavItems.Dashboard);
                    handleClose();
                  }}
                >
                  {NavItems.Dashboard}
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <Link
                    href="mailto:support@talentlabs.ai"
                    sx={{ color: "black", textDecoration: "none" }}
                  >
                    Contact Us
                  </Link>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    logout();
                  }}
                >
                  Sign Out
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import {
  bodyStyle,
  headerStyle,
  footerStyle,
  modalStyle,
} from "../../utilities/modalStyles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ResizableTextarea } from "../../utilities/textAreaAutosize";
import { countWords } from "../../utilities/wordsCounter";
import { VectorStrokeIcon } from "../../utilities/icons/vectorStroke";
import { RoleModalRequest, SearchCriteria } from "../../utilities/roles";
import { getSearchCriteriaById } from "../../utilities/actions";
import { dateFormater } from "../../utilities/dateFormatHelper";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { AddIcon } from "../../utilities/icons/addIcon";

export interface NotesProps {
  activeRoleRequest: RoleModalRequest;
  activeSearchCriteria?: SearchCriteria;
  searchCriteria?: SearchCriteria[];
  updateSearchParameters: (data: SearchCriteria, projectId?: string) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const Notes = ({
  activeRoleRequest,
  activeSearchCriteria,
  searchCriteria,
  updateSearchParameters,
}: NotesProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [searchCriteraData, setSearchCriteraData] = useState<SearchCriteria>();
  const [searchCriteraDataRequest, setSearchCriteriaDataRequest] =
    useState<SearchCriteria>();
  const [searchParametersData, setSearchParametersData] = useState<
    SearchCriteria[] | undefined
  >([]);
  const [filteredSearchParametersData, setFilteredSearchParametersData] =
    useState<SearchCriteria[] | undefined>();
  const [datesForSelect, setDatesForSelect] = useState<
    SearchCriteria[] | undefined
  >([]);
  const [qualifiedContent, setQualifiedContent] = useState("");
  const [needsReviewContent, setNeedsReviewContent] = useState("");
  const [noteContent, setNoteContent] = useState("");
  const [wordsCounter, setWordsCounter] = useState<number>(0);

  const [selectedValue, setSelectedValue] = useState<string | Date | undefined>(
    "All"
  );

  const handleReviewChange = (e: any) => {
    const value = e.target.value;
    setNeedsReviewContent(value);
    setWordsCounter(countWords(value));
  };
  const handleQualifiedChange = (e: any) => {
    const value = e.target.value;
    setQualifiedContent(value);
    setWordsCounter(countWords(value));
  };
  const handleNotesChange = (e: any) => {
    const value = e.target.value;
    setNoteContent(value);
    setWordsCounter(countWords(value));
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    setSearchCriteraData(activeSearchCriteria);
    const searchParametersData = searchCriteria?.map(
      (searchParameter: SearchCriteria) => {
        const originalDate = new Date(searchParameter.createdDate as Date);

        const formattedDate = new Intl.DateTimeFormat("en-US", {
          dateStyle: "long",
          timeStyle: "short",
        }).format(originalDate);
        const formattedDateForSelect = new Intl.DateTimeFormat("en-US", {
          dateStyle: "long",
        }).format(originalDate);
        const datePart = formattedDate.split(" at ")[0];
        const timePart = formattedDate.split(" at ")[1];
        return {
          ...searchParameter,
          createdDate: `${datePart} ${timePart}`,
          dateForSelect: formattedDateForSelect,
        };
      }
    );
    setSearchParametersData(searchParametersData);
    setDatesForSelect(searchParametersData);
  }, [activeSearchCriteria, searchCriteria]);

  useEffect(() => {
    if (modalIsOpen) {
      setQualifiedContent(searchCriteraData?.qualified || "");
      setNeedsReviewContent(searchCriteraData?.needs || "");
      setNoteContent(searchCriteraData?.notes || "");
    }
  }, [modalIsOpen, activeSearchCriteria]);

  const handleAddNotes = () => {
    const newData = {
      needs: needsReviewContent,
      qualified: qualifiedContent,
      notes: noteContent,
    };

    setSearchParametersData((prevData) => {
      const currentData = prevData || [];
      const updatedData = [...currentData, newData];
      return updatedData;
    });

    setSearchCriteriaDataRequest((prevData) => {
      return {
        searchCriteriaId: searchCriteraData?.searchCriteriaId || "",
        needs: needsReviewContent || "",
        qualified: qualifiedContent || "",
        notes: noteContent || "",
      };
    });
  };

  useEffect(() => {
    if (searchCriteraDataRequest) {
      updateSearchParameters(
        searchCriteraDataRequest,
        activeRoleRequest.projectId
      );
    }
  }, [searchCriteraDataRequest]);

  const getSearchCriteria = (
    projectId: string | undefined,
    scId: string | undefined
  ) => {
    const getData = async (): Promise<void> => {
      let results = await getSearchCriteriaById(projectId, scId);
      setSearchCriteraData((prevData) => {
        return {
          needs: results?.qualified,
          qualified: results?.needs,
          notes: results?.notes,
        };
      });
    };
    getData();
  };

  useEffect(() => {
    if (searchParametersData && searchParametersData.length > 0) {
      setSelectedValue("All");
    }
    setFilteredSearchParametersData(searchParametersData);
  }, [searchParametersData]);

  const handleChange = (event: any) => {
    const selectedOption = event.target.value;

    const selectedSearchCriteria = searchParametersData?.find(
      (sp: SearchCriteria) => sp.createdDate === selectedOption
    );

    if (selectedSearchCriteria) {
      getSearchCriteria(
        activeRoleRequest.projectId,
        selectedSearchCriteria.searchCriteriaId
      );
    }

    setSelectedValue(selectedOption);
    if (selectedOption !== "All") {
      const formattedOption = dateFormater(selectedOption, {
        dateStyle: "long",
      });

      const filteredSPByDate = searchParametersData?.filter(
        (sp: SearchCriteria) => {
          return sp.dateForSelect
            ?.toLocaleString()
            .startsWith(formattedOption as string);
        }
      );

      if (filteredSPByDate) {
        setFilteredSearchParametersData(filteredSPByDate);
      }
    } else {
      setFilteredSearchParametersData(searchParametersData);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "24px 40px",
            width: "100%",
            position: "sticky",
            top: 0,
            backgroundColor: "#F7F7FF",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#181818",
                fontSize: "1.5rem",
              }}
            >
              Search Parameters
            </Typography>
          </Box>
          <Box>
            <Box sx={{ display: "flex", height: "40px", alignItems: "center" }}>
              {/* <Typography sx={{ lineHeight: "20px" }}>View By</Typography>
              <FormControl
                sx={{
                  m: 1,
                  maxWidth: 180,
                  width: "fit-content",
                }}
              >
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  sx={{
                    "& .MuiInputBase-input": {
                      padding: "5px",
                      fontSize: "1rem",
                    },
                    "&. MuiFormLabel-root": {
                      display: "hidden",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  value={selectedValue?.toString()}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {datesForSelect
                    ?.sort((a: SearchCriteria, b: SearchCriteria) => {
                      return (
                        new Date(b.createdDate as Date).getTime() -
                        new Date(a.createdDate as Date).getTime()
                      );
                    })
                    .filter(
                      (
                        searchParameter: SearchCriteria,
                        index: number,
                        array: SearchCriteria[]
                      ) => {
                        return (
                          index === 0 ||
                          searchParameter.dateForSelect !==
                            array[index - 1].dateForSelect
                        );
                      }
                    )
                    .map((searchParameter: SearchCriteria, index: number) => (
                      <MenuItem
                        key={index}
                        value={searchParameter.createdDate as string}
                      >
                        {searchParameter.dateForSelect as string}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}

              {filteredSearchParametersData?.length === 0 && (
                <Button
                  variant="text"
                  sx={{
                    borderRadius: "10px",
                    width: "92px",
                    padding: "5px 20px 5px 15px",
                    color: "#FFF",
                    backgroundColor: "#9F14E0",
                    transition: "none",
                    "& .MuiSvgIcon-root": {
                      marginRight: "8px",

                      color: "#FFF",
                    },
                    "&:hover": {
                      backgroundColor: "#CE66FF",
                      boxShadow: "none",
                    },
                  }}
                  onClick={openModal}
                >
                  <AddIcon />

                  <Typography sx={{ fontSize: "1rem" }}>Add</Typography>
                </Button>
              )}
              {filteredSearchParametersData &&
                filteredSearchParametersData?.length > 0 && (
                  <Button
                    variant="text"
                    sx={{
                      borderRadius: "10px",
                      width: "116px",
                      padding: "5px 20px 5px 15px",
                      color: "#FFF",
                      backgroundColor: "#9F14E0",
                      transition: "none",
                      "& .MuiSvgIcon-root": {
                        fontSize: "22px",
                        marginRight: "8px",
                        color: "#FFF",
                      },
                      "&:hover": {
                        backgroundColor: "#CE66FF",
                        boxShadow: "none",
                      },
                    }}
                    onClick={openModal}
                  >
                    <VectorStrokeIcon status={""} />

                    <Typography sx={{ fontSize: "1rem" }}>Update</Typography>
                  </Button>
                )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100vw",
            position: "absolute",
            borderBottom: "1px solid #D9D9D9",
          }}
        ></Box>
        {filteredSearchParametersData?.length === 0 && (
          <Box sx={{ padding: "24px 40px" }}>
            <Typography
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "1rem",
              }}
            >
              Search parameters have not been set yet. Adding these will help us
              find candidates that suit your needs!
            </Typography>
          </Box>
        )}

        {filteredSearchParametersData &&
          filteredSearchParametersData?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                padding: "24px 40px",
                flexWrap: "wrap",
                margin: "0 auto",
                overflow: "auto",
                height: "calc(100vh - 291px)",
              }}
            >
              {filteredSearchParametersData
                ?.sort((a: SearchCriteria, b: SearchCriteria) => {
                  return (
                    new Date(b.createdDate as Date).getTime() -
                    new Date(a.createdDate as Date).getTime()
                  );
                })
                .map(
                  (
                    searchParameter: SearchCriteria,
                    index: number,
                    array: SearchCriteria[]
                  ) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        maxWidth: "686px",
                        width: "90%",
                        margin: "0 auto",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "1.25rem",
                          lineHeight: "30px",
                          padding: "16px 0",
                        }}
                      >
                        {searchParameter.dateForSelect?.toLocaleString()}
                      </Typography>
                      <Box
                        sx={{
                          backgroundColor: "#FFF",
                          boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.1)",
                          padding: "24px",
                          display: "flex",
                          borderRadius: "8px",
                          flexWrap: "wrap",
                          flexDirection: "column",
                          width: "100%",
                          gap: 3,
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {index ===
                            searchParametersData?.findIndex(
                              (item) => item === array[0]
                            ) && (
                            <FiberManualRecordIcon
                              sx={{
                                border: "1px solid #E5F4E7",
                                backgroundColor: "#E5F4E7",
                                borderRadius: "100%",
                                width: "24px",
                                height: "24px",
                                color: "#4C8E5E",
                              }}
                            />
                          )}
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "1rem",
                              paddingLeft: "5px",
                            }}
                          >
                            {index ===
                            searchParametersData?.findIndex(
                              (item) => item === array[0]
                            )
                              ? "Current Version"
                              : "Previous Version"}{" "}
                            <span
                              style={{
                                color: "#8F8F8F",
                                paddingLeft: "10px",
                                lineHeight: "24px",
                              }}
                            >
                              {dateFormater(
                                searchParameter.createdDate as string,
                                {
                                  timeStyle: "short",
                                }
                              )}
                            </span>
                          </Typography>
                        </Box>
                        <Divider></Divider>
                        <Box sx={{ whiteSpace: "pre-wrap" }}>
                          <Box
                            sx={{
                              borderRadius: "10px",
                              padding: "0 6px",
                              backgroundColor: "#6AA171",
                              width: "fit-content",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "0.875rem",
                              }}
                            >
                              Qualified
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                padding: "8px",
                                borderRadius: "10px",
                                color: "#7A7A7A",
                              }}
                            >
                              {searchParameter?.qualified}
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{ whiteSpace: "pre-wrap" }}>
                          <Box
                            sx={{
                              borderRadius: "10px",
                              padding: "0 6px",
                              backgroundColor: "#DE901A",
                              width: "fit-content",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "0.875rem",
                              }}
                            >
                              Needs Review
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                padding: "8px",
                                borderRadius: "10px",
                                color: "#7A7A7A",
                              }}
                            >
                              {searchParameter?.needs}
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{ whiteSpace: "pre-wrap" }}>
                          <Box
                            sx={{
                              borderRadius: "10px",
                              padding: "0 6px",
                              backgroundColor: "#ABABAB",
                              width: "fit-content",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "0.875rem",
                              }}
                            >
                              Notes
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                padding: "8px",
                                borderRadius: "10px",
                                color: "#7A7A7A",
                              }}
                            >
                              {searchParameter?.notes}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )
                )}
            </Box>
          )}

        <Modal open={modalIsOpen} onClose={closeModal}>
          <Box
            sx={{ ...modalStyle, minHeight: "345px", height: "fit-content" }}
          >
            <Box
              sx={{
                ...headerStyle,
                borderBottom: "1px solid #D9D9D9",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: 2,
                }}
              >
                <Box>
                  <ArrowBackIosIcon
                    onClick={closeModal}
                    sx={{ paddingTop: "4px" }}
                  />
                </Box>
                <Typography
                  sx={{
                    color: "#000",
                    fontWeight: 400,
                    width: "100%",
                    fontSize: "20px",
                    textAlign: "center",
                    margin: "0 auto",
                  }}
                >
                  Search Parameter
                </Typography>
                <CloseOutlinedIcon
                  onClick={closeModal}
                  sx={{ marginLeft: "auto" }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                ...bodyStyle,
                gap: 0.5,
                padding: "0 16px",
                margin: "16px 0",
                maxHeight: "calc(100vh - 467px)",
                overflowY: "auto",
              }}
            >
              <ResizableTextarea
                label="Qualified"
                value={qualifiedContent}
                isSearchPropertiesPage={true}
                onChange={handleQualifiedChange}
              ></ResizableTextarea>{" "}
              <ResizableTextarea
                label="Needs Review"
                value={needsReviewContent}
                onChange={handleReviewChange}
                isSearchPropertiesPage={true}
              ></ResizableTextarea>{" "}
              <ResizableTextarea
                label="Notes"
                value={noteContent}
                isSearchPropertiesPage={true}
                onChange={handleNotesChange}
              ></ResizableTextarea>{" "}
              {/* <Typography
                sx={{
                  marginLeft: "auto",
                  fontSize: "12px",
                  color: "#8F8F8F",
                }}
              >
                {wordsCounter}/1000 Words
              </Typography> */}
            </Box>

            <Divider></Divider>
            <Box sx={footerStyle}>
              <Button
                onClick={closeModal}
                sx={{
                  borderRadius: "10px",
                  color: "#181818",
                  border: "1px solid #ABABAB",
                  padding: "8px 16px",
                  maxHeight: "40px",
                  maxWidth: "82px",
                }}
                variant="text"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  closeModal();
                  handleAddNotes();
                }}
                disabled={qualifiedContent.length === 0}
                variant="contained"
                color="secondary"
                sx={{
                  color: "#fff",
                  borderRadius: "10px",
                  padding: "8px 16px",
                  maxHeight: "40px",
                  maxWidth: "136px",
                  "&:hover": {
                    backgroundColor: "#CE66FF",
                    boxShadow: "none",
                  },
                }}
              >
                Save Changes
              </Button>
            </Box>
          </Box>
        </Modal>
      </>
    </Box>
  );
};

import { Skill, Education, Experience, Certification } from "./";

export type Candidate = {
  projectId: string;
  searchCriteriaId: string;
  id: string;
  name: string;
  summary: string;
  status: string;
  linkedInUrl: string;
  location: string;
  salary: string;
  notes: string;
  skills: string[];
  educations: Educations[];
  experiences: Experiences[];
  certifications: string[];
  confidence: number;
  confidenceReason: string;
  dateCreated: Date;
  sourcerComments: string;
  statusComments: string;
  statusMatrix: StatusMatrix;
};

export interface Educations {
  dateRange: string;
  degree: string;
  information: string;
  linkedInCompanyID: string;
  school: string;
}
export interface Experiences {
  linkedInCompanyID: number;
  company: string;
  role: string;
  tenure: string;
  location: string;
  information: string;
}

export type Candidates = {
  candidateId: string;
  status: string;
  notes: string;
  impersonationId: string;
};

export interface StatusMatrix {
  Education: string;
  Experience: string;
  Level: string;
  "Previous Company": string;
  Region: string;
  Skills: string;
  [key: string]: string;
}

export enum SortByDateCandidate {
  Lastest = 1,
  Oldest = 2,
}

export enum FilterCandidates {
  All = 1,
  ScoreGreaterThan90 = 90,
  ScoreGreaterThan80 = 80,
  Qualified = 70,
  NeedsReview = 60,
  ApprovedCandidates = "Approved",
  RejectedCandidates = "Rejected",
}

import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const AddIcon = (props: any) => {
  return (
    <SvgIcon >
      <svg
        width="23"
        height="24"
        viewBox="0 0 23 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9503 12.7665C20.3592 12.7661 20.686 12.4199 20.6802 11.9932C20.6745 11.5664 20.3383 11.2208 19.9294 11.2211L12.2411 11.2277L12.2474 3.20514C12.2478 2.77844 11.9165 2.42767 11.5075 2.42167C11.0986 2.41567 10.7668 2.75671 10.7665 3.1834L10.7601 11.229L3.04975 11.2356C2.64083 11.236 2.314 11.5822 2.31975 12.0089C2.3255 12.4357 2.66166 12.7813 3.07058 12.781L10.7589 12.7744L10.7526 20.797C10.7522 21.2237 11.0835 21.5744 11.4925 21.5804C11.9014 21.5864 12.2332 21.2454 12.2335 20.8187L12.2399 12.7731L19.9503 12.7665Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const InboxIcon = (props: any) => {
	return (
		<SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.57285 5.47629C4.75466 5.41054 4.94905 5.375 5.15 5.375H18.85C19.0475 5.375 19.2391 5.40952 19.4189 5.47355L12.9016 12.1724L12.9 12.174C12.3774 12.7151 11.6233 12.7195 11.0868 12.1717L11.0863 12.1711L4.57285 5.47629ZM3.62052 6.28999C3.46497 6.56527 3.375 6.88748 3.375 7.23493V16.7605C3.375 17.0011 3.4179 17.2289 3.49554 17.4373L9.11161 11.934L3.62052 6.28999ZM9.98327 12.83L4.30432 18.3949C4.55798 18.5393 4.8471 18.6205 5.15 18.6205H18.85C19.155 18.6205 19.4447 18.5397 19.6981 18.396L14.0814 12.7523L13.7991 13.0424L13.7983 13.0432C12.781 14.0957 11.2161 14.0912 10.1928 13.0453L10.1916 13.0441L9.98327 12.83ZM20.625 16.7605C20.625 17.0006 20.5828 17.228 20.5062 17.4359L14.9531 11.8562L20.3756 6.28273C20.5334 6.55892 20.625 6.8834 20.625 7.23493V16.7605ZM5.15 4.125C3.45285 4.125 2.125 5.54543 2.125 7.23493V16.7605C2.125 18.4638 3.46618 19.8705 5.15 19.8705H18.85C20.5455 19.8705 21.875 18.4621 21.875 16.7605V7.23493C21.875 5.53163 20.5338 4.125 18.85 4.125H5.15Z"
				fill="#181818"
			/>
		</SvgIcon>
	);
};

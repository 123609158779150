import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Upload } from "../upload";

import React, { useEffect, useState } from "react";
import { AddNewRoleStep } from "../../types";
import { FileUploadOutlined } from "@mui/icons-material";
import {
  ActiveStep,
  JobDescriptionDetails,
  RoleStatus,
} from "../../enums/addRole";
import { RoleModalRequest } from "../../utilities/roles";
import { ResizableTextarea } from "../../utilities/textAreaAutosize";
import { UploadIcon } from "../../utilities/icons/uploadIcon";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { NavItems } from "../../enums/nav-items";
export interface JobDescriptionProps {
  activeRoleStep: AddNewRoleStep;
  isSearchCriteriaTab: boolean;
  activeRoleRequest?: RoleModalRequest;
  isOverviewTab?: boolean;
  sendRequest: (data: JobDescriptionDetails, route: NavItems) => void;
  handleUpload: (file: File, title: string) => void;
  handleUpdateJobDescription: (data: RoleModalRequest) => void;
  handleCancel: () => void;
}
export const JobDescription = ({
  isOverviewTab,
  activeRoleStep,
  isSearchCriteriaTab,
  activeRoleRequest,
  sendRequest,
  handleUpdateJobDescription,
  handleCancel,
  handleUpload,
}: JobDescriptionProps) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState<AddNewRoleStep>(activeRoleStep);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [file, setFile] = useState<null | File>(null);
  const [fileLenght, setFileLenght] = useState<number>(0);
  const [uploadModalIsOpen, setUploadModalIsOpen] = useState<boolean>(false);
  const [jobDescriptionUrlInput, setJobDescriptionUrlInput] = useState<string>(
    activeRoleRequest ? activeRoleRequest.description : ""
  );
  const [jobPostingUrlInput, setJobPostingUrlInput] = useState<string>();
  // activeRoleRequest ? activeRoleRequest.jobDescriptionUrl : ""
  const [errorForJobPostingUrlInput, seterrorForJobPostingUrlInput] =
    useState<boolean>(false);

  const [jobTitle, setJobTitle] = useState<string>(
    activeRoleRequest ? activeRoleRequest.title : ""
  );
  const [errorForJobTitle, setErrorForJobTitle] = useState<boolean>(false);
  const [roleCategory, setRoleCategory] = useState<string>(
    activeRoleRequest ? activeRoleRequest.role : ""
  );
  const [location, setLocation] = useState<string>(
    activeRoleRequest ? activeRoleRequest.location : ""
  );
  const [workEnviroment, setWorkEnviroment] = useState<string>(
    activeRoleRequest ? activeRoleRequest.environment : ""
  );
  const [errorForworkEnviroment, setErrorForworkEnviroment] =
    useState<boolean>(false);

  const [degree, setDegree] = useState<string>(
    activeRoleRequest ? activeRoleRequest.degree : ""
  );
  const [errorForDegree, setErrorForDegree] = useState<boolean>(false);
  const [minYearsOfExp, setMinYearsOfExp] = useState<number | string>(
    activeRoleRequest ? activeRoleRequest.minYearsExperience : 0
  );
  const [minYearsErrorMessage, setMinYearsErrorMsg] = useState<string>("");
  const [maxYearsOfExp, setMaxYearsOfExp] = useState<number | string>(
    activeRoleRequest ? activeRoleRequest.maxYearsExperience : 0
  );
  const [maxYearsErrorMessage, setMaxYearsErrorMsg] = useState<string>("");

  const [workType, setWorkType] = useState<string>(
    activeRoleRequest ? activeRoleRequest.department : ""
  );
  const [errorForWorkType, setErrorForWorkType] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(
    activeRoleRequest ? activeRoleRequest.description : ""
  );
  const [errorForNotes, setErrorForNotes] = useState<boolean>(false);
  const [dataForRequest, setDataForRequest] = useState<RoleModalRequest>({
    projectId: "",
    title: "",
    description: "",
    department: "",
    location: "",
    environment: "",
    role: "",
    degree: "",
    minYearsExperience: "",
    maxYearsExperience: "",
    skills: [],
    stages: [],
    searchCriteria: [],
  });

  const handleJobUrlChange = (e: any) => {
    const value = e.target.value;
    setJobDescriptionUrlInput(value);
  };
  const handleJobPostingUrlChange = (e: any) => {
    const value = e.target.value;
    setJobPostingUrlInput(value);
    if (value.length === 0) {
      seterrorForJobPostingUrlInput(true);
    } else {
      seterrorForJobPostingUrlInput(false);
    }
  };
  const handleChangeJobTitle = (e: any) => {
    const value = e.target.value;
    setJobTitle(value);
    if (value.length === 0) {
      setErrorForJobTitle(true);
    } else {
      setErrorForJobTitle(false);
    }
  };
  const handleChangeRoleCategory = (e: any) => {
    const value = e.target.value;
    setRoleCategory(value);
  };
  const handleLocationChange = (e: any) => {
    const value = e.target.value;
    setLocation(value);
  };
  const handleWorkEnvChange = (e: any) => {
    const value = e.target.value;
    setWorkEnviroment(value);
    if (value) {
      if (value.length === 0) {
        setErrorForworkEnviroment(true);
      } else {
        setErrorForworkEnviroment(false);
      }
    } else {
      setErrorForworkEnviroment(true);
    }
  };

  const handleDegreeChange = (e: any) => {
    const value = e.target.value;
    setDegree(value);
    if (value) {
      if (value.length === 0) {
        setErrorForDegree(true);
      } else {
        setErrorForDegree(false);
      }
    } else {
      setErrorForDegree(true);
    }
  };

  const handleMinYearExpChange = (event: any) => {
    const value = event.target.value.replace(/\D/g, "");
    event.target.value = value;

    setMinYearsOfExp(+event.target.value);

    if (isNaN(+event.target.value) || !value ) {
      setMinYearsErrorMsg("Please enter a numerical value.");
    } else if (+event.target.value > 40) {
      setMinYearsErrorMsg("Please enter a numerical value below 41.");
    } else {
      setMinYearsErrorMsg("");
    }
  };

  const handleMaxYearExpChange = (event: any) => {
    const value = event.target.value.replace(/\D/g, "");
    event.target.value = value;
    setMaxYearsOfExp(+event.target.value);
    if(isNaN(+event.target.value) || !value) {
      setMaxYearsErrorMsg("Please enter a numerical value.");
    } else if (+event.target.value > 40) {
      setMaxYearsErrorMsg("Please enter a numerical value below 41.");
    } else {
      setMaxYearsErrorMsg("");
    }
  };

  const [skills, setSkils] = useState(
    activeRoleRequest
      ? [
          ...activeRoleRequest?.skills.map((skill: string) => {
            return { type: "text", value: skill };
          }),
        ]
      : [{ type: "text", value: "" }]
  );

  const addNewSkill = () => {
    setSkils([...skills, { type: "text", value: "" }]);
  };

  const handleSkillChange = (index: number, event: any) => {
    const newSkills = [...skills];
    newSkills[index].value = event.target.value;
    setSkils(newSkills);
  };

  const handleWorkTypeChange = (e: any) => {
    const value = e.target.value;
    setWorkType(value);
    if (value) {
      if (value.length === 0) {
        setErrorForWorkType(true);
      } else {
        setErrorForWorkType(false);
      }
    } else {
      setErrorForWorkType(true);
    }
  };
  const handleNotesChange = (e: any) => {
    const value = e.target.value;
    setDescription(value);

    if (value.length === 0) {
      setErrorForNotes(true);
    } else {
      setErrorForNotes(false);
    }
  };
  useEffect(() => {
    const isFormValid =
      jobTitle.trim().length > 0 &&
      workEnviroment.trim().length > 0 &&
      degree.trim().length > 0 &&
      +minYearsOfExp > 0 &&
      +minYearsOfExp <= 40 &&
      +maxYearsOfExp > 0 &&
      +maxYearsOfExp <= 40 &&
      description?.trim().length > 0;

    setIsButtonDisabled(isFormValid);
  }, [
    jobTitle,
    roleCategory,
    location,
    workEnviroment,
    degree,
    minYearsOfExp,
    maxYearsOfExp,
    skills,
    description,
  ]);
  useEffect(() => {
    const skillsData = skills.filter((skill) => skill.value !== "").map(
      (sk: { type: string; value: string }) => sk.value
    );
    setDataForRequest((prevData) => ({
      ...prevData,
      projectId: activeRoleRequest?.projectId
        ? activeRoleRequest.projectId
        : "",
      title: jobTitle,
      role: roleCategory,
      description: description,
      location: location,
      department: workType,
      environment: workEnviroment,
      degree: degree,
      minYearsExperience: minYearsOfExp.toString(),
      maxYearsExperience: maxYearsOfExp.toString(),
      skills: [...skillsData],
      status: RoleStatus.Active,
    }));
  }, [
    jobTitle,
    roleCategory,
    location,
    workType,
    workEnviroment,
    degree,
    minYearsOfExp,
    maxYearsOfExp,
    skills,
    description,
  ]);

  const handleContinue = (activeStep: AddNewRoleStep) => {
    if (activeStep === AddNewRoleStep.UploadDocument) {
      setActiveStep(AddNewRoleStep.ReviewDataAndConfirm);
    } else {
      if (jobPostingUrlInput?.trim().length === 0) {
        seterrorForJobPostingUrlInput(true);
      }
      if (jobTitle.trim().length === 0) {
        setErrorForJobTitle(true);
      }
      if (workEnviroment.trim().length === 0) {
        setErrorForworkEnviroment(true);
      }
      if (workType.trim().length === 0) {
        setErrorForWorkType(true);
      }
      if (degree.trim().length === 0) {
        setErrorForDegree(true);
      }

      if (minYearsOfExp === 0 || +minYearsOfExp > 40) {
        setMinYearsErrorMsg("Please enter a numerical value below 41.");
      }
      if (maxYearsOfExp === 0 || +maxYearsOfExp > 40) {
        setMaxYearsErrorMsg("Please enter a numerical value below 41.");
      }
      if (description.trim().length === 0) {
        setErrorForNotes(true);
      }
      if (isButtonDisabled) {
        const jobDetails: JobDescriptionDetails = {
          degree,
          jobTitle,
          location,
          maxYearsOfExp,
          jobDescriptionUrl: jobDescriptionUrlInput,
          jobPostingUrl: jobDescriptionUrlInput,
          notes: description,
          roleCategory,
          workType,
          workEnviroment,
          minYearsOfExp,
          requiredSkils: skills.filter((skill) => skill.value !== "").map((skill) => {
            return skill.value;
          }),
        };
        sendRequest(jobDetails, NavItems.Dashboard);
      }
    }
  };

  const handleUploadClick = (id: string): void => {
    const fileInput = document.getElementById(id);
    fileInput?.click();
  };

  const handleUploadFile = (file: File | null): void => {
    setFileLenght(file !== null ? file!.size : 0);
    setFile(file);
    setUploadModalIsOpen(false);
  };
  const handleModalIsOpen = (modalIsOpen: boolean): void => {
    setUploadModalIsOpen(modalIsOpen);
  };
  const openUploadConfirmationModal = (): void => {
    setUploadModalIsOpen(true);
  };

  const handleRemoveFileFromModal = (): void => {
    setFileLenght(0);
    setFile(null);
  };

  const sendReqWithFile = (file: File): void => {
    if (file && jobDescriptionUrlInput.length) {
      handleUpload(file, jobDescriptionUrlInput);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        marginX: "auto",
      }}
    >
      <Box>
        {activeStep === AddNewRoleStep.UploadDocument && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexwrap: "nowrap",
              gap: "8px",
              maxWidth: "600px",
              padding: "16px",
            }}
          >
            <Typography
              id="description-ulr-label"
              sx={{ fontWeight: 700 }}
              fontSize="1.25rem"
            >
              Job Title*
            </Typography>
            <TextField
              autoComplete="off"
              sx={{
                width: "100%",
                borderRadius: "8px",
                color: "#212121",

                "& label.Mui-focused": {
                  color: "#212121",
                  fontSize: "0.875rem",
                },

                "& .MuiInputBase-input": {
                  padding: jobDescriptionUrlInput
                    ? "25px 12px 8px 12px"
                    : "16.5px",
                  fontSize: "1rem",
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={`Job Title*`}
              id="description-url-input"
              label={!jobDescriptionUrlInput ? "" : "Job Title"}
              variant="filled"
              value={jobDescriptionUrlInput}
              InputProps={{ disableUnderline: true }}
              onChange={handleJobUrlChange}
              error={errorForJobPostingUrlInput}
            />

            <Typography
              variant="h6"
              component="h2"
              fontSize="1.25rem"
              sx={{ paddingTop: "8px" }}
            >
              Upload Job Description Document
            </Typography>
            <Upload
              onChoseFile={handleUploadFile}
              onUpload={sendReqWithFile}
              handleModalIsOpen={handleModalIsOpen}
              uploadModalIsOpen={uploadModalIsOpen}
            ></Upload>

            {file && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ position: "relative" }}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "0.75rem" }}
                      gutterBottom
                    >
                      <UploadIcon />{" "}
                      <span
                        style={{
                          position: "absolute",
                          top: "5px",
                          left: "30px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {" "}
                        {file.name}{" "}
                      </span>
                    </Typography>
                  </Box>

                  <Box>
                    <CloseOutlinedIcon
                      onClick={handleRemoveFileFromModal}
                      sx={{
                        width: "1.4rem",
                        height: "1.4rem",
                        color: "#000000",
                      }}
                    />
                  </Box>
                </Box>
              </>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingTop: "8px",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: theme.palette.secondary.light }}
              >
                Manually add role,
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textDecoration: "underline",
                  color: theme.palette.secondary.light,
                  cursor: "pointer",
                  marginLeft: 0.5,
                }}
                onClick={() => handleContinue(AddNewRoleStep.UploadDocument)}
              >
                {" "}
                here{" "}
              </Typography>
            </Box>
          </Box>
        )}

        {activeStep === AddNewRoleStep.ReviewDataAndConfirm && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexwrap: "nowrap",
                paddingLeft: "8px",
                marginBottom: !isSearchCriteriaTab ? "12px" : "unset",
                gap: 1,
                height: {
                  xs: "calc(100vh - 254px)",
                  xl: "576px",
                },
                "@media (min-height: 864px)": {
                  height: "576px",
                },
                overflowY: "auto",
              }}
            >
              {/* <Box>
                {" "}
                <Typography
                  id="job-posting-label"
                  fontSize="1.25rem"
                  fontWeight={500}
                  color="#181818"
                  marginBottom={1}
                >
                  Job Posting Link
                </Typography>
                <TextField
                  autoComplete="off"
                  sx={{
                    maxWidth: "322px",
                    width: "100%",
                    color: "#212121",

                    "& label.Mui-focused": {
                      color: "#212121",
                      fontSize: "0,875rem",
                    },

                    "& .MuiInputBase-input": {
                      padding: jobPostingUrlInput
                        ? "25px 12px 8px 12px"
                        : "15.5px",
                      fontSize: "1rem",
                    },

                    "& .MuiInputBase-input.MuiFilledInput-input:focus": {
                      border: errorForJobPostingUrlInput
                        ? "1px solid transparent "
                        : "1px solid #AD00FF",
                    },
                  }}
                  placeholder="Job Link Url"
                  id="job-posting-url-input"
                  label={!jobPostingUrlInput ? "" : "Job Link Url"}
                  variant="filled"
                  value={jobPostingUrlInput}
                  InputProps={{
                    disableUnderline: true,
                    sx: errorForJobPostingUrlInput
                      ? {
                          border: " 1px solid red",
                        }
                      : {},
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleJobPostingUrlChange}
                  onBlur={handleJobPostingUrlChange}
                  error={errorForJobPostingUrlInput}
                  helperText={
                    errorForJobPostingUrlInput ? "Please use a valid link." : ""
                  }
                />
              </Box> */}

              {/* <Box>
                {" "}
                <Typography
                  id="job-document-upload-label"
                  fontSize="1.25rem"
                  fontWeight={500}
                  color="#181818"
                  marginBottom={1}
                >
                  Job Posting Document
                </Typography>
                <Button
                  sx={{
                    color: theme.palette.info.dark,
                    backgroundColor: "#EFCCFF",
                    fontWeight: 500,
                    boxShadow: "none",
                    height: "38px",
                    "&:hover": {
                      backgroundColor: "#E6B3FF",
                      boxShadow: "none",
                    },
                    padding: "0 16px",
                  }}
                  onClick={() => {
                    handleUploadClick("fileInput");
                  }}
                >
                  <FileUploadOutlined
                    sx={{
                      width: "16px",
                      height: "16px",
                      marginRight: 1,
                    }}
                  />{" "}
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      lineHeight: "18px",
                    }}
                  >
                    Upload Document{" "}
                  </Typography>
                  <input
                    type="file"
                    id="fileInput2"
                    accept=".pdf"
                    style={{ display: "none" }}
                  />
                </Button>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: 300,
                    marginTop: "8px",
                  }}
                >
                  Maximum file size: 10MB.
                </Typography>
                <Typography sx={{ fontSize: "0.75rem", fontWeight: 300 }}>
                  Accepted FIle Types: .doc, .pdf.
                </Typography>
              </Box> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  marginTop: "8px",
                }}
              >
                {" "}
                <Typography
                  id="job-details-label"
                  fontSize="1.25rem"
                  fontWeight={500}
                  color="#181818"
                >
                  Job Details
                </Typography>
                <TextField
                  autoComplete="off"
                  sx={{
                    maxWidth: "322px",
                    borderRadius: "20px",
                    color: "#212121",
                    "& label.Mui-focused": {
                      color: "#212121",
                      fontSize: "0,875rem",
                    },
                    "& .MuiInputBase-input": {
                      padding: jobTitle ? "25px 12px 8px 12px" : "15.5px",
                      fontSize: "1rem",
                    },

                    "& .MuiInputBase-input.MuiFilledInput-input:focus": {
                      border: errorForJobTitle
                        ? "1px solid transparent "
                        : "1px solid #AD00FF",
                    },
                  }}
                  id="job-title-input"
                  label={!jobTitle ? "" : "Job Title"}
                  rows={1}
                  required
                  placeholder={`Job Title*`}
                  error={errorForJobTitle}
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    sx: errorForJobTitle
                      ? {
                          border: " 1px solid red",
                        }
                      : {},
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeJobTitle}
                  onBlur={handleChangeJobTitle}
                  value={jobTitle}
                  helperText={
                    errorForJobTitle
                      ? "Oops! It looks like you forgot to provide input for this field."
                      : ""
                  }
                />
                <TextField
                  autoComplete="off"
                  sx={{
                    maxWidth: "322px",
                    borderRadius: "20px",
                    color: "#212121",
                    "& label.Mui-focused": {
                      color: "#212121",
                      fontSize: "0,875rem",
                    },
                    "& .MuiInputBase-input": {
                      padding: roleCategory ? "25px 12px 8px 12px" : "16.5px",
                      fontSize: "1rem",
                    },
                  }}
                  id="role-category-select"
                  label={!roleCategory ? "" : "Role Category"}
                  placeholder={`Role Category`}
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  value={roleCategory}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeRoleCategory}
                />
                <TextField
                  autoComplete="off"
                  sx={{
                    maxWidth: "322px",
                    borderRadius: "20px",
                    color: "#212121",
                    "& label.Mui-focused": {
                      color: "#212121",
                      fontSize: "0,875rem",
                    },
                    "& .MuiInputBase-input": {
                      padding: location ? "25px 12px 8px 12px" : "16.5px",
                      fontSize: "1rem",
                    },
                  }}
                  id="location-input"
                  label={!location ? "" : "Location"}
                  value={location}
                  placeholder={`Location`}
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleLocationChange}
                />
                <FormControl variant="filled" sx={{ maxWidth: "322px" }}>
                  <InputLabel id="work-enmviroment-label">
                    <span style={{ color: "#ABABAB" }}>
                      {" "}
                      Work Environment*{" "}
                    </span>
                  </InputLabel>
                  <Select
                    sx={{
                      width: "100%",
                      color: "#212121",
                      "& label.Mui-focused": {
                        color: "#212121",
                        fontSize: "0,875rem",
                      },
                      "& .MuiInputBase-input": {
                        padding: workEnviroment
                          ? "25px 12px 8px 12px"
                          : "15.5px",
                        fontSize: "1rem",
                      },

                      "& .MuiInputBase-input.MuiFilledInput-input:focus": {
                        border: "1px solid transparent",
                      },
                    }}
                    inputProps={{
                      disableUnderline: true,
                      sx: errorForworkEnviroment
                        ? {
                            border: "1px solid red",
                          }
                        : {},
                    }}
                    value={workEnviroment}
                    variant="filled"
                    id="onsite-select"
                    disableUnderline
                    required
                    onChange={handleWorkEnvChange}
                    onBlur={handleWorkEnvChange}
                    error={errorForworkEnviroment}
                  >
                    <MenuItem value={"Onsite"}>Onsite</MenuItem>
                    <MenuItem value={"Hybrid"}>Hybrid</MenuItem>
                    <MenuItem value={"Remote"}>Remote</MenuItem>
                  </Select>
                  {errorForworkEnviroment && (
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      Oops! It looks like you forgot to select an option for
                      this field.
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl variant="filled" sx={{ maxWidth: "322px" }}>
                  <InputLabel id="work-type-label"> <span style={{ color: "#ABABAB" }}>
                      {" "}
                      Work Type*{" "}
                    </span></InputLabel>
                  <Select
                    required
                    disableUnderline
                    labelId="work-type-label"
                    id="work-type-select"
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: workType ? "25px 12px 8px 12px" : "15.5px",
                        fontSize: "1rem",
                      },

                      "& .MuiInputBase-input.MuiFilledInput-input:focus": {
                        border: "1px solid transparent",
                      },
                    }}
                    onChange={handleWorkTypeChange}
                    onBlur={handleWorkTypeChange}
                    error={errorForWorkType}
                    value={workType}
                    inputProps={{
                      disableUnderline: true,
                      sx: errorForWorkType
                        ? {
                            border: " 1px solid red",
                          }
                        : {},
                    }}
                  >
                    <MenuItem value={"Internship"}>Internship</MenuItem>
                    <MenuItem value={"Part Time"}>Part Time</MenuItem>
                    <MenuItem value={"Full Time"}>Full Time</MenuItem>
                    <MenuItem value={"Temporary Contract"}>
                      Temporary Contract
                    </MenuItem>
                    <MenuItem value={"Freelance"}>Freelance</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                  {errorForWorkType && (
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      Oops! It looks like you forgot to select an option for
                      this field.
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  flexDirection: "column",
                }}
              >
                {" "}
                <Typography
                  id="Qualifications-label"
                  fontSize="1.25rem"
                  fontWeight={500}
                  color="#181818"
                >
                  Qualifications
                </Typography>
                <FormControl variant="filled" sx={{ maxWidth: "322px" }}>
                  <InputLabel id="qualifications-input-label">
                 <span style={{ color: "#ABABAB" }}>
                      {" "}
                      Required Degree*{" "}
                    </span>
                  </InputLabel>
                  <Select
                    required
                    disableUnderline
                    labelId="qualifications-input-label"
                    id="qualifications-input"
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: degree ? "25px 12px 8px 12px" : "15.5px",
                        fontSize: "1rem",
                      },

                      "& .MuiInputBase-input.MuiFilledInput-input:focus": {
                        border: "1px solid transparent",
                      },
                    }}
                    onChange={handleDegreeChange}
                    onBlur={handleDegreeChange}
                    error={errorForDegree}
                    value={degree}
                    inputProps={{
                      disableUnderline: true,
                      sx: errorForDegree
                        ? {
                            border: " 1px solid red",
                          }
                        : {},
                    }}
                  >
                    <MenuItem value={`Associates's Degree`}>
                      Associates's Degree
                    </MenuItem>
                    <MenuItem value={`Bachelor's Degree`}>
                      Bachelor's Degree
                    </MenuItem>
                    <MenuItem value={`Master's Degree`}>
                      Master's Degree
                    </MenuItem>
                    <MenuItem value={"Doctorate Degree"}>
                      Doctorate Degree
                    </MenuItem>
                    <MenuItem value={"Bootcamp"}>Bootcamp</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                  {errorForDegree && (
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      Oops! It looks like you forgot to select an option for
                      this field.
                    </FormHelperText>
                  )}
                </FormControl>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <TextField
                    autoComplete="off"
                    sx={{
                      width: "272px",
                      borderRadius: "20px",
                      color: "#212121",
                      "& label.Mui-focused": {
                        color: "#212121",
                        fontSize: "0,875rem",
                      },
                      "& .MuiInputBase-input": {
                        padding: minYearsOfExp
                          ? "25px 12px 8px 12px"
                          : "15.5px",
                        fontSize: "1rem",
                      },

                      "& .MuiInputBase-input.MuiFilledInput-input:focus": {
                        border:
                          minYearsErrorMessage.length > 0
                            ? "1px solid transparent "
                            : "1px solid #AD00FF",
                      },
                    }}
                    id="min-year-of-experience-input"
                    label={
                      !minYearsOfExp ? "" : "Required Min Years of Experience"
                    }
                    required
                    placeholder="Required Min Years of Experience*"
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                      sx:
                        minYearsErrorMessage.length > 0
                          ? {
                              border: " 1px solid red",
                            }
                          : {},
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleMinYearExpChange}
                    onBlur={handleMinYearExpChange}
                    value={activeRoleRequest ? minYearsOfExp : null}
                    error={minYearsErrorMessage.length > 0}
                    helperText={
                      minYearsErrorMessage.length > 0
                        ? minYearsErrorMessage
                        : ""
                    }
                  />{" "}
                  <TextField
                    autoComplete="off"
                    sx={{
                      width: "272px",
                      borderRadius: "20px",
                      color: "#212121",
                      "& label.Mui-focused": {
                        color: "#212121",
                        fontSize: "0,875rem",
                      },
                      "& .MuiInputBase-input": {
                        padding: maxYearsOfExp
                          ? "25px 12px 8px 12px"
                          : "15.5px",
                        fontSize: "1rem",
                      },

                      "& .MuiInputBase-input.MuiFilledInput-input:focus": {
                        border:
                          maxYearsErrorMessage.length > 0
                            ? "1px solid transparent "
                            : "1px solid #AD00FF",
                      },
                    }}
                    id="max-year-of-experience-input"
                    label={
                      !maxYearsOfExp ? "" : "Required Max Years of Experience"
                    }
                    required
                    placeholder={`Required Max Years of Experience*`}
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                      sx:
                        maxYearsErrorMessage.length > 0
                          ? {
                              border: "1px solid red",
                            }
                          : {},
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={activeRoleRequest ? maxYearsOfExp : null}
                    onChange={handleMaxYearExpChange}
                    onBlur={handleMaxYearExpChange}
                    error={maxYearsErrorMessage.length > 0}
                    helperText={
                      maxYearsErrorMessage.length > 0
                        ? maxYearsErrorMessage
                        : ""
                    }
                  />
                </Box>
                {skills.map((item, index) => {
                  return (
                    <TextField
                      key={item.type + index}
                      autoComplete="off"
                      sx={{
                        maxWidth: "322px",
                        borderRadius: "20px",
                        color: "#212121",
                        "& label.Mui-focused": {
                          color: "#212121",
                          fontSize: "0,875rem",
                        },
                        "& .MuiInputBase-input": {
                          padding: item.value ? "25px 12px 8px 12px" : "16.5px",
                          fontSize: "1rem",
                        },
                      }}
                      value={item.value}
                      id={item.type + index}
                      label={!item.value ? "" : "Required Skills"}
                      placeholder={`Required Skills`}
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event) => handleSkillChange(index, event)}
                    />
                  );
                })}
                <Button
                  sx={{
                    color: theme.palette.info.dark,
                    backgroundColor: "#EFCCFF",
                    fontWeight: 500,
                    boxShadow: "none",
                    width: "90px",
                    lineHeight: "18px",
                    height: "38px",
                    "&:hover": {
                      backgroundColor: "#E6B3FF",
                      boxShadow: "none",
                    },
                  }}
                  onClick={addNewSkill}
                >
                  {" "}
                  Add Skill
                </Button>
              </Box>

              <Box sx={{ margin: "16px 0px", minHeight: "61px" }}>
                <Typography
                  id="Job Description-label"
                  fontSize="1.25rem"
                  fontWeight={500}
                  sx={{ paddingLeft: "5px" }}
                  color="#181818"
                >
                  Job Description
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <ResizableTextarea
                    label=""
                    onChange={handleNotesChange}
                    error={errorForNotes}
                    rowsNumber={2}
                    placeholder="Job Description*"
                    value={description}
                  ></ResizableTextarea>
                  {errorForNotes && (
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      Please enter a job description
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Box>
            {isOverviewTab && (
              <>
                <Box
                  sx={{
                    backgroundColor: "transparent",
                    zIndex: "5",
                    position: "sticky",
                    bottom: 0,
                  }}
                >
                  <Divider
                    sx={{
                      color: "D9D9D9",
                      marginX: "-16px",
                      marginTop: "16px",
                    }}
                  ></Divider>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "20px 16px 0 0",
                    }}
                  >
                    <Button
                      sx={{
                        borderRadius: "10px",
                        color: "#181818",
                        border: "1px solid #ABABAB",
                        padding: "8px 16px",
                        maxHeight: "40px",
                        maxWidth: "82px",
                        marginRight: "16px",
                      }}
                      variant="text"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        color: "#fff",
                        borderRadius: "10px",
                        padding: "8px 16px",
                        maxHeight: "40px",
                        maxWidth: "136px",
                        "&:hover": {
                          backgroundColor: "#CE66FF",
                          boxShadow: "none",
                        },
                      }}
                      onClick={() => {
                        handleUpdateJobDescription(dataForRequest);
                        handleCancel();
                      }}
                    >
                      Save changes
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
      {!isSearchCriteriaTab && (
        <Box>
          <Divider
            sx={{
              color: "#D9D9D9",
            }}
          ></Divider>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexwrap: "nowrap",
              gap: "19px",
              marginRight: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                paddingTop: 2,
              }}
            >
              {" "}
              {activeStep === AddNewRoleStep.UploadDocument && (
                <>
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => {
                      handleCancel();
                    }}
                    sx={{
                      borderRadius: "10px",
                      color: "#181818",
                      border: "1px solid #ABABAB",
                      padding: "8px 16px",
                      maxHeight: "40px",
                      maxWidth: "82px",
                      marginRight: "16px",
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={
                      fileLenght === 0 || jobDescriptionUrlInput.length === 0
                    }
                    onClick={openUploadConfirmationModal}
                    sx={{
                      color: "#fff",
                      borderRadius: "10px",
                      padding: "8px 16px",
                      maxHeight: "40px",
                      maxWidth: "136px",
                      "&:hover": {
                        backgroundColor: "#CE66FF",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Continue
                  </Button>
                </>
              )}
              {activeStep === AddNewRoleStep.ReviewDataAndConfirm && (
                <>
                  <Button
                    onClick={() => {
                      handleCancel();
                    }}
                    sx={{
                      borderRadius: "10px",
                      color: "#181818",
                      border: "1px solid #ABABAB",
                      padding: "8px 16px",
                      maxHeight: "40px",
                      maxWidth: "82px",
                      marginRight: "16px",
                    }}
                    variant="text"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      handleContinue(AddNewRoleStep.ReviewDataAndConfirm);
                    }}
                    disabled={!isButtonDisabled}
                    variant="contained"
                    color="secondary"
                    sx={{
                      color: "#fff",
                      borderRadius: "10px",
                      padding: "8px 16px",
                      maxHeight: "40px",
                      maxWidth: "136px",
                      "&:hover": {
                        backgroundColor: "#CE66FF",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Save Changes
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

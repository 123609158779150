import React, { useEffect, useState } from "react"; // eslint-disable-line no-unused-vars
import {
  Grid,
  Button,
  Typography,
  Breadcrumbs,
  Chip,
  Box,
  Stack,
} from "@mui/material";
import { Candidate, FilterCandidates } from "../../types";
import { Results } from "..";
import { Status } from "../../enums/candidate-status";
import { SingleTab, AciveRoles, ReviewedCandidates } from "../../types/tab";

import { useTheme } from "@mui/material/styles";
import { ForwardIcon } from "../../utilities/icons/index";

import { NavItems } from "../../enums/nav-items";
import {
  IdealCandidateProfiles,
  RoleActiveStatus,
  RoleModalRequest,
  SearchCriteria,
  Stages,
} from "../../utilities/roles";
import { RoleStatus } from "../../enums/addRole";
import {
  getIdealCandidateProfiles,
  getSearchCriteriaData,
} from "../../utilities/actions";
import noInactiveRoles from "../../assets/images/Empty-amico 1.png";
import noActiveRoles from "../../assets/images/noActiveRoles.png";
import { AddIcon } from "../../utilities/icons/addIcon";
export interface DashboardProps {
  candidates: Candidate[];
  isAdmin: boolean;
  busy: boolean;
  filter: string;
  breadCrumbsLink: string[];
  searchCriteriaResults?: SearchCriteria;
  idealCandidatesResults?: IdealCandidateProfiles;
  isDevAccount: boolean;
  projectsData: RoleModalRequest[];
  roleData?: RoleModalRequest;
  isConfirmationPopupOpen: boolean;
  isRejectModalOpen: boolean;
  handleOpenCandidateNotes: (
    candidateId: string,
    candidateData: Candidate,
    action: string
  ) => void;
  handleGetCandidates: (projectId?: string) => void;
  handleImpersonate: (impersonationId: string) => void;
  handleAcceptAll: (
    filter: FilterCandidates,
    selectedTab: ReviewedCandidates
  ) => void;
  handleButtonGroupChoose: (label: string, key: string) => void;
  handleDisqualifyUserFromModal: (statusComentRequest: string) => void;
  handleUpdateChangesDisqualifyModal: (statusComentRequest: string) => void;
  handleRouteChange: (route: NavItems) => void;
  handleUpdateJobDescriptionData: (data: RoleModalRequest) => void;
  updateSearchParameters: (data: SearchCriteria, projectId?: string) => void;
  saveIdealCandidateProfiles: (
    data: IdealCandidateProfiles,
    projectId?: string
  ) => void;
  handleResetProgres: () => void;
  setCurrentRoute: (route: string) => void;
}

export const Dashboard = ({
  candidates,
  isAdmin,
  busy,
  filter,
  breadCrumbsLink,
  searchCriteriaResults,
  idealCandidatesResults,
  isDevAccount,
  projectsData,
  roleData,
  isConfirmationPopupOpen,
  isRejectModalOpen,
  handleGetCandidates,
  handleOpenCandidateNotes,
  handleImpersonate,
  handleAcceptAll,
  handleRouteChange,
  handleUpdateJobDescriptionData,
  saveIdealCandidateProfiles,
  updateSearchParameters,
  handleButtonGroupChoose,
  handleDisqualifyUserFromModal,
  handleUpdateChangesDisqualifyModal,
  handleResetProgres,
  setCurrentRoute,
}: DashboardProps): JSX.Element => {
  const theme = useTheme();
  const [impersonationId, setImpersonationId] = useState<string>("");
  const [selectedTab, setSelectedTab] = React.useState<AciveRoles>(
    AciveRoles.Active
  );
  const [projectsDataRequest, setProjectsDataRequest] = useState<
    RoleModalRequest[]
  >([]);

  const [activeSearchCriteria, setActiveSearchCriteria] =
    useState<SearchCriteria>({} as SearchCriteria);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria[]>([]);
  const [activeIdealPorfile, setActiveIdealPorfile] =
    useState<IdealCandidateProfiles>({} as IdealCandidateProfiles);
  const [idealCandidateProfiles, setIdealCandidateProfiles] = useState<
    IdealCandidateProfiles[]
  >([]);
  const [breadCrubsLinks, setBreadCrubsLink] =
    useState<string[]>(breadCrumbsLink);
  const [activeRoleRequest, setActiveRoleRequest] = useState<RoleModalRequest>(
    {} as RoleModalRequest
  );
  const [inactiveRoleData, setInactiveRoleData] = useState<RoleModalRequest[]>(
    []
  );
  const [activeRoleData, setActiveRoleData] = useState<RoleModalRequest[]>([]);
  const tabs: SingleTab[] = [
    {
      value: AciveRoles.Active,
      active: true,
    },
    {
      value: AciveRoles.Inactive,
      active: false,
    },
  ];

  const handleTabsChange = (selectedTab: SingleTab) => {
    tabs.map((tab: SingleTab) => {
      if (tab.value === selectedTab.value) {
        return (tab.active = true);
      } else {
        return (tab.active = false);
      }
    });

    setSelectedTab(selectedTab.value);
  };

  const onNavigateToCandidates = (role: RoleModalRequest) => {
    setBreadCrubsLink(["Dashboard", role.title]);
    setActiveRoleRequest(role);
    getSearchCriteria(role?.projectId);
    getIdealProfiles(role.projectId);
  };

  const getSearchCriteria = (id: string | undefined) => {
    const getData = async (): Promise<void> => {
      let results = await getSearchCriteriaData(id);
      setActiveSearchCriteria(results[results.length - 1]);
      setSearchCriteria(results);
    };
    getData();
  };

  const getIdealProfiles = (id: string | undefined) => {
    const getData = async (): Promise<void> => {
      let results = await getIdealCandidateProfiles(id);
      setActiveIdealPorfile(results[results.length - 1]);
      setIdealCandidateProfiles(results);
    };
    getData();
  };

  useEffect(() => {
    if (
      (searchCriteriaResults || idealCandidatesResults) &&
      activeRoleRequest.projectId !== undefined
    ) {
      setTimeout(() => {
        getSearchCriteria(activeRoleRequest.projectId);
        getIdealProfiles(activeRoleRequest.projectId);
      }, 500);
    }
  }, [searchCriteriaResults, idealCandidatesResults]);

  const handleBreadCrumbChange = (link: string) => {
    setBreadCrubsLink(breadCrubsLinks);
    if (breadCrubsLinks.indexOf(link) === 0) {
      setBreadCrubsLink([]);
    } else {
      if (breadCrubsLinks.indexOf(link) !== breadCrubsLinks.length - 1) {
        const changeBreadCrumbs = breadCrubsLinks.splice(
          0,
          breadCrubsLinks.length - 1
        );
        setBreadCrubsLink(changeBreadCrumbs);
      }
    }
    setCurrentRoute(link);
  };

  useEffect(() => {
    setProjectsDataRequest(projectsData);

    const inactiveRolesData = projectsData.filter(
      (project: RoleModalRequest): boolean => {
        return project.status === RoleStatus.Inactive;
      }
    );
    const activeRoleData = projectsData.filter(
      (project: RoleModalRequest): boolean => {
        return (
          project.status === RoleStatus.Active ||
          project.status === RoleStatus.Processing ||
          project.status === RoleStatus.Pending
        );
      }
    );
    setInactiveRoleData(inactiveRolesData);
    setActiveRoleData(activeRoleData);
  }, [projectsData]);

  useEffect(() => {
    if (roleData) {
      setBreadCrubsLink(["Dashboard", roleData.title]);
    }
  }, [roleData]);

  useEffect(() => {
    setBreadCrubsLink(breadCrumbsLink);
  }, [breadCrumbsLink]);

  const onButtonSelect = (data: string, label: string) =>
    console.log(data, label);

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 668,
    bgcolor: "background.paper",
    borderRadius: 2,
    outline: "none",
    p: 2,

    overflow: "hidden",
  };

  const handleUpload = (file: File) => {
    console.log(file);
  };

  const handleUploadClick = (id: string) => {
    const fileInput = document.getElementById(id);
    fileInput?.click();
  };

  return (
    <Box
      sx={{
        paddingTop: "30px",
        height: "calc(100vh - 40px)",
        overflowY: "auto",
      }}
    >
      {breadCrubsLinks.length === 2 && (
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container>
            <Grid
              container
              sx={{
                paddingTop: "20px",
              }}
            >
              <Grid
                item
                sx={{
                  backgroundColor: "#fff",
                  width: "100%",
                }}
              >
                <Grid
                  sx={{
                    paddingY: "8px",
                    marginX: "auto",
                    maxWidth: "1696px",
                    padding: {
                      xl: "8px 16px 8px 36px",
                      lg: " 8px 16px 8px 40px ",
                      md: " 8px 16px 8px 40px",
                      sm: " 8px 16px 8px 40px",
                      xs: " 8px 16px 8px 40px",
                    },
                  }}
                >
                  <Breadcrumbs aria-label="breadcrumb" separator="/">
                    {breadCrubsLinks.map((linkName: string) => {
                      return (
                        <Typography
                          variant="h5"
                          key={linkName}
                          color={
                            breadCrubsLinks.indexOf(linkName) ===
                            breadCrubsLinks.length - 1
                              ? theme.palette.text.primary
                              : theme.palette.text.secondary
                          }
                          fontWeight={
                            breadCrubsLinks.indexOf(linkName) ===
                            breadCrubsLinks.length - 1
                              ? 500
                              : 400
                          }
                          fontSize="14px"
                          onClick={() => {
                            handleBreadCrumbChange(linkName);
                          }}
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          {linkName}
                        </Typography>
                      );
                    })}
                  </Breadcrumbs>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Results
                  activeSearchCriteria={activeSearchCriteria}
                  searchCriteria={searchCriteria}
                  activeIdealPorfile={activeIdealPorfile}
                  idealCandidateProfiles={idealCandidateProfiles}
                  activeRoleRequest={activeRoleRequest}
                  isDevAccount={isDevAccount}
                  isConfirmationPopupOpen={isConfirmationPopupOpen}
                  selectedRoutes={breadCrubsLinks}
                  filter={filter}
                  candidates={candidates}
                  isRejectModalOpen={isRejectModalOpen}
                  updateSearchParameters={updateSearchParameters}
                  saveIdealCandidateProfiles={saveIdealCandidateProfiles}
                  handleUpdateJobDescriptionData={
                    handleUpdateJobDescriptionData
                  }
                  handleOpenReviewModal={(
                    candidateId: string,
                    candidateData: Candidate
                  ) => {
                    handleOpenCandidateNotes(
                      candidateId,
                      candidateData,
                      "review"
                    );
                  }}
                  handleAccept={(
                    candidateId: string,
                    candidateData: Candidate
                  ) => {
                    handleOpenCandidateNotes(
                      candidateId,
                      candidateData,
                      Status.Accepted
                    );
                  }}
                  handleReject={(
                    candidateId: string,
                    candidateData: Candidate
                  ) => {
                    handleOpenCandidateNotes(
                      candidateId,
                      candidateData,
                      Status.Rejected
                    );
                  }}
                  handleAcceptAllUsers={handleAcceptAll}
                  handleButtonGroupChoose={handleButtonGroupChoose}
                  handleDisqualifyUserFromModal={handleDisqualifyUserFromModal}
                  handleUpdateChangesDisqualifyModal={
                    handleUpdateChangesDisqualifyModal
                  }
                  handleResetProgres={handleResetProgres}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {breadCrubsLinks.length === 0 && (
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid item>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#fff",
                paddingTop: "16px",
                borderBottom: "1px solid #D9D9F5",
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "1696px",
                  marginX: "auto",
                  paddingTop: "16px",
                  gap: "16px",
                  paddingX: "20px",
                }}
              >
                <Grid
                  container
                  display="flex"
                  sx={{
                    flexDirection: "row",
                    gap: "16px",
                  }}
                >
                  <Typography variant="h5">Candidate Dashboard</Typography>
                  {isDevAccount && (
                    <Button
                      color="secondary"
                      sx={{
                        marginLeft: { sm: "auto" },
                        borderRadius: "8px",
                        maxWidth: "300px",
                        color: "#fff",
                        boxShadow: "none",
                        backgroundColor: theme.palette.secondary.main,
                        transition: "none",
                        "&:hover": {
                          backgroundColor: "#CE66FF",
                          boxShadow: "none",
                        },
                      }}
                      variant="contained"
                      onClick={() => {
                        handleRouteChange(NavItems.AddNewRole);
                      }}
                    >
                      <AddIcon />{" "}
                      <span style={{ marginLeft: "8px", marginTop: "1px" }}>Add More Roles </span>
                    </Button>
                  )}
                </Grid>

                <Grid item>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "16px",
                      paddingTop: "11px",
                      alignItems: "center",
                    }}
                  >
                    {tabs.map((singleTab: SingleTab) => (
                      <Typography
                        onClick={() => handleTabsChange(singleTab)}
                        key={singleTab.value}
                        sx={{
                          cursor: "pointer",
                          textAlign: "center",
                          color:
                            singleTab.value === selectedTab
                              ? theme.palette.secondary.main
                              : theme.palette.secondary.contrastText,
                          borderBottom:
                            singleTab.value === selectedTab
                              ? "3px solid #AD00FF"
                              : "",
                          paddingBottom: "8px",
                          fontFamily: "Arial",
                          fontWeight:
                            singleTab.value === selectedTab ? 700 : 500,
                          minWidth: "140px",
                          "&:hover": {
                            color:
                              singleTab.value === selectedTab
                                ? "#8A00CC"
                                : "#585858",
                          },
                        }}
                        variant="body2"
                      >
                        <span
                          style={{
                            cursor: "pointer",
                            borderRadius: "15px 15px",
                            backgroundColor:
                              singleTab.value === selectedTab
                                ? "#EED9F8"
                                : "#E6E6E6",
                            color:
                              singleTab.value === selectedTab
                                ? "#AD00FF"
                                : "#8F8F8F",
                            padding: "2px 9px",
                            marginRight: "8px",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          {" "}
                          {singleTab.value === AciveRoles.Active
                            ? projectsData.filter(
                                (data: RoleModalRequest) =>
                                  data.status === RoleStatus.Active ||
                                  data.status === RoleStatus.Pending ||
                                  data.status === RoleStatus.Processing
                              ).length
                            : projectsData.filter(
                                (data: RoleModalRequest) =>
                                  data.status === RoleStatus.Inactive
                              ).length}
                        </span>{" "}
                        {singleTab.value}
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>{" "}
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                  md: "column",
                  lg: "column",
                },

                maxWidth: "1696px",
                marginX: "auto",
                gap: "16px",
                marginTop: "16px",
                paddingX: "20px",
              }}
            >
              {selectedTab === AciveRoles.Active &&
                activeRoleData.length > 0 && (
                  <Grid item xs={12} md={12} sx={{ maxHeight: { lg: "16px" } }}>
                    <Grid container display="flex">
                      <Grid item md={3} sm={12} xs={12}>
                        <Typography color={theme.palette.text.secondary}>
                          Role Name
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={3}
                        sm={12}
                        xs={12}
                        sx={{
                          paddingLeft: {
                            xs: 0,
                            sm: 0,
                            md: "20px",
                            lg: "20px",
                          },
                        }}
                      >
                        <Typography color={theme.palette.text.secondary}>
                          {" "}
                          Status
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={3}
                        sm={12}
                        xs={12}
                        color={theme.palette.text.secondary}
                      >
                        <Typography>Stage</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              {selectedTab === AciveRoles.Inactive &&
                inactiveRoleData.length > 0 && (
                  <Grid
                    item
                    sm={6}
                    xs={12}
                    md={6}
                    lg={12}
                    sx={{ maxHeight: { lg: "16px" } }}
                  >
                    <Grid container display="flex">
                      <Grid item md={3} sm={12} xs={12}>
                        <Typography color={theme.palette.text.secondary}>
                          Role Name
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={3}
                        sm={12}
                        xs={12}
                        sx={{
                          paddingLeft: {
                            xs: 0,
                            sm: 0,
                            md: "20px",
                            lg: "20px",
                          },
                        }}
                      >
                        <Typography color={theme.palette.text.secondary}>
                          {" "}
                          Status
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

              <Grid item>
                <Grid
                  width="100%"
                  container
                  gap={2}
                  flexWrap="nowrap"
                  flexDirection="column"
                >
                  {" "}
                  {selectedTab === AciveRoles.Active ? (
                    activeRoleData.length > 0 ? (
                      activeRoleData.map(
                        (project: RoleModalRequest, index: number) => {
                          return (
                            <Grid
                              key={index}
                              item
                              sx={{
                                cursor:
                                  project.status === RoleStatus.Active ||
                                  project.status === RoleStatus.Processing
                                    ? "pointer"
                                    : "default",
                              }}
                              onClick={() => {
                                if (
                                  project.status === RoleActiveStatus.Active ||
                                  project.status === RoleStatus.Processing ||
                                  project.status === ""
                                ) {
                                  onNavigateToCandidates(project);
                                  handleGetCandidates(project.projectId);
                                }
                              }}
                            >
                              <Grid
                                container
                                width="100%"
                                display="flex"
                                sx={{
                                  backgroundColor: "#fff",
                                  borderRadius: "20px",
                                  padding: "24px",

                                  gap: {
                                    sm: "16px",
                                    xs: "16px",
                                    md: 0,
                                    lg: 0,
                                  },

                                  "&:hover": {
                                    backgroundColor:
                                      project.status === RoleStatus.Active ||
                                      project.status ===
                                        RoleStatus.Processing ||
                                      project.status === ""
                                        ? "#F6F6F6"
                                        : "#fff",
                                    boxShadow:
                                      project.status === RoleStatus.Active ||
                                      project.status ===
                                        RoleStatus.Processing ||
                                      project.status === ""
                                        ? "0px 32px 48px -20px rgba(100, 112, 122, 0.15)"
                                        : "none",
                                  },
                                }}
                              >
                                <Grid item md={3} sm={12} xs={12}>
                                  <Typography variant="h5">
                                    {project.title}
                                  </Typography>
                                </Grid>
                                <Grid item md={3} sm={12} xs={12}>
                                  <Chip
                                    label={
                                      project.status === RoleStatus.Processing
                                        ? "Processing Job Description"
                                        : project.status
                                    }
                                    sx={{
                                      marginLeft: {
                                        xs: 0,
                                        sm: 0,
                                        md: "20px",
                                        lg: 0,
                                      },
                                      fontSize: "0.875rem",
                                      fontWeight: 500,
                                      color:
                                        project.status === RoleStatus.Active
                                          ? "#366543"
                                          : "#023041",

                                      backgroundColor:
                                        project.status === RoleStatus.Active
                                          ? theme.palette.success.light
                                          : "#EAF6FB",

                                      borderRadius: "10px",
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  sm={12}
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexWrap: {
                                      xs: "wrap",
                                      sm: "wrap",
                                      md: "wrap",
                                      lg: "wrap",
                                      xl: "nowrap",
                                    },
                                  }}
                                >
                                  {project.stages &&
                                    project.stages.map((stage: Stages) => (
                                      <Chip
                                        key={stage.id}
                                        label={stage.title}
                                        sx={{
                                          marginLeft: {
                                            xs: 0,
                                            sm: 0,
                                            md: "20px",
                                            lg: 0,
                                          },
                                          marginRight: "25px",
                                          fontSize: "0.875rem",
                                          fontWeight: 500,
                                          color:
                                            project.status === RoleStatus.Active
                                              ? "theme.palette.success.dark"
                                              : "#023041",

                                          backgroundColor: stage.completed
                                            ? "#EAF6FB"
                                            : "transparent",
                                          borderRadius: "10px",
                                        }}
                                      />
                                    ))}
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  sm={12}
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifySelf: "flex-end",

                                      color:
                                        project.status === RoleStatus.Active ||
                                        project.status ===
                                          RoleStatus.Processing ||
                                        project.status === ""
                                          ? theme.palette.text.primary
                                          : "#ABABAB",
                                    }}
                                    variant="h6"
                                  >
                                    View{" "}
                                    <ForwardIcon
                                      sx={{
                                        color:
                                          project.status ===
                                            RoleStatus.Active ||
                                          project.status ===
                                            RoleStatus.Processing ||
                                          project.status === ""
                                            ? theme.palette.secondary.main
                                            : "#ABABAB",
                                      }}
                                    />
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        }
                      )
                    ) : (
                      <>
                        <Stack
                          sx={{
                            gap: 1,
                            display: "flex",
                            flexDirection: "column",
                            marginX: "auto",
                            maxWidth: "1696px",
                            paddingX: "24px",
                            alignItems: "center",
                            paddingBottom: "24px",
                          }}
                        >
                          <Box
                            sx={{
                              maxWidth: "186px",
                              maxHeight: "155px",
                              marginTop: "111px",
                              marginBottom: "40px",
                            }}
                          >
                            <img
                              src={noActiveRoles}
                              alt="No reviewed candidates placeholder"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </Box>
                          <Typography variant="h5">
                            You have no active roles.
                          </Typography>
                          <Typography variant="body1" fontSize="14px">
                            No positions to fill? Channel that energy into
                            shaping tomorrow.
                          </Typography>
                          {isDevAccount && (
                            <Button
                              color="secondary"
                              sx={{
                                borderRadius: "8px",
                                maxWidth: "300px",
                                color: "#fff",
                                boxShadow: "none",
                                backgroundColor: theme.palette.secondary.main,
                                transition: "none",
                                "&:hover": {
                                  backgroundColor: "#CE66FF",
                                  boxShadow: "none",
                                },
                              }}
                              variant="contained"
                              onClick={() => {
                                handleRouteChange(NavItems.AddNewRole);
                              }}
                            >
                              <AddIcon />{" "}
                              <span style={{ marginLeft: "8px" }}>
                                Add More Roles{" "}
                              </span>
                            </Button>
                          )}
                        </Stack>
                      </>
                    )
                  ) : selectedTab === AciveRoles.Inactive ? (
                    inactiveRoleData.length > 0 ? (
                      inactiveRoleData.map(
                        (project: RoleModalRequest, index: number) => {
                          return (
                            <Grid
                              key={index}
                              item
                              sm={6}
                              xs={12}
                              md={6}
                              lg={12}
                              sx={{
                                maxHeight: { md: "74px" },
                              }}
                            >
                              <Grid
                                container
                                width="100%"
                                display="flex"
                                sx={{
                                  backgroundColor: "#fff",
                                  borderRadius: "20px",
                                  padding: "24px",
                                  gap: {
                                    sm: "16px",
                                    xs: "16px",
                                    md: 0,
                                    lg: 0,
                                  },

                                  "&:hover": {
                                    backgroundColor:
                                      project.status === RoleActiveStatus.Active
                                        ? "#F6F6F6"
                                        : "#fff",
                                    boxShadow:
                                      project.status === RoleActiveStatus.Active
                                        ? "0px 32px 48px -20px rgba(100, 112, 122, 0.15)"
                                        : "none",
                                  },
                                }}
                              >
                                <Grid item md={3} sm={12} xs={12}>
                                  <Typography variant="h5">
                                    {project.title}
                                  </Typography>
                                </Grid>
                                <Grid item md={3} sm={12} xs={12}>
                                  <Chip
                                    label={project.status}
                                    sx={{
                                      marginLeft: {
                                        xs: 0,
                                        sm: 0,
                                        md: "20px",
                                        lg: 0,
                                      },
                                      color:
                                        project.status ===
                                        RoleActiveStatus.Active
                                          ? theme.palette.success.dark
                                          : "#5AB5D6",

                                      backgroundColor:
                                        project.status ===
                                        RoleActiveStatus.Active
                                          ? theme.palette.success.light
                                          : "#C6EFFD",

                                      borderRadius: "10px",
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  sm={12}
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifySelf: "flex-end",

                                      color:
                                        project.status ===
                                        RoleActiveStatus.Active
                                          ? theme.palette.text.primary
                                          : "#ABABAB",
                                    }}
                                    variant="h6"
                                  >
                                    View{" "}
                                    <ForwardIcon
                                      sx={{
                                        color:
                                          project.status ===
                                          RoleActiveStatus.Active
                                            ? theme.palette.secondary.main
                                            : "#ABABAB",
                                      }}
                                    />
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        }
                      )
                    ) : (
                      <Stack
                        sx={{
                          gap: 1,
                          display: "flex",
                          flexDirection: "column",
                          marginX: "auto",
                          maxWidth: "1696px",
                          paddingX: "24px",
                          alignItems: "center",
                          paddingBottom: "24px",
                        }}
                      >
                        <Box
                          sx={{
                            maxWidth: "186px",
                            maxHeight: "155px",
                            marginTop: "111px",
                            marginBottom: "40px",
                          }}
                        >
                          <img
                            src={noInactiveRoles}
                            alt="No active roles placeholder"
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Box>
                        <Typography variant="h5">
                          You have no inactive roles.
                        </Typography>
                        <Typography variant="body1" fontSize="14px">
                          When an active role becomes inactive, you'll see it
                          here.
                        </Typography>
                        {isDevAccount && (
                          <Button
                            color="secondary"
                            sx={{
                              borderRadius: "8px",
                              maxWidth: "300px",
                              color: "#fff",
                              boxShadow: "none",
                              backgroundColor: theme.palette.secondary.main,
                              transition: "none",
                              "&:hover": {
                                backgroundColor: "#CE66FF",
                                boxShadow: "none",
                              },
                            }}
                            variant="contained"
                            onClick={() => {
                              handleRouteChange(NavItems.AddNewRole);
                            }}
                          >
                            <AddIcon />{" "}
                            <span style={{ marginLeft: "8px" }}>
                              Add More Roles{" "}
                            </span>
                          </Button>
                        )}
                      </Stack>
                    )
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

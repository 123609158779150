import React, { useState } from "react";

export interface TextAreaProps {
  onChange: (e: any) => void;
  label: string;
  isInsideLabel?: boolean;
  error?: boolean;
  placeholder?: string;
  rowsNumber?: number;
  value?: string;
  isSearchPropertiesPage?: boolean;
  isCandidateCard?: boolean;
}

const customStyles = {
  border: {
    border: "1px solid #AD00FF",
  },
  text: {
    fontSize: "16px",
    color: "blue",
  },
};
export const ResizableTextarea = ({
  onChange,
  label,
  isInsideLabel,
  error,
  placeholder,
  rowsNumber,
  value,
  isCandidateCard,
  isSearchPropertiesPage,
}: TextAreaProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  return (
    <div
      style={{
        position: "relative",
        padding: !isCandidateCard ? "5px" : "unset",
        paddingBottom: isCandidateCard ? "16px" : "5px",
      }}
    >
      {isSearchPropertiesPage ? (
        <div
          style={{
            fontSize: "0.875rem",
            color: "#FFF",
            marginBottom: "8px",
            borderRadius: "10px",
            padding: "0 6px",
            width: "fit-content",
            backgroundColor:
              label === "Notes"
                ? "#ABABAB"
                : label === "Qualified"
                ? "#6AA171"
                : label === "Needs Review"
                ? "#DE901A"
                : "initial",
          }}
        >
          {label}
        </div>
      ) : (
        <div
          style={{
            fontSize: "16px",
            marginBottom: !isCandidateCard ? "8px" : "unset",
          }}
          // className={isInsideLabel ? classes.label : ""}
        >
          {label}
        </div>
      )}
      <textarea
        style={{
          width: "100%",
          border:
            isFocused && !error ? "1px solid #AD00FF" : `1px solid transparent`,
          borderRadius: "8px",
          resize: "vertical",
          backgroundColor: "#E6E6E6",
          padding: isInsideLabel ? "28px 12px 8px" : "8px 0 8px 8px",
          outline: error ? "1px solid red" : "none",
          color: "#7A7A7A",
          fontSize: "1rem",
          height: !isCandidateCard ? "unset" : "88px",
        }}
        value={value ? value : ""}
        aria-label={label}
        onChange={onChange}
        rows={rowsNumber ? rowsNumber : 5}
        onBlur={() => {
          onChange;
          setIsFocused(false);
        }}
        onFocus={handleFocus}
        placeholder={placeholder}
      />
    </div>
  );
};

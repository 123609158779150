import React, { useEffect, useState } from "react";
import { RoleModalRequest, Stages, SubStages } from "../../utilities/roles";
import { OverviewMenu } from "../../enums/nav-items";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { ReviewedCandidates } from "../../types/tab";
import { CalendarIcon } from "../../utilities/icons";
import { DateField } from "@mui/x-date-pickers/DateField";
import { getTimeline, submitTimeline } from "../../utilities/actions";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
export interface TimelineProps {
  activeRoleRequest: RoleModalRequest;
  activeItem?: OverviewMenu;
  onNavigate: (data: OverviewMenu | ReviewedCandidates) => void;
}

export const Timeline = ({
  activeRoleRequest,
  activeItem,
  onNavigate,
}: TimelineProps) => {
  const [activeOverviewItem, setActiveOverviewItem] = useState<OverviewMenu>();
  const [stages, setStages] = useState<Stages[]>(
    activeRoleRequest.stages ? activeRoleRequest.stages : []
  );
  const [creationDate, setCreationDate] = useState<Dayjs>();
  const [progress, setProgress] = useState<number>(0);
  const [progressStepTwo, setProgressStepTwo] = useState<number>(0);
  const [progressStepThree, setProgressStepThree] = useState<number>(0);
  const [progressStepFour, setProgressStepFour] = useState<number>(0);
  const [checkboxStates, setCheckboxStates] = useState<boolean[][]>([]);
  const [hoveredLabel, setHoveredLabel] = useState<{
    step: number | null;
    labelIndex: number | null;
  } | null>(null);

  useEffect(() => {
    setActiveOverviewItem(activeItem);

    const initialState = stages.map((step: Stages) =>
      step.subStages.map((stage: Stages) => {
        const subStages = stages.map((stage: Stages) => stage.subStages);
        const completedStatus =
          subStages
            .flat()
            .find((subStage: SubStages) => subStage.title === stage.title)
            ?.completed || false;

        return completedStatus;
      })
    );

    initialState.map((value: boolean[], stepIndex: number) =>
      value.map((val: boolean, labelIndex: number) => {
        const counter = value.filter((v: boolean, index: number) => {
          if (stepIndex === 0 && labelIndex < 2) {
            return v && index !== value.length - 1;
          } else if (stepIndex === 1 && labelIndex < 4) {
            return v && index !== value.length - 1;
          } else if (stepIndex === 2 && labelIndex < 2) {
            return v && index !== 1;
          } else if (stepIndex === 3 && labelIndex < 1) {
            return v;
          }
          return false;
        }).length;

        if (stepIndex === 0 && labelIndex < 2 && val === true) {
          setProgress((counter / 2) * 100);
        } else if (stepIndex === 1 && labelIndex < 4 && val === true) {
          setProgressStepTwo((counter / 4) * 100);
        } else if (stepIndex === 2 && labelIndex < 2 && val === true) {
          setProgressStepThree((counter / 2) * 100);
        } else if (stepIndex === 3 && labelIndex < 2 && val === true) {
          setProgressStepFour((counter / 1) * 100);
        }
      })
    );
    setCheckboxStates(initialState);
  }, [activeItem, activeRoleRequest]);

  const checkBoxChange = async (
    step: Stages,
    subStage: SubStages,
    stepIndex: number,
    labelIndex: number,
    event: any
  ) => {
    setCheckboxStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[stepIndex][labelIndex] = !newStates[stepIndex][labelIndex];
      if (stepIndex === 0 && labelIndex < 2) {
        const checkedCount = newStates[stepIndex].filter(
          (isChecked, index, array) => {
            if (index === array.length - 1 && isChecked) {
              return false;
            }
            return isChecked;
          }
        ).length;
        setProgress((checkedCount / 2) * 100);
      }

      if (stepIndex === 1 && labelIndex < 4) {
        const checkedCount = newStates[stepIndex].filter(
          (isChecked, index, array) => {
            if (index === array.length - 1 && isChecked) {
              return false;
            }
            return isChecked;
          }
        ).length;
        setProgressStepTwo((checkedCount / 4) * 100);
      }
      if (stepIndex === 2 && labelIndex < 3) {
        const checkedCount = newStates[stepIndex].filter(
          (isChecked, index) => index !== 1 && isChecked
        ).length;
        setProgressStepThree((checkedCount / 2) * 100);
      }
      if (stepIndex === 3 && labelIndex < 1) {
        const checkedCount = newStates[stepIndex].filter(
          (isChecked) => isChecked
        ).length;
        setProgressStepFour((checkedCount / 1) * 100);
      }
      return newStates;
    });
    const checkboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      'input[type="checkbox"]'
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.disabled = true;
    });

    try {
      if (activeRoleRequest.projectId) {
        const status = subStage.completed ? "incomplete" : "complete";
        const response = await submitTimeline(
          activeRoleRequest.projectId,
          subStage.id,
          status
        );
        if (response) {
          const timeLineData = await getTimeline(activeRoleRequest.projectId);
          setStages(timeLineData);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      checkboxes.forEach((checkbox: HTMLInputElement) => {
        checkbox.disabled = false;
      });
    }
  };

  useEffect(() => {}, []);
  const navigate = (step: Stages, index: number, labelIndex: number) => {
    if (index === 0 && labelIndex === 0) {
      onNavigate(OverviewMenu.Job_Description);
    } else if (index === 0 && labelIndex === 1) {
      onNavigate(OverviewMenu.Search_Parameters);
    } else if (index === 0 && labelIndex === 2) {
      onNavigate(OverviewMenu.Ideal_Candidate_Profiles);
    } else if (index === 1 && labelIndex === 1) {
      onNavigate(ReviewedCandidates.Qualified);
    } else if (index === 1 && labelIndex === 2) {
      onNavigate(ReviewedCandidates.Qualified);
    } else if (index === 1 && labelIndex === 3) {
      onNavigate(OverviewMenu.Search_Parameters);
    } else if (index === 1 && labelIndex === 4) {
      onNavigate(OverviewMenu.Ideal_Candidate_Profiles);
    }
  };
  const handleLabelHover = (step: number | null, labelIndex: number | null) => {
    if ((step === 1 && labelIndex === 0) || (step === 1 && labelIndex === 2)) {
      return;
    } else {
      setHoveredLabel({ step, labelIndex });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "24px 40px",
          width: "100%",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#181818",
              fontSize: "1.5rem",
            }}
          >
            To Do
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100vw",
          position: "absolute",
          borderBottom: "1px solid #D9D9D9",
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 291px)",
          overflow: "auto",
          position: "relative",
        }}
      >
        <Box
          sx={{
            maxWidth: "560px",
            marginX: "auto",
            padding: "48px 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          {stages
            ? stages.map((step: Stages, index: number) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "17px 0",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      paddingRight: "30px",
                      position: "absolute",
                      top: "-34px",
                      left: "-230px",
                      maxWidth: "180px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#F7F7FF",
                        padding: "5px 10px",
                        color: "#8F8F8F",
                        borderRadius: "5px",
                      }}
                    >
                      {/* <Typography
                        sx={{ color: "#8F8F8F", marginRight: "10px" }}
                      >
                        Select Date{" "}
                      </Typography>
                      <CalendarIcon /> */}
                      {/* <DateField
                        label="Creation Date"
                        readOnly
                        value={dayjs(step.completionDate)}
                        slotProps={{ textField: { size: "small" } }}
                        sx={{
                          "& .MuiInputBase-input": {
                            height: "1.2em",
                          },
                        }}
                      /> */}
                      <DatePicker
                        label="Select Date"
                        slots={{ openPickerIcon: CalendarIcon }}
                        slotProps={{ textField: { size: "small" } }}
                        sx={{
                          "& .MuiInputBase-root": {
                            width: "171px",
                            height: "2.5rem",
                            borderRadius: "8px",
                            backgroundColor: "#E6E6E6",
                          },
                          "& .MuiInputBase-input": {
                            color: "#8F8F8F",
                          },

                          "& .MuiFormLabel-root": {
                            color: "#8F8F8F !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "2px",
                      backgroundColor: "#D2D2D2",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "100%",
                        width: "25px",
                        height: "25px",
                        position: "absolute",
                        top: "-30px",
                        border: `${
                          (index === 0 && progress < 49) ||
                          (index === 1 && progressStepTwo < 24) ||
                          (index === 2 && progressStepThree < 49) ||
                          (index === 3 && progressStepFour < 49)
                            ? "4px solid #D2D2D2"
                            : index === 0 && progress > 99
                            ? "4px solid #E5F4E7"
                            : index === 1 && progressStepTwo > 99
                            ? "4px solid #E5F4E7"
                            : index === 2 && progressStepThree > 99
                            ? "4px solid #E5F4E7"
                            : index === 3 && progressStepFour > 99
                            ? "4px solid #E5F4E7"
                            : "4px solid #4C8E5E"
                        }`,
                        backgroundColor: `${
                          (index === 0 && progress < 49) ||
                          (index === 1 && progressStepTwo < 24) ||
                          (index === 2 && progressStepThree < 49) ||
                          (index === 3 && progressStepFour < 49)
                            ? "#ABABAB"
                            : index === 0 && progress > 99
                            ? "#4C8E5E"
                            : index === 1 && progressStepTwo > 99
                            ? "#4C8E5E"
                            : index === 2 && progressStepThree > 99
                            ? "#4C8E5E"
                            : index === 3 && progressStepFour > 99
                            ? "#4C8E5E"
                            : "#FFF"
                        }`,
                      }}
                    ></Box>
                    <Box
                      sx={{
                        height: `${
                          index === 0
                            ? progress
                            : index === 1
                            ? progressStepTwo
                            : index === 2
                            ? progressStepThree
                            : index === 3
                            ? progressStepFour
                            : ""
                        }%`,
                        width: "2px",
                        backgroundColor: "#4C8E5E",
                        transition: "height 0.5s",
                      }}
                    ></Box>
                  </Box>
                  <Box sx={{ paddingLeft: "30px" }}>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "-29px",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "1.25rem", fontWeight: "400" }}
                      >
                        {step.title}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "360px",
                        "& .MuiFormGroup-root": {
                          padding: "8px 0",
                        },
                      }}
                    >
                      <FormGroup>
                        {step.subStages.map((subStage, labelIndex) => (
                          <FormControlLabel
                            key={subStage.id}
                            control={
                              <Checkbox
                                onChange={(event) =>
                                  checkBoxChange(
                                    step,
                                    subStage,
                                    index,
                                    labelIndex,
                                    event.target.checked
                                  )
                                }
                                checked={
                                  checkboxStates[index]
                                    ? checkboxStates[index][labelIndex]
                                    : false
                                }
                              />
                            }
                            label={
                              <div
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                                onMouseEnter={() =>
                                  handleLabelHover(index, labelIndex)
                                }
                                onMouseLeave={() =>
                                  handleLabelHover(null, null)
                                }
                                className="label-container"
                              >
                                {subStage.title}{" "}
                                {subStage.optional ? "(Optional)" : ""}
                                {index < 2 &&
                                  hoveredLabel &&
                                  hoveredLabel.step === index &&
                                  hoveredLabel.labelIndex === labelIndex && (
                                    <button
                                      className="hover-button"
                                      onClick={() =>
                                        navigate(step, index, labelIndex)
                                      }
                                      style={{
                                        marginLeft: "15px",
                                        padding: "0.125rem 8px",
                                        fontSize: "14px",
                                        backgroundColor: "#9F14E0",
                                        border: "none",
                                        color: "#FFF",
                                        borderRadius: "10px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Add
                                    </button>
                                  )}
                              </div>
                            }
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "0.875rem",
                                color: "#585858",
                              },
                              "& .MuiSvgIcon-root": {
                                fontSize: "1rem",
                                color: "#4C8E5E",
                              },
                            }}
                          />
                        ))}
                      </FormGroup>
                    </Box>
                  </Box>
                </Box>
              ))
            : []}
        </Box>
      </Box>
    </Box>
  );
};

export default Timeline;

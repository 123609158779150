import { StageItems } from "../enums/addRole";

export interface RoleModal {
  projectId: string;
  degree: string;
  file: null | File;
  jobTitle: string;
  location: string;
  minYearsOfExp: number;
  maxYearsOfExp: number;
  jobDescriptionUrl: string;
  jobPostingUrl: string;
  notes: string;
  roleCategory: string;
  workType: string;
  workEnviroment: string;
  requiredSkils: string[];
  simularProfiles: IdealProfile[];
  comment: {
    notes: string;
    date: Date;
  };
}

export interface IdealProfile {
  linkendinProfile: string;
  comments: string;
}

export enum RoleActiveStatus {
  Active = "Active",
  Inactive = "Inactive",
  Pending = "Pending",
}
export interface RoleModalForList extends RoleModal {
  status: RoleActiveStatus;
}

export interface RoleModalRequest {
  projectId?: string;
  title: string;
  description: string;
  department: string;
  location: string;
  environment: string;
  role: string;
  degree: string;
  minYearsExperience: string | number;
  maxYearsExperience: string | number;
  jobDescriptionUrl?: string;
  jobPostingUrl?: string;
  status?: string;
  skills: string[];
  stages?: Stages[];
  searchCriteria?: SearchCriteria[];
  idealCandidateProfiles?: IdealCandidateProfiles[];
}
export interface IdealCandidateProfiles {
  idealCandidateProfileId?: string;
  createdDate?: Date | string;
  idealProfiles: IdealProfiles[];
}
export interface Stages {
  completed: boolean;
  completionDate: Date;
  id: string;
  optional: boolean;
  subStages: SubStages[];
  title: string;
}

export interface SubStages {
  completed: boolean;
  completionDate: Date;
  id: string;
  optional: boolean;
  subStages: [];
  title: string;
}
export interface IdealProfile {
  linkendinProfile: string;
  comments: string;
}
export interface SearchCriteria {
  searchCriteriaId?: string;
  qualified?: string;
  needs?: string;
  notes?: string;
  createdDate?: Date | string;
  dateForSelect?: Date | string;
}

export interface IdealProfiles {
  linkedInProfileid?: string;
  linkedInUrl: string;
  comments: string;
}
export interface IdealProfilesSc {
  profiles: IdealProfilesData[];
  createdDate?: Date | string;
  dateForSelect?: Date | string;
  searchCriteriaId?: string;
}
export interface IdealProfilesData {
  linkedInProfileid?: string;
  linkedInUrl: string;
  comments: string;
}
export interface RoleStagesItems {
  id: number;
  name: StageItems;
}

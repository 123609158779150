import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const PersonIcon = (props: any) => {
	return (
		<SvgIcon {...props} width="17" height="18" viewBox="0 0 17 18">
			<path d="M6.62909 6.0913C6.62909 5.23089 7.31409 4.53339 8.15909 4.53339C9.00408 4.53339 9.68908 5.23089 9.68908 6.0913C9.68908 6.95172 9.00408 7.64922 8.15909 7.64922C7.31409 7.64922 6.62909 6.95172 6.62909 6.0913ZM8.15909 3.46143C6.73268 3.46143 5.57634 4.63886 5.57634 6.0913C5.57634 7.54374 6.73268 8.72118 8.15909 8.72118C9.5855 8.72118 10.7418 7.54374 10.7418 6.0913C10.7418 4.63886 9.5855 3.46143 8.15909 3.46143ZM4.53053 12.8086C4.53053 11.7121 5.40279 10.8291 6.47461 10.8291H10.1733C11.2502 10.8291 12.1173 11.7172 12.1173 12.8086V14.0021C12.1173 14.2981 12.353 14.538 12.6437 14.538C12.9344 14.538 13.1701 14.2981 13.1701 14.0021V12.8086C13.1701 11.1272 11.8335 9.7571 10.1733 9.7571H6.47461C4.82332 9.7571 3.47778 11.1181 3.47778 12.8086V14.0021C3.47778 14.2981 3.71345 14.538 4.00416 14.538C4.29487 14.538 4.53053 14.2981 4.53053 14.0021V12.8086Z" />
		</SvgIcon>
	);
};

import React from "react"; // eslint-disable-line no-unused-vars
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import * as consts from "../consts";

export const AuthProvider = (props: any): JSX.Element => {
	return (
		<Auth0Provider
			domain={consts.AUTH0_DOMAIN}
			clientId={consts.AUTH0_CLIENTID}
			authorizationParams={{
				redirect_uri: window.location.href,
				audience: consts.AUTH0_AUDIENCE,
				responseType: "id_token token",
				scope: consts.AUTH0_SCOPE,
			}}
		>
			{props.children}
		</Auth0Provider>
	);
};

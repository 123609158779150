import React, { useEffect, useState } from "react"; // eslint-disable-line no-unused-vars
import {
  Box,
  Button,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  MenuProps,
  Modal,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { CandidateCard } from "..";
import { Candidate, FilterCandidates, SortByDateCandidate } from "../../types";
import { FileDownloadOutlined, Close } from "@mui/icons-material";
import { Status } from "../../enums/candidate-status";
import { ReviewedCandidates, ReviewedTab } from "../../types/tab";
import { CSVLink } from "react-csv";
import { CSVHeadersList } from "../../types/csvModel";
import NoQuickReviewQualifiedCandidates from "../../assets/images/noQuickReviewAndQualified.png";
import NoReviewedCandidates from "../../assets/images/noReviewedCandidates.png";
import { styled, alpha } from "@mui/material/styles";
import {
  IdealCandidateProfiles,
  RoleModalRequest,
  SearchCriteria,
} from "../../utilities/roles";
import { Overview } from "../overview/overview";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FilterIcon } from "../../utilities/icons/filterIcon";
import { ShowIcon } from "../../utilities/icons/showIcon";
import { HideIcon } from "../../utilities/icons/hideIcon";
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
export interface ResultsProps {
  candidates: Candidate[];
  filter: string;
  selectedRoutes: string[];
  isDevAccount: boolean;
  activeRoleRequest: RoleModalRequest;
  activeSearchCriteria?: SearchCriteria;
  searchCriteria?: SearchCriteria[];
  isConfirmationPopupOpen: boolean;
  isRejectModalOpen: boolean;
  activeIdealPorfile: IdealCandidateProfiles;
  idealCandidateProfiles: IdealCandidateProfiles[];
  handleAccept: (
    candidateId: string,
    candidateData: Candidate,
    action: Status
  ) => void;
  handleReject: (
    candidateId: string,
    candidateData: Candidate,
    action: Status
  ) => void;
  handleAcceptAllUsers: (
    usersFilter: FilterCandidates,
    selectedTab: ReviewedCandidates
  ) => void;
  handleOpenReviewModal: (
    candidateId: string,
    candidateData: Candidate,
    action: string
  ) => void;
  handleUpdateJobDescriptionData: (data: RoleModalRequest) => void;
  updateSearchParameters: (data: SearchCriteria, projectId?: string) => void;
  saveIdealCandidateProfiles: (
    data: IdealCandidateProfiles,
    projectId?: string
  ) => void;
  handleButtonGroupChoose: (label: string, key: string) => void;
  handleDisqualifyUserFromModal: (statusComentRequest: string) => void;
  handleUpdateChangesDisqualifyModal: (statusComentRequest: string) => void;
  handleResetProgres: () => void;
}

export const Results = ({
  candidates,
  filter,
  selectedRoutes,
  isDevAccount,
  activeRoleRequest,
  activeSearchCriteria,
  searchCriteria,
  isConfirmationPopupOpen,
  isRejectModalOpen,
  activeIdealPorfile,
  idealCandidateProfiles,
  updateSearchParameters,
  handleAccept,
  handleReject,
  handleAcceptAllUsers,
  handleOpenReviewModal,
  handleUpdateJobDescriptionData,
  saveIdealCandidateProfiles,
  handleButtonGroupChoose,
  handleDisqualifyUserFromModal,
  handleUpdateChangesDisqualifyModal,
  handleResetProgres,
}: ResultsProps): JSX.Element => {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState<ReviewedCandidates>(
    ReviewedCandidates.Overview
  );
  const [acceptAllModalIsOpen, setAcceptAllModalIsOpen] = useState(false);

  const [sortByLastest, setSortByLastest] = useState<SortByDateCandidate>(
    SortByDateCandidate.Lastest
  );
  const [newCandidates, setNewCandidates] = useState<Candidate[]>([]);
  const [rewievedCandidates, setRewievedCandidates] = useState<Candidate[]>([]);
  const [filterCandidates, setFilterCandidates] = useState<FilterCandidates>(
    FilterCandidates.All
  );
  const [isVisibleQualified, setIsVisibleQualified] = useState(false);

  const toggleVisibilityQualified = () => {
    setIsVisibleQualified(!isVisibleQualified);
  };
  const [isVisibleReview, setIsVisibleReview] = useState(false);

  const toggleVisibilityReview = () => {
    setIsVisibleReview(!isVisibleReview);
  };
  const [isVisibleNotes, setIsVisibleNotes] = useState(false);

  const toggleVisibilityNotes = () => {
    setIsVisibleNotes(!isVisibleNotes);
  };
  const [anchorElFilter, setAnchorElFilter] =
    React.useState<null | HTMLElement>(null);
  const openFilterSelect = Boolean(anchorElFilter);
  const handleClickFilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFilter(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };
  const [anchorEl, setAnchorElAction] = React.useState<null | HTMLElement>(
    null
  );
  const open = Boolean(anchorEl);
  const handleClickSelectAction = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAction(event.currentTarget);
  };
  const handleCloseAction = () => {
    setAnchorElAction(null);
  };
  const tabs: ReviewedTab[] = [
    {
      value: ReviewedCandidates.Overview,
      active: true,
      amount: null,
      visible: isDevAccount ? true : false,
    },
    {
      value: ReviewedCandidates.Qualified,
      active: false,
      amount: candidates.filter((candidate: Candidate) => {
        return candidate.status === Status.Pending || candidate.status === Status.Crawled;
      }).length,
      visible: true,
    },
    // {
    //   value: ReviewedCandidates.Quick_Review,
    //   active: false,
    //   amount: 0,
    //   visible: true,
    // },
    {
      value: ReviewedCandidates.Reviewed,
      active: false,
      amount: candidates.filter((candidate: Candidate) => {
        return candidate.status === Status.Accepted || candidate.status === Status.Rejected
      }).length,
      visible: true,
    },
  ];

  useEffect(() => {
    const newCandidatesData = candidates.filter((candidate: Candidate) => {
      return candidate.status === Status.Pending || candidate.status === Status.Crawled
    });
    setNewCandidates(newCandidatesData);
    const rewievedCandidatesData = candidates.filter((candidate: Candidate) => {
      return candidate.status === Status.Accepted || candidate.status === Status.Rejected
    });
    setRewievedCandidates(rewievedCandidatesData);
    setFilterCandidates(FilterCandidates.All);
  }, [candidates]);

  const handleTabsChange = (selectedTab: ReviewedTab) => {
    setFilterCandidates(FilterCandidates.All);
    const newCandidatesData = candidates.filter((candidate: Candidate) => {
      return candidate.status === Status.Pending || candidate.status === Status.Crawled
    });
    setNewCandidates(newCandidatesData);
    const rewievedCandidatesData = candidates.filter((candidate: Candidate) => {
      return candidate.status === Status.Accepted || candidate.status === Status.Rejected
    });
    setRewievedCandidates(rewievedCandidatesData);
    tabs.map((tab: ReviewedTab) => {
      if (tab.value === selectedTab.value) {
        return (tab.active = true);
      } else {
        return (tab.active = false);
      }
    });

    setSelectedTab(selectedTab.value);
  };

  const handleFilterCount = (filter: FilterCandidates): number => {
   
    switch (filter) {
      case FilterCandidates.All:
        return candidates.filter((candidate: Candidate) => {
          return selectedTab === ReviewedCandidates.Qualified
            ?  (candidate.status === Status.Pending || candidate.status === Status.Crawled)&& 
                candidate.confidence * 100 >= 0
            :  ( candidate.status === Status.Accepted || candidate.status === Status.Rejected)   &&
                candidate.confidence * 100 >= 0;
        }).length;

      case FilterCandidates.Qualified:
        return candidates.filter((candidate: Candidate) => {
          return selectedTab === ReviewedCandidates.Qualified
          ?  (candidate.status === Status.Pending || candidate.status === Status.Crawled) &&
                candidate.confidence * 100 >= 70
            :    ( ( candidate.status === Status.Accepted || candidate.status === Status.Rejected) )   &&
                candidate.confidence * 100 >= 70;
        }).length;
      case FilterCandidates.NeedsReview:
        return candidates.filter((candidate: Candidate) => {
          return selectedTab === ReviewedCandidates.Qualified
            ? (candidate.status === Status.Pending || candidate.status === Status.Crawled) &&
            candidate.confidence * 100 < 70
            :   ( candidate.status === Status.Accepted || candidate.status === Status.Rejected) &&
                candidate.confidence * 100 < 70;
        }).length;
     
      case FilterCandidates.ApprovedCandidates:
        return candidates.filter((candidate: Candidate) => {
          return candidate.status === Status.Accepted;
        }).length;
      case FilterCandidates.RejectedCandidates:
        return candidates.filter((candidate: Candidate) => {
          return candidate.status === Status.Rejected;
        }).length;
      // case FilterCandidates.ScoreGreaterThan90:
      //   return candidates.filter((candidate: Candidate) => {
      //     return selectedTab === ReviewedCandidates.Qualified
      //       ? candidate.status === Status.Pending &&
      //           candidate.confidence * 100 >= 90 &&
      //           candidate.confidence * 100 <= 100
      //       : candidate.status !== Status.Pending &&
      //           candidate.confidence * 100 >= 90 &&
      //           candidate.confidence * 100 <= 100;
      //   }).length;

      // case FilterCandidates.ScoreGreaterThan80:
      //   return candidates.filter((candidate: Candidate) => {
      //     return selectedTab === ReviewedCandidates.Qualified
      //       ? candidate.status === Status.Pending &&
      //           candidate.confidence * 100 >= 80 &&
      //           candidate.confidence * 100 < 90
      //       : candidate.status !== Status.Pending &&
      //           candidate.confidence * 100 >= 80 &&
      //           candidate.confidence * 100 < 90;
      //   }).length;

      default:
        return 0;
    }
  };

  const handleFilterChange = (filter: FilterCandidates) => {
    let filteredUsers = [];
    setFilterCandidates(filter);
    switch (filter) {
      case FilterCandidates.All:
        filteredUsers = candidates.filter((candidate: Candidate) => {
          return selectedTab === ReviewedCandidates.Qualified
            ?   (candidate.status === Status.Pending || candidate.status === Status.Crawled)&&
                candidate.confidence * 100 >= 0
            : ( candidate.status === Status.Accepted || candidate.status === Status.Rejected) &&
                candidate.confidence * 100 >= 0;
        });
        selectedTab === ReviewedCandidates.Qualified
          ? setNewCandidates(filteredUsers)
          : setRewievedCandidates(filteredUsers);
        break;
      case FilterCandidates.Qualified:
        filteredUsers = candidates.filter((candidate: Candidate) => {
          return selectedTab === ReviewedCandidates.Qualified
            ?  (candidate.status === Status.Pending || candidate.status === Status.Crawled) &&
                candidate.confidence * 100 >= 70
            :  ( candidate.status === Status.Accepted || candidate.status === Status.Rejected) &&
                candidate.confidence * 100 >= 70;
        });
        selectedTab === ReviewedCandidates.Qualified
          ? setNewCandidates(filteredUsers)
          : setRewievedCandidates(filteredUsers);
        break;
      case FilterCandidates.NeedsReview:
        filteredUsers = candidates.filter((candidate: Candidate) => {
          return selectedTab === ReviewedCandidates.Qualified
            ?  (candidate.status === Status.Pending || candidate.status === Status.Crawled) &&
                candidate.confidence * 100 < 70
            :  ( candidate.status === Status.Accepted || candidate.status === Status.Rejected)  &&
                candidate.confidence * 100 < 70;
        });
      

        selectedTab === ReviewedCandidates.Qualified
          ? setNewCandidates(filteredUsers)
          : setRewievedCandidates(filteredUsers);
        break;

      // case FilterCandidates.ScoreGreaterThan90:
      //   filteredUsers = candidates.filter((candidate: Candidate) => {
      //     return selectedTab === ReviewedCandidates.Qualified
      //       ? candidate.status === Status.Pending &&
      //           candidate.confidence * 100 >= 90 &&
      //           candidate.confidence * 100 <= 100
      //       : candidate.status !== Status.Pending &&
      //           candidate.confidence * 100 >= 90 &&
      //           candidate.confidence * 100 <= 100;
      //   });
      //   selectedTab === ReviewedCandidates.Qualified
      //     ? setNewCandidates(filteredUsers)
      //     : setRewievedCandidates(filteredUsers);
      //   break;
      // case FilterCandidates.ScoreGreaterThan80:
      //   filteredUsers = candidates.filter((candidate: Candidate) => {
      //     return selectedTab === ReviewedCandidates.Qualified
      //       ? candidate.status === Status.Pending &&
      //           candidate.confidence * 100 >= 80 &&
      //           candidate.confidence * 100 < 90
      //       : candidate.status !== Status.Pending &&
      //           candidate.confidence * 100 >= 80 &&
      //           candidate.confidence * 100 < 90;
      //   });
      //   selectedTab === ReviewedCandidates.Qualified
      //     ? setNewCandidates(filteredUsers)
      //     : setRewievedCandidates(filteredUsers);
      //   break;
      case FilterCandidates.ApprovedCandidates:
        filteredUsers = candidates.filter((candidate: Candidate) => {
          return candidate.status === Status.Accepted;
        });
        setRewievedCandidates(filteredUsers);
        break;
      case FilterCandidates.RejectedCandidates:
        filteredUsers = candidates.filter((candidate: Candidate) => {
          return candidate.status === Status.Rejected;
        });
        setRewievedCandidates(filteredUsers);
        break;
      default:
        return [];
    }
  };
  const handleOpenAcceptAllModal = () => setAcceptAllModalIsOpen(true);
  const handleCloseAcceptAllModal = () => setAcceptAllModalIsOpen(false);

  const handleApproveAll = () => {
    handleCloseAcceptAllModal();
    handleAcceptAllUsers(filterCandidates, selectedTab);
    setFilterCandidates(FilterCandidates.All);
  };
  const handleSortByDateChange = (e: any) => {
    setSortByLastest(e.target.value);

    const sortedCandidates =
      selectedTab === ReviewedCandidates.Qualified
        ? newCandidates.sort((a: Candidate, b: Candidate) => {
            return sortByLastest === SortByDateCandidate.Lastest
              ? new Date(a.dateCreated).getTime() -
                  new Date(b.dateCreated).getTime()
              : new Date(b.dateCreated).getTime() -
                  new Date(a.dateCreated).getTime();
          })
        : rewievedCandidates.sort((a: Candidate, b: Candidate) => {
            return sortByLastest === SortByDateCandidate.Lastest
              ? new Date(a.dateCreated).getTime() -
                  new Date(b.dateCreated).getTime()
              : new Date(b.dateCreated).getTime() -
                  new Date(a.dateCreated).getTime();
          });
    selectedTab === ReviewedCandidates.Qualified
      ? setNewCandidates(sortedCandidates)
      : setRewievedCandidates(sortedCandidates);
  };

  const getMinWidth = (tabValue: ReviewedCandidates) => {
    switch (tabValue) {
      case ReviewedCandidates.Overview:
        return "80px";
      case ReviewedCandidates.Qualified:
        return "105px";
      case ReviewedCandidates.Quick_Review:
        return "136px";
      case ReviewedCandidates.Reviewed:
        return "105px";
      default:
        return "85px";
    }
  };
  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 552,
    bgcolor: "background.paper",
    borderRadius: 2,
    outline: "none",
    p: 2,
  };

  const onNavigateToReviewTab = (tab: ReviewedCandidates) => {
    setSelectedTab(tab);
  };

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: selectedTab !== ReviewedCandidates.Overview ? 2 : 0,
      }}
    >
      {" "}
      <Modal open={acceptAllModalIsOpen} onClose={handleCloseAcceptAllModal}>
        <Box sx={styleModal}>
          <Box sx={{ display: "flex", padding: "8px 8px 24px" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              fontSize="20px"
            >
              Are you sure you want to approve all?
            </Typography>
            <Close
              sx={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={handleCloseAcceptAllModal}
            />
          </Box>
          <Box
            sx={{
              borderTop: "1px solid #D9D9D9",
              padding: "24px 8px 0",
              display: "flex",
              flexDirection: "row",
              flexwrap: "nowrap",
              gap: 2,
            }}
          >
            <Box
              sx={{
                minHeight: "126px",
                fontSize: "16px",
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                lineHeight={1}
                fontSize="16px"
                sx={{ paddingBottom: "50px" }}
              >
                We will approve{" "}
                {handleFilterCount(filterCandidates) === 1
                  ? "1  candidate"
                  : `all the ${handleFilterCount(
                      filterCandidates
                    )} candidates.`}
              </Typography>

              {filterCandidates === 1 && (
                <ul style={{ paddingLeft: "30px" }}>
                  {handleFilterCount(FilterCandidates.Qualified) > 0 && (
                    <li style={{ marginTop: "8px" }}>
                      {handleFilterCount(FilterCandidates.Qualified)} -
                      Qualified
                    </li>
                  )}
                  {handleFilterCount(FilterCandidates.All) -
                    handleFilterCount(FilterCandidates.Qualified) >
                    0 && (
                    <li style={{ marginTop: "8px" }}>
                      {handleFilterCount(FilterCandidates.All) -
                        handleFilterCount(FilterCandidates.Qualified)}{" "}
                      - Needs Review
                    </li>
                  )}
                </ul>
              )}

              {filterCandidates === 70 && (
                <ul style={{ paddingLeft: "30px" }}>
                  {handleFilterCount(filterCandidates) > 0 && (
                    <li style={{ marginTop: "8px" }}>
                      {handleFilterCount(filterCandidates)} - Qualified
                    </li>
                  )}
                </ul>
              )}
              {filterCandidates === 60 && (
                <ul style={{ paddingLeft: "30px" }}>
                  {handleFilterCount(filterCandidates) > 0 && (
                    <li style={{ marginTop: "8px" }}>
                      {handleFilterCount(filterCandidates)} - Needs Review
                    </li>
                  )}
                </ul>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              padding: "24px 8px 8px ",
              display: "flex",
              flexDirection: "row",
              flexwrap: "nowrap",
              gap: 2,
            }}
          >
            <Box sx={{ display: "flex", gap: 2, marginLeft: "auto" }}>
              {" "}
              <Button
                variant="text"
                onClick={handleCloseAcceptAllModal}
                sx={{ textTransform: "none", color: "#000" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleApproveAll}
                sx={{
                  borderRadius: "100px",
                  height: "40px",
                  boxShadow: "none",
                  textTransform: "none",
                  color: "#fff",
                }}
              >
                Yes, Approve All
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Grid
        item
        sx={{
          backgroundColor: "#fff",
          paddingLeft: "16px",
          borderBottom: "1px solid #D9D9F5",
        }}
      >
        <Grid
          item
          sx={{ marginX: "auto", maxWidth: "1696px", paddingX: "24px" }}
        >
          <Grid container alignItems="center" minHeight={"48px"}>
            {" "}
            <Typography variant="h5">
              {selectedRoutes[selectedRoutes.length - 1]}
            </Typography>
            {selectedTab === ReviewedCandidates.Qualified &&
              newCandidates.length > 0 && (
                <Typography
                  variant="body1"
                  sx={{
                    color: "#585858",
                    marginLeft: {
                      md: "auto",
                      sm: "auto",
                      xs: 0,
                      lg: "auto",
                    },
                    paddingLeft: "16px",
                  }}
                >
                  Last updated: {""}
                  {new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    day: "numeric",
                    month: "long",
                  }).format(
                    new Date(
                      candidates.reduce(
                        (latest: Date | any, user: Candidate) => {
                          const userDate: Date = new Date(user.dateCreated);
                          if (!latest || userDate > latest) {
                            return userDate;
                          }
                          return latest;
                        },
                        null
                      )
                    )
                  )}
                </Typography>
              )}
          </Grid>

          <Grid item>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                paddingTop: "11px",
                alignItems: "center",
              }}
            >
              {tabs
                .filter((singleTab: ReviewedTab) => {
                  return singleTab.visible === true;
                })
                .map((singleTab: ReviewedTab) => (
                  <Typography
                    onClick={() => handleTabsChange(singleTab)}
                    key={singleTab.value}
                    variant="body2"
                    sx={{
                      cursor: "pointer",
                      color:
                        singleTab.value === selectedTab
                          ? theme.palette.secondary.main
                          : theme.palette.text.secondary,
                      borderBottom:
                        singleTab.value === selectedTab
                          ? "3px solid #AD00FF"
                          : "",
                      paddingBottom: "8px",
                      textAlign: "center",
                      fontFamily: "Arial",
                      fontWeight: singleTab.value === selectedTab ? 700 : 500,

                      minWidth: getMinWidth(singleTab.value),
                      "&:hover": {
                        color:
                          singleTab.value === selectedTab
                            ? "#8A00CC"
                            : "#585858",
                      },
                    }}
                  >
                    {(singleTab.amount === 0 ||
                      (singleTab.amount !== null && singleTab.amount > 0)) && (
                      <span
                        style={{
                          cursor: "pointer",
                          borderRadius: "15px 15px",
                          backgroundColor:
                            singleTab.value === selectedTab
                              ? "#EED9F8"
                              : "#E6E6E6",
                          color:
                            singleTab.value === selectedTab
                              ? "#AD00FF"
                              : "#8F8F8F",
                          padding: "2px 9px",
                          marginRight: "8px",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {singleTab.amount}
                      </span>
                    )}
                    {""}
                    {singleTab.value}
                  </Typography>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {selectedTab === ReviewedCandidates.Overview && (
        <Overview
          activeSearchCriteria={activeSearchCriteria}
          searchCriteria={searchCriteria}
          activeIdealPorfile={activeIdealPorfile}
          idealCandidateProfiles={idealCandidateProfiles}
          activeRoleRequest={activeRoleRequest}
          updateSearchParameters={updateSearchParameters}
          saveIdealCandidateProfiles={saveIdealCandidateProfiles}
          handleUpdateJobDescriptionData={handleUpdateJobDescriptionData}
          onNavigateToReviewTab={onNavigateToReviewTab}
        />
      )}
      {selectedTab === ReviewedCandidates.Qualified && (
        <Grid
          container
          sx={{
            gap: 1,
            display: "flex",
            flexDirection: "column",
            marginX: "auto",
            maxWidth: "1696px",
            padding: {
              xl: "0 16px 0 32px",
              lg: " 0 16px 0 40px ",
              md: " 0 16px 0 40px",
              sm: " 0 16px 0 40px",
              xs: " 0 16px 0 40px",
            },
          }}
        >
          {newCandidates.length > 0 && (
            <Grid container>
              <Grid
                item
                sx={{
                  display: "flex",
                  gap: 1,
                  marginBottom: "24px",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={handleFilterCount(FilterCandidates.All) === 0}
                  sx={{
                    borderRadius: "10px",
                    height: "34px",
                    boxShadow: "none",
                    padding: "6px 16px 6px 9px",
                    backgroundColor:
                      filterCandidates === FilterCandidates.All
                        ? theme.palette.secondary.main
                        : "#F2F2F2",
                    color:
                      filterCandidates === FilterCandidates.All
                        ? "#fff"
                        : theme.palette.text.secondary,
                    "&:hover": {
                      color:
                        filterCandidates === FilterCandidates.All
                          ? "#fff"
                          : theme.palette.text.secondary,
                      boxShadow: "none",
                      backgroundColor:
                        filterCandidates === FilterCandidates.All
                          ? "#8A00CC"
                          : "#E6E6E6",
                    },
                  }}
                  onClick={() => handleFilterChange(FilterCandidates.All)}
                >
                  {" "}
                  <span
                    style={{
                      backgroundColor:
                        filterCandidates === FilterCandidates.All
                          ? "#FFF"
                          : "#E6E6E6",
                      color:
                        filterCandidates === FilterCandidates.All
                          ? theme.palette.secondary.main
                          : "#8F8F8F",
                      padding: "0 7px",
                      borderRadius: "7px",
                      display: "flex",
                      height: "16px",
                      alignItems: "center",
                      marginRight: "8px",
                      fontWeight:
                        filterCandidates === FilterCandidates.All ? 500 : 400,
                    }}
                  >
                    {handleFilterCount(FilterCandidates.All)}
                  </span>{" "}
                  All
                </Button>
                {/* <Button
                  disabled={
                    handleFilterCount(FilterCandidates.ScoreGreaterThan90) === 0
                  }
                  color="secondary"
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    height: "34px",
                    padding: "6px 8px",
                    boxShadow: "none",
                    backgroundColor:
                      filterCandidates === FilterCandidates.ScoreGreaterThan90
                        ? theme.palette.secondary.main
                        : theme.palette.text.disabled,
                    color:
                      filterCandidates === FilterCandidates.ScoreGreaterThan90
                        ? "#fff"
                        : theme.palette.text.secondary,
                    "&:hover": {
                      color:
                        filterCandidates === FilterCandidates.ScoreGreaterThan90
                          ? "#fff"
                          : theme.palette.text.secondary,
                      boxShadow: "none",
                      backgroundColor:
                        filterCandidates === FilterCandidates.ScoreGreaterThan90
                          ? "#8A00CC"
                          : "#F2F2F2",
                    },
                    fontWeight:
                      filterCandidates === FilterCandidates.ScoreGreaterThan90
                        ? 500
                        : 400,
                  }}
                  onClick={() =>
                    handleFilterChange(FilterCandidates.ScoreGreaterThan90)
                  }
                >
                  <span
                    style={{
                      backgroundColor: "#fff",
                      color:
                        filterCandidates === FilterCandidates.ScoreGreaterThan90
                          ? theme.palette.secondary.main
                          : theme.palette.secondary.light,
                      padding: "0 7px",
                      borderRadius: "7px",
                      display: "flex",
                      height: "16px",
                      alignItems: "center",
                      marginRight: "8px",
                      fontWeight:
                        filterCandidates === FilterCandidates.ScoreGreaterThan90
                          ? 500
                          : 400,
                    }}
                  >
                    {handleFilterCount(FilterCandidates.ScoreGreaterThan90)}
                  </span>{" "}
                  90% Match
                </Button>
                <Button
                  color="secondary"
                  onClick={() =>
                    handleFilterChange(FilterCandidates.ScoreGreaterThan80)
                  }
                  disabled={
                    handleFilterCount(FilterCandidates.ScoreGreaterThan80) === 0
                  }
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    height: "34px",
                    padding: "6px 8px",
                    backgroundColor:
                      filterCandidates === FilterCandidates.ScoreGreaterThan80
                        ? theme.palette.secondary.main
                        : theme.palette.text.disabled,
                    color:
                      filterCandidates === FilterCandidates.ScoreGreaterThan80
                        ? "#fff"
                        : theme.palette.text.secondary,
                    "&:hover": {
                      color:
                        filterCandidates === FilterCandidates.ScoreGreaterThan80
                          ? "#fff"
                          : theme.palette.text.secondary,
                      boxShadow: "none",
                      backgroundColor:
                        filterCandidates === FilterCandidates.ScoreGreaterThan80
                          ? "#8A00CC"
                          : "#F2F2F2",
                    },
                  }}
                >
                  {" "}
                  <span
                    style={{
                      backgroundColor: "#fff",
                      color:
                        filterCandidates === FilterCandidates.ScoreGreaterThan80
                          ? theme.palette.secondary.main
                          : theme.palette.secondary.light,
                      padding: "0 7px",
                      borderRadius: "7px",
                      display: "flex",
                      height: "16px",
                      alignItems: "center",
                      marginRight: "8px",
                      fontWeight:
                        filterCandidates === FilterCandidates.ScoreGreaterThan80
                          ? 500
                          : 400,
                    }}
                  >
                    {handleFilterCount(FilterCandidates.ScoreGreaterThan80)}
                  </span>
                  80% Match
                </Button>  */}
                <Button
                  disabled={handleFilterCount(FilterCandidates.Qualified) === 0}
                  color="secondary"
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    height: "34px",
                    padding: "6px 16px 6px 9px",
                    boxShadow: "none",
                    backgroundColor:
                      filterCandidates === FilterCandidates.Qualified
                        ? theme.palette.secondary.main
                        : "#F2F2F2",
                    color:
                      filterCandidates === FilterCandidates.Qualified
                        ? "#fff"
                        : theme.palette.text.secondary,
                    "&:hover": {
                      color:
                        filterCandidates === FilterCandidates.Qualified
                          ? "#fff"
                          : theme.palette.text.secondary,
                      boxShadow: "none",
                      backgroundColor:
                        filterCandidates === FilterCandidates.Qualified
                          ? "#8A00CC"
                          : "#E6E6E6",
                    },
                    fontWeight:
                      filterCandidates === FilterCandidates.Qualified
                        ? 500
                        : 400,
                  }}
                  onClick={() => handleFilterChange(FilterCandidates.Qualified)}
                >
                  {" "}
                  <span
                    style={{
                      backgroundColor:
                        filterCandidates === FilterCandidates.Qualified
                          ? "#FFF"
                          : "#E6E6E6",
                      color:
                        filterCandidates === FilterCandidates.Qualified
                          ? theme.palette.secondary.main
                          : "#8F8F8F",
                      padding: "0 7px",
                      borderRadius: "7px",
                      display: "flex",
                      height: "16px",
                      alignItems: "center",
                      marginRight: "8px",
                      fontWeight:
                        filterCandidates === FilterCandidates.Qualified
                          ? 500
                          : 400,
                    }}
                  >
                    {handleFilterCount(FilterCandidates.Qualified)}
                  </span>{" "}
                  Qualified
                </Button>
                <Button
                  disabled={
                    handleFilterCount(FilterCandidates.NeedsReview) === 0
                  }
                  color="secondary"
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    height: "34px",
                    padding: "6px 16px 6px 9px",
                    backgroundColor:
                      filterCandidates === FilterCandidates.NeedsReview
                        ? theme.palette.secondary.main
                        : "#F2F2F2",
                    color:
                      filterCandidates === FilterCandidates.NeedsReview
                        ? "#fff"
                        : theme.palette.text.secondary,
                    "&:hover": {
                      color:
                        filterCandidates === FilterCandidates.NeedsReview
                          ? "#fff"
                          : theme.palette.text.secondary,
                      boxShadow: "none",
                      backgroundColor:
                        filterCandidates === FilterCandidates.NeedsReview
                          ? "#8A00CC"
                          : "#E6E6E6",
                    },
                    fontWeight:
                      filterCandidates === FilterCandidates.NeedsReview
                        ? 500
                        : 400,
                  }}
                  onClick={() =>
                    handleFilterChange(FilterCandidates.NeedsReview)
                  }
                >
                  {" "}
                  <span
                    style={{
                      backgroundColor:
                        filterCandidates === FilterCandidates.NeedsReview
                          ? "#FFF"
                          : "#E6E6E6",
                      color:
                        filterCandidates === FilterCandidates.NeedsReview
                          ? theme.palette.secondary.main
                          : "#8F8F8F",
                      padding: "0 7px",
                      borderRadius: "7px",
                      display: "flex",
                      height: "16px",
                      alignItems: "center",
                      marginRight: "8px",
                      fontWeight:
                        filterCandidates === FilterCandidates.NeedsReview
                          ? 500
                          : 400,
                    }}
                  >
                    {handleFilterCount(FilterCandidates.NeedsReview)}
                  </span>{" "}
                  Needs Review
                </Button>
              </Grid>
              <Grid
                item
                flexWrap="wrap"
                sx={{
                  display: "flex",
                  gap: "8px",
                  marginLeft: {
                    md: "auto",
                    sm: 0,
                    xs: 0,
                    lg: "auto",
                  },
                  justifyContent: {
                    md: "flex-end",
                    sm: "flex-start",
                    xs: "space-between",
                    lg: "flex-end",
                  },
                }}
              >
                <div>
                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    sx={{
                      backgroundColor: "#F0EAF2",
                      color: "#181818",
                      "&:hover": {
                        backgroundColor: "#D3B7E0",
                      },
                      maxWidth: "178px",
                      width: "100%",
                      borderRadius: "8px",
                    }}
                    onClick={handleClickSelectAction}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    Actions
                  </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseAction}
                  >
                    {/* <MenuItem
                      sx={{ color: "#000000", justifyContent: "center" }}
                      onClick={handleCloseAction}
                      disableRipple
                    >
                      Quick Review
                    </MenuItem> */}
                    {newCandidates.length > 0 && (
                      <MenuItem
                        sx={{ color: "#000000", justifyContent: "center" }}
                        onClick={() => {
                          handleOpenAcceptAllModal();
                          handleCloseAction();
                        }}
                        disableRipple
                      >
                        Approve All{" "}
                        {filterCandidates === 1
                          ? handleFilterCount(FilterCandidates.All)
                          : `${filterCandidates}% (${handleFilterCount(
                              filterCandidates
                            )})`}
                      </MenuItem>
                    )}
                    {newCandidates.length > 0 && (
                      <MenuItem
                        sx={{
                          justifyContent: "center",
                          textDecoration: "none",
                          "& .MuiButtonBase-root ": {
                            textDecoration: "none",
                          },
                        }}
                        onClick={handleCloseAction}
                        disableRipple
                      >
                        <CSVLink
                          data={[
                            ...newCandidates.map((candidate: Candidate) => {
                              return {
                                ...candidate,
                                educations: candidate.educations.join("; "),
                                skills: candidate.skills.join("; "),
                                experiences: candidate.experiences.join("; "),
                                certifications:
                                  candidate.certifications.join("; "),
                              };
                            }),
                          ]}
                          headers={CSVHeadersList}
                          filename={"New Candidates list"}
                        >
                          <Typography sx={{ color: "#000000" }}>
                            Download CSV{" "}
                            {/* {filterCandidates === 1
                              ? "(All)"
                              : `(${filterCandidates}% Match)`} */}
                          </Typography>
                        </CSVLink>
                      </MenuItem>
                    )}
                  </StyledMenu>
                </div>
              </Grid>
            </Grid>
          )}

          {newCandidates.length > 0 && (
            <Grid
              item
              sx={{
                marginLeft: {
                  md: "auto",
                  sm: 0,
                  xs: 0,
                  lg: "auto",
                },
                display: "flex",
                alignItems: "center",
                flexWrap: {
                  md: "nowrap",
                  sm: "nowrap",
                  xs: "wrap",
                  lg: "nowrap",
                },
                paddingRight: "8px",
              }}
            >
              {" "}
              <Typography
                sx={{
                  minWidth: "60px",
                  padding: "4px 0 5px ",
                  color: "#34004D",
                }}
              >
                Sort By:
              </Typography>
              <FormControl
                fullWidth
                variant="standard"
                sx={{ maxWidth: "180px" }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sortByLastest}
                  onChange={handleSortByDateChange}
                  disableUnderline
                >
                  <MenuItem value={SortByDateCandidate.Lastest}>
                    Date Added: Latest
                  </MenuItem>
                  <MenuItem value={SortByDateCandidate.Oldest}>
                    Date Added: Oldest
                  </MenuItem>
                </Select>
              </FormControl>
              {/* <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disableElevation
                sx={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "#8F8F8F",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#181818",
                  },
                }}
                onClick={handleClickFilter}
                endIcon={<FilterIcon />}
              >
                Filter
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorElFilter}
                open={openFilterSelect}
                onClose={handleCloseFilter}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "self-start",
                  color: "#000000",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <MenuItem
                    sx={{ color: "#000000", fontSize: "0.875rem" }}
                    onClick={toggleVisibilityQualified}
                    disableRipple
                  >
                    Qualified
                  </MenuItem>
                  <span style={{ paddingRight: "10px" }}>
                    {!isVisibleQualified ? <ShowIcon /> : <HideIcon />}
                  </span>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <MenuItem
                    sx={{ color: "#000000", fontSize: "0.875rem" }}
                    onClick={toggleVisibilityReview}
                    disableRipple
                  >
                    Needs Review
                  </MenuItem>
                  <span style={{ paddingRight: "10px" }}>
                    {!isVisibleReview ? <ShowIcon /> : <HideIcon />}
                  </span>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <MenuItem
                    sx={{ color: "#000000", fontSize: "0.875rem" }}
                    onClick={toggleVisibilityNotes}
                    disableRipple
                  >
                    Notes
                  </MenuItem>
                  <span style={{ paddingRight: "10px" }}>
                    {!isVisibleNotes ? <ShowIcon /> : <HideIcon />}
                  </span>
                </Box>
              </StyledMenu> */}
            </Grid>
          )}

          <Grid container sx={{ display: "flex", gap: " 10px" }}>
            {newCandidates.map((candidate, index) => {
              return (
                <Grid item xs={12} sm={5.5} md={5.5} lg={12} key={index}>
                  <CandidateCard
                    key={index + "candidate card"}
                    candidate={candidate}
                    isVisibleQualified={isVisibleQualified}
                    selectedTab={ReviewedCandidates.Qualified}
                    isConfirmationPopupOpen={isConfirmationPopupOpen}
                    isRejectCandidatesModalOpen={isRejectModalOpen}
                    handleOpenReviewModal={handleOpenReviewModal}
                    handleButtonGroupChoose={handleButtonGroupChoose}
                    handleDisqualifyUserFromModal={
                      handleDisqualifyUserFromModal
                    }
                    handleUpdateChangesDisqualifyModal={
                      handleUpdateChangesDisqualifyModal
                    }
                    handleAccept={handleAccept}
                    handleReject={handleReject}
                    handleResetProgres={handleResetProgres}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
      {/* {selectedTab === ReviewedCandidates.Quick_Review && (
        <Stack
          sx={{
            gap: 1,
            display: "flex",
            flexDirection: "column",
            marginX: "auto",
            maxWidth: "1696px",
            paddingX: "24px",
            alignItems: "center",
            paddingBottom: "24px",
            paddingTop: "16px",
          }}
        >
          <Box
            sx={{
              maxWidth: "400px",
              maxHeight: "400px",
            }}
          >
            <img
              src={NoQuickReviewQualifiedCandidates}
              alt="No new candidates placeholder"
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
          <Typography variant="h5">
            You have no candidates to review.
          </Typography>
          <Typography variant="body1" fontSize="14px">
            Zero candidates, 100% opportunity to chill. Enjoy this break! Check
            in a few days to review new matches.
          </Typography>
          <Typography variant="body1" marginTop={2} fontSize="14px">
            Check in a few days to review new matches.
          </Typography>
        </Stack>
      )} */}
      {selectedTab === ReviewedCandidates.Qualified &&
        newCandidates.length === 0 && (
          <Stack
            sx={{
              gap: 1,
              display: "flex",
              flexDirection: "column",
              marginX: "auto",
              maxWidth: "1696px",
              paddingX: "24px",
              alignItems: "center",
              paddingBottom: "24px",
            }}
          >
            <Box
              sx={{
                maxWidth: "400px",
                maxHeight: "400px",
              }}
            >
              <img
                src={NoQuickReviewQualifiedCandidates}
                alt="No new candidates placeholder"
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
            <Typography variant="h5">You have no new candidate.</Typography>
            <Typography variant="body1" fontSize="14px">
              Zero candidates, 100% opportunity to chill. Enjoy this break!
            </Typography>
            <Typography variant="body1" marginTop={2} fontSize="14px">
              Check in a few days to review new matches.
            </Typography>
          </Stack>
        )}
      {selectedTab === ReviewedCandidates.Reviewed && (
        <Grid
          container
          sx={{
            gap: 1,
            display: "flex",
            flexDirection: "column",
            marginX: "auto",
            maxWidth: "1696px",
            padding: {
              xl: "0 16px 0 32px",
              lg: " 0 16px 0 40px ",
              md: " 0 16px 0 40px",
              sm: " 0 16px 0 40px",
              xs: " 0 16px 0 40px",
            },
          }}
        >
          {rewievedCandidates.length > 0 && (
            <Grid container>
              <Grid
                item
                sx={{
                  display: "flex",
                  gap: 1,
                  marginBottom: "24px",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={handleFilterCount(FilterCandidates.All) === 0}
                  sx={{
                    borderRadius: "10px",
                    height: "34px",
                    padding: "6px 16px 6px 9px",
                    backgroundColor:
                      filterCandidates === FilterCandidates.All
                        ? theme.palette.secondary.main
                        : "#F2F2F2",
                    color:
                      filterCandidates === FilterCandidates.All
                        ? "#fff"
                        : theme.palette.text.secondary,
                    "&:hover": {
                      color:
                        filterCandidates === FilterCandidates.All
                          ? "#fff"
                          : theme.palette.text.secondary,
                      boxShadow: "none",
                      backgroundColor:
                        filterCandidates === FilterCandidates.All
                          ? "#8A00CC"
                          : "#E6E6E6",
                    },
                  }}
                  onClick={() => handleFilterChange(FilterCandidates.All)}
                >
                  {" "}
                  <span
                    style={{
                      backgroundColor:
                        filterCandidates === FilterCandidates.All
                          ? "#FFF"
                          : "#E6E6E6",
                      color:
                        filterCandidates === FilterCandidates.All
                          ? theme.palette.secondary.main
                          : "#8F8F8F",
                      padding: "0 7px",
                      borderRadius: "7px",
                      display: "flex",
                      height: "16px",
                      alignItems: "center",
                      marginRight: "8px",
                      fontWeight:
                        filterCandidates === FilterCandidates.All ? 600 : 400,
                    }}
                  >
                    {handleFilterCount(FilterCandidates.All)}
                  </span>{" "}
                  All
                </Button>
                {/* <Button
                  color="secondary"
                  disabled={
                    handleFilterCount(FilterCandidates.ScoreGreaterThan90) === 0
                  }
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    height: "34px",
                    padding: "6px 16px 6px 9px",
                    backgroundColor:
                      filterCandidates === FilterCandidates.ScoreGreaterThan90
                        ? theme.palette.secondary.main
                        : theme.palette.text.disabled,
                    color:
                      filterCandidates === FilterCandidates.ScoreGreaterThan90
                        ? "#fff"
                        : theme.palette.text.secondary,
                    "&:hover": {
                      color:
                        filterCandidates === FilterCandidates.ScoreGreaterThan90
                          ? "#fff"
                          : theme.palette.text.secondary,
                      boxShadow: "none",
                      backgroundColor:
                        filterCandidates === FilterCandidates.ScoreGreaterThan90
                          ? "#8A00CC"
                          : "#F2F2F2",
                    },
                  }}
                  onClick={() =>
                    handleFilterChange(FilterCandidates.ScoreGreaterThan90)
                  }
                >
                  <span
                    style={{
                      backgroundColor: "#fff",
                      color:
                        filterCandidates === FilterCandidates.ScoreGreaterThan90
                          ? theme.palette.secondary.main
                          : theme.palette.secondary.light,
                      padding: "0 7px",
                      borderRadius: "7px",
                      display: "flex",
                      height: "16px",
                      alignItems: "center",
                      marginRight: "8px",
                      fontWeight:
                        filterCandidates === FilterCandidates.ScoreGreaterThan90
                          ? 500
                          : 400,
                    }}
                  >
                    {handleFilterCount(FilterCandidates.ScoreGreaterThan90)}
                  </span>{" "}
                  90% Match
                </Button>
                <Button
                  color="secondary"
                  onClick={() =>
                    handleFilterChange(FilterCandidates.ScoreGreaterThan80)
                  }
                  disabled={
                    handleFilterCount(FilterCandidates.ScoreGreaterThan80) === 0
                  }
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    height: "34px",
                    padding: "6px 16px 6px 9px",
                    boxShadow: "none",
                    backgroundColor:
                      filterCandidates === FilterCandidates.ScoreGreaterThan80
                        ? theme.palette.secondary.main
                        : theme.palette.text.disabled,
                    color:
                      filterCandidates === FilterCandidates.ScoreGreaterThan80
                        ? "#fff"
                        : theme.palette.text.secondary,
                    "&:hover": {
                      color:
                        filterCandidates === FilterCandidates.ScoreGreaterThan80
                          ? "#fff"
                          : theme.palette.text.secondary,
                      boxShadow: "none",
                      backgroundColor:
                        filterCandidates === FilterCandidates.ScoreGreaterThan80
                          ? "#8A00CC"
                          : "#F2F2F2",
                    },
                  }}
                >
                  {" "}
                  <span
                    style={{
                      backgroundColor: "#fff",
                      color:
                        filterCandidates === FilterCandidates.ScoreGreaterThan80
                          ? theme.palette.secondary.main
                          : theme.palette.secondary.light,
                      padding: "0 7px",
                      borderRadius: "7px",
                      display: "flex",
                      height: "16px",
                      alignItems: "center",
                      marginRight: "8px",
                      fontWeight:
                        filterCandidates === FilterCandidates.ScoreGreaterThan80
                          ? 500
                          : 400,
                    }}
                  >
                    {handleFilterCount(FilterCandidates.ScoreGreaterThan80)}
                  </span>
                  80% Match
                </Button> */}
                <Button
                  disabled={handleFilterCount(FilterCandidates.Qualified) === 0}
                  color="secondary"
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    height: "34px",
                    padding: "6px 16px 6px 9px",
                    boxShadow: "none",
                    backgroundColor:
                      filterCandidates === FilterCandidates.Qualified
                        ? theme.palette.secondary.main
                        : "#F2F2F2",
                    color:
                      filterCandidates === FilterCandidates.Qualified
                        ? "#fff"
                        : theme.palette.text.secondary,
                    "&:hover": {
                      color:
                        filterCandidates === FilterCandidates.Qualified
                          ? "#fff"
                          : theme.palette.text.secondary,
                      boxShadow: "none",
                      backgroundColor:
                        filterCandidates === FilterCandidates.Qualified
                          ? "#8A00CC"
                          : "#E6E6E6",
                    },
                  }}
                  onClick={() => handleFilterChange(FilterCandidates.Qualified)}
                >
                  {" "}
                  <span
                    style={{
                      backgroundColor:
                        filterCandidates === FilterCandidates.Qualified
                          ? "#FFF"
                          : "#E6E6E6",
                      color:
                        filterCandidates === FilterCandidates.Qualified
                          ? theme.palette.secondary.main
                          : "#8F8F8F",
                      padding: "0 7px",
                      borderRadius: "7px",
                      display: "flex",
                      height: "16px",
                      alignItems: "center",
                      marginRight: "8px",
                      fontWeight:
                        filterCandidates === FilterCandidates.Qualified
                          ? 500
                          : 400,
                    }}
                  >
                    {handleFilterCount(FilterCandidates.Qualified)}
                  </span>{" "}
                  Qualified
                </Button>
                <Button
                  disabled={
                    handleFilterCount(FilterCandidates.NeedsReview) === 0
                  }
                  color="secondary"
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    height: "34px",
                    padding: "6px 16px 6px 9px",
                    boxShadow: "none",
                    backgroundColor:
                      filterCandidates === FilterCandidates.NeedsReview
                        ? theme.palette.secondary.main
                        : "#F2F2F2",
                    color:
                      filterCandidates === FilterCandidates.NeedsReview
                        ? "#fff"
                        : theme.palette.text.secondary,
                    "&:hover": {
                      color:
                        filterCandidates === FilterCandidates.NeedsReview
                          ? "#fff"
                          : theme.palette.text.secondary,
                      boxShadow: "none",
                      backgroundColor:
                        filterCandidates === FilterCandidates.NeedsReview
                          ? "#8A00CC"
                          : "#E6E6E6",
                    },
                  }}
                  onClick={() =>
                    handleFilterChange(FilterCandidates.NeedsReview)
                  }
                >
                  {" "}
                  <span
                    style={{
                      backgroundColor:
                        filterCandidates === FilterCandidates.NeedsReview
                          ? "#FFF"
                          : "#E6E6E6",
                      color:
                        filterCandidates === FilterCandidates.NeedsReview
                          ? theme.palette.secondary.main
                          : "#8F8F8F",
                      padding: "0 7px",
                      borderRadius: "7px",
                      display: "flex",
                      height: "16px",
                      alignItems: "center",
                      marginRight: "8px",
                      fontWeight:
                        filterCandidates === FilterCandidates.NeedsReview
                          ? 500
                          : 400,
                    }}
                  >
                    {handleFilterCount(FilterCandidates.NeedsReview)}
                  </span>{" "}
                  Needs Review
                </Button>
                <Button
                  disabled={
                    handleFilterCount(FilterCandidates.ApprovedCandidates) === 0
                  }
                  color="secondary"
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    height: "34px",
                    padding: "6px 16px 6px 9px",
                    backgroundColor:
                      filterCandidates === FilterCandidates.ApprovedCandidates
                        ? theme.palette.secondary.main
                        : "#F2F2F2",
                    color:
                      filterCandidates === FilterCandidates.ApprovedCandidates
                        ? "#fff"
                        : theme.palette.text.secondary,
                    "&:hover": {
                      color:
                        filterCandidates === FilterCandidates.ApprovedCandidates
                          ? "#fff"
                          : theme.palette.text.secondary,
                      boxShadow: "none",
                      backgroundColor:
                        filterCandidates === FilterCandidates.ApprovedCandidates
                          ? "#8A00CC"
                          : "#E6E6E6",
                    },
                  }}
                  onClick={() =>
                    handleFilterChange(FilterCandidates.ApprovedCandidates)
                  }
                >
                  {" "}
                  <span
                    style={{
                      backgroundColor:
                        filterCandidates === FilterCandidates.ApprovedCandidates
                          ? "#FFF"
                          : "#E6E6E6",
                      color:
                        filterCandidates === FilterCandidates.ApprovedCandidates
                          ? theme.palette.secondary.main
                          : "#8F8F8F",
                      padding: "0 7px",
                      borderRadius: "7px",
                      display: "flex",
                      height: "16px",
                      alignItems: "center",
                      marginRight: "8px",
                      fontWeight:
                        filterCandidates === FilterCandidates.ApprovedCandidates
                          ? 600
                          : 400,
                    }}
                  >
                    {handleFilterCount(FilterCandidates.ApprovedCandidates)}
                  </span>{" "}
                  Approved
                </Button>
                <Button
                  disabled={
                    handleFilterCount(FilterCandidates.RejectedCandidates) === 0
                  }
                  color="secondary"
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    height: "34px",
                    padding: "6px 16px 6px 9px",
                    backgroundColor:
                      filterCandidates === FilterCandidates.RejectedCandidates
                        ? theme.palette.secondary.main
                        : "#F2F2F2",
                    color:
                      filterCandidates === FilterCandidates.RejectedCandidates
                        ? "#fff"
                        : theme.palette.text.secondary,
                    "&:hover": {
                      color:
                        filterCandidates === FilterCandidates.RejectedCandidates
                          ? "#fff"
                          : theme.palette.text.secondary,
                      boxShadow: "none",
                      backgroundColor:
                        filterCandidates === FilterCandidates.RejectedCandidates
                          ? "#8A00CC"
                          : "#E6E6E6",
                    },
                  }}
                  onClick={() =>
                    handleFilterChange(FilterCandidates.RejectedCandidates)
                  }
                >
                  {" "}
                  <span
                    style={{
                      backgroundColor:
                        filterCandidates === FilterCandidates.RejectedCandidates
                          ? "#FFF"
                          : "#E6E6E6",
                      color:
                        filterCandidates === FilterCandidates.RejectedCandidates
                          ? theme.palette.secondary.main
                          : "#8F8F8F",
                      padding: "0 7px",
                      borderRadius: "7px",
                      display: "flex",
                      height: "16px",
                      alignItems: "center",
                      marginRight: "8px",
                      fontWeight:
                        filterCandidates === FilterCandidates.RejectedCandidates
                          ? 500
                          : 400,
                    }}
                  >
                    {handleFilterCount(FilterCandidates.RejectedCandidates)}
                  </span>{" "}
                  Rejected
                </Button>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  gap: "8px",
                  marginLeft: {
                    md: "auto",
                    sm: "20px",
                    xs: 0,
                    lg: "auto",
                  },
                  justifyContent: {
                    md: "flex-end",
                    sm: "flex-start",
                    xs: "space-between",
                    lg: "flex-end",
                  },
                }}
              >
                {rewievedCandidates.length > 0 && (
                  <Button
                    color="secondary"
                    sx={{
                      marginLeft: { sm: "auto" },
                      borderRadius: "8px",
                      maxWidth: "300px",
                      color: "#fff",
                      boxShadow: "none",
                      maxHeight: "34px",
                      backgroundColor: theme.palette.secondary.main,
                      "&:hover": {
                        backgroundColor: "#CE66FF",
                        boxShadow: "none",
                      },
                    }}
                    variant="contained"
                  >
                    <CSVLink
                      data={[
                        ...rewievedCandidates.map((candidate: Candidate) => {
                          return {
                            ...candidate,
                            educations: candidate.educations.join("; "),
                            skills: candidate.skills.join("; "),
                            experiences: candidate.experiences.join("; "),
                            certifications: candidate.certifications.join("; "),
                          };
                        }),
                      ]}
                      headers={CSVHeadersList}
                      filename={"Reviewed Candidates list"}
                    >
                      <Typography sx={{ color: "#FFF" }}>
                        Download CSV{" "}
                        {/* {filterCandidates === 1
                          ? "(All)"
                          : filterCandidates ===
                              FilterCandidates.ApprovedCandidates ||
                            filterCandidates ===
                              FilterCandidates.RejectedCandidates
                          ? `(${filterCandidates})`
                          : `(Qualified)`} */}
                      </Typography>
                    </CSVLink>
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
          {rewievedCandidates.length > 0 && (
            <Grid
              item
              sx={{
                marginLeft: {
                  md: "auto",
                  sm: 0,
                  xs: 0,
                  lg: "auto",
                },
                display: "flex",
                alignItems: "center",
                flexWrap: {
                  md: "nowrap",
                  sm: "nowrap",
                  xs: "wrap",
                  lg: "nowrap",
                },
                paddingRight: "8px",
              }}
            >
              {" "}
              <Typography
                sx={{
                  minWidth: "60px",
                  padding: "4px 0 5px",
                  color: "#34004D",
                }}
              >
                Sort By:
              </Typography>{" "}
              <FormControl
                fullWidth
                variant="standard"
                sx={{ maxWidth: "180px" }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sortByLastest}
                  onChange={handleSortByDateChange}
                  disableUnderline
                >
                  <MenuItem value={SortByDateCandidate.Lastest}>
                    Date Added: Latest
                  </MenuItem>
                  <MenuItem value={SortByDateCandidate.Oldest}>
                    Date Added: Oldest
                  </MenuItem>
                </Select>
              </FormControl>
              {/* <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disableElevation
                sx={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "#8F8F8F",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#181818",
                  },
                }}
                onClick={handleClickFilter}
                endIcon={<FilterIcon />}
              >
                Filter
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorElFilter}
                open={openFilterSelect}
                onClose={handleCloseFilter}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "self-start",
                  color: "#000000",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <MenuItem
                    sx={{ color: "#000000", fontSize: "0.875rem" }}
                    onClick={toggleVisibilityQualified}
                    disableRipple
                  >
                    Qualified
                  </MenuItem>
                  <span style={{ paddingRight: "10px" }}>
                    {!isVisibleQualified ? <ShowIcon /> : <HideIcon />}
                  </span>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <MenuItem
                    sx={{ color: "#000000", fontSize: "0.875rem" }}
                    onClick={toggleVisibilityReview}
                    disableRipple
                  >
                    Needs Review
                  </MenuItem>
                  <span style={{ paddingRight: "10px" }}>
                    {!isVisibleReview ? <ShowIcon /> : <HideIcon />}
                  </span>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <MenuItem
                    sx={{ color: "#000000", fontSize: "0.875rem" }}
                    onClick={toggleVisibilityNotes}
                    disableRipple
                  >
                    Notes
                  </MenuItem>
                  <span style={{ paddingRight: "10px" }}>
                    {!isVisibleNotes ? <ShowIcon /> : <HideIcon />}
                  </span>
                </Box>
              </StyledMenu> */}
            </Grid>
          )}
          <Grid
            container
            sx={{
              display: "flex",
              gap: " 10px",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {rewievedCandidates.map((candidate, index) => {
              return (
                <Grid item xs={12} sm={5.5} md={5.5} lg={12} key={index}>
                  <CandidateCard
                    key={index + "candidate card"}
                    candidate={candidate}
                    isVisibleQualified={isVisibleQualified}
                    selectedTab={ReviewedCandidates.Reviewed}
                    isConfirmationPopupOpen={isConfirmationPopupOpen}
                    isRejectCandidatesModalOpen={isRejectModalOpen}
                    handleAccept={handleAccept}
                    handleReject={handleReject}
                    handleOpenReviewModal={handleOpenReviewModal}
                    handleButtonGroupChoose={handleButtonGroupChoose}
                    handleDisqualifyUserFromModal={
                      handleDisqualifyUserFromModal
                    }
                    handleUpdateChangesDisqualifyModal={
                      handleUpdateChangesDisqualifyModal
                    }
                    handleResetProgres={handleResetProgres}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
      {selectedTab === ReviewedCandidates.Reviewed &&
        rewievedCandidates.length === 0 && (
          <Stack
            sx={{
              gap: 1,
              display: "flex",
              flexDirection: "column",
              marginX: "auto",
              maxWidth: "1696px",
              paddingX: "24px",
              alignItems: "center",
              paddingBottom: "24px",
            }}
          >
            <Box
              sx={{
                maxWidth: "186px",
                maxHeight: "155px",
                marginTop: "111px",
                marginBottom: "80px",
              }}
            >
              <img
                src={NoReviewedCandidates}
                alt="No reviewed candidates placeholder"
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
            <Typography variant="h5">
              You do not have reviewed candidates.
            </Typography>
            <Typography variant="body1" fontSize="14px">
              Nothing here? That means you have not reviewed candidates.
            </Typography>
          </Stack>
        )}
    </Grid>
  );
};

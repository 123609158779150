import React from "react";
import styles from "./confirmationModal.module.scss";
import ReactDOM from "react-dom";
import CloseIcon from "@mui/icons-material/Close";

export interface ModalProps {
  setOpenModal: (value: boolean) => void;
  handleConfirm: () => void;
  title?: string;
  body?: string;
  confirmLabel: string;
  cancelLabel: string;
}
const overlayElement = document.createElement("div");
overlayElement.setAttribute("id", "overlays");
document.body.prepend(overlayElement);

const portalElement: any = document.getElementById("overlays");

export const RejectModal = ({
  setOpenModal,
  handleConfirm,
  title,
  body,
  confirmLabel,
  cancelLabel,
}: ModalProps): JSX.Element => {
  return (
    <>
      {ReactDOM.createPortal(
        <>
          <div className={styles["modal-backdrop"]}>
            <div className={styles["modal-container"]}>
              <div className={styles["title-close-btn"]}>
                <button
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  <CloseIcon fontSize={"small"} />
                </button>
              </div>
              <div className={styles.title}>
                <span>{title}</span>
              </div>
              <div className={styles.body}>
                <span>{body}</span>
              </div>
              <div className={styles.footer}>
                <button
                  onClick={() => {
                    setOpenModal(false);
                  }}
                  className={styles["cancel-button"]}
                >
                  {cancelLabel}
                </button>
                <button
                  className={styles["confirm-button"]}
                  onClick={handleConfirm}
                >
                  {confirmLabel}
                </button>
              </div>
            </div>
          </div>
        </>,
        portalElement
      )}
    </>
  );
};

export default RejectModal;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  ActiveStep,
  IdealProfile,
  IdealProfilesData,
} from "../../enums/addRole";
import AddIcon from "@mui/icons-material/Add";
import { IdealProfiles } from "../../utilities/roles";

export interface SimularProfilesProps {
  isOverviewTab: boolean;
  idealProfilesData?: IdealProfiles[];
  changeStep: (step: ActiveStep, data: IdealProfilesData) => void;
  onChangeData: (status: boolean) => void;
  handleUpdateSimilarProfiles: (data: IdealProfiles[]) => void;
  handleCancel: () => void;
}

export const SimularProfiles = ({
  isOverviewTab,
  idealProfilesData,
  changeStep,
  onChangeData,
  handleUpdateSimilarProfiles,
  handleCancel,
}: SimularProfilesProps) => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState<number | null>(null);
  const [isChanged, setIsChanged] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [urlUserLinkendin, setUrlLinekndin] = useState<any[]>(
    idealProfilesData
      ? [
          ...idealProfilesData.map((profile: IdealProfiles) => {
            return { type: "text", value: profile.linkedInUrl };
          }),
        ]
      : [{ type: "text", value: "" }]
  );

  const [comments, setComment] = useState(
    idealProfilesData
      ? [
          ...idealProfilesData.map((profile: IdealProfiles) => {
            return { type: "text", value: profile.comments };
          }),
        ]
      : [{ type: "text", value: "" }]
  );
  const [dataForRequest, setDataForRequest] = useState<IdealProfiles[]>([]);
  const addNewProfile = () => {
    if (setUrlLinekndin.length < 3) {
      setUrlLinekndin([...urlUserLinkendin, { type: "text", value: "" }]);
      setComment([...comments, { type: "text", value: "" }]);
    }
  };

  const handleUrlChange = (index: number, event: any) => {
    const newUrlLinkendim = [...urlUserLinkendin];
    if (event.target.value !== newUrlLinkendim[index].value) {
      setIsChanged(true);
      onChangeData(true);
    }
    newUrlLinkendim[index].value = event.target.value || "";
    setUrlLinekndin(newUrlLinkendim);
  };
  const handleCommentChange = (index: number, event: any) => {
    const newComments = [...comments];
    if (event.target.value !== newComments[index].value) {
      setIsChanged(true);
      onChangeData(true);
    }
    newComments[index].value = event.target.value || "";
    setComment(newComments);
  };
  const handleGoToNextStep = () => {
    const listOfUsers = urlUserLinkendin.map((data, index) => {
      return {
        linkendinProfile: data.value,
        comments: comments[index].value,
      };
    });
    changeStep(ActiveStep.Other, { simularProfiles: listOfUsers });
  };

  const openModal = (index: number) => {
    setOpen(true);
    setIndex(index + 1);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteIdealProfile = (index: number | null): void => {
    if (index !== null) {
      const i = index - 1;

      const linkedInData = [...urlUserLinkendin];
      const commentsData = [...comments];

      linkedInData.splice(i, 1);
      commentsData.splice(i, 1);
      setUrlLinekndin(linkedInData);
      setComment(commentsData);

      handleClose();
    }
  };

  useEffect(() => {
    if (urlUserLinkendin && comments) {
      const dataForRequest = urlUserLinkendin.map(
        (link: any, index: number) => ({
          linkedInProfileid: "",
          linkedInUrl: link.value || "",
          comments: comments[index]?.value || "",
        })
      );

      setDataForRequest(dataForRequest);
    }
    urlUserLinkendin.forEach((url: any) => {
      if (urlUserLinkendin.length === 1 && url.value === "") {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    });
  }, [urlUserLinkendin, comments]);

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingBottom: "24px",
        paddingLeft: "8px",
      }}
    >
      {!isOverviewTab && (
        <>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 700,
            }}
          >
            Do you want to discover similar profiles?
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "96px",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 400,
                color: "#8F8F8F",
                lineHeight: "24px",
              }}
            >
              {" "}
              Share up to 3 ideal candidates with us and we'll suggest
              candidates who closely match the profile.{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 400,
                color: "#8F8F8F",
                lineHeight: "24px",
              }}
            >
              Don`t have time right now? You can add an ideal candidate later!
            </Typography>
          </Box>
        </>
      )}
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          paddingBottom: "16px",

          maxHeight: {
            xs: "calc(100vh - 303px)",
            xl: "576px",
          },
          "@media (min-height: 864px)": {
            height: "576px",
          },
          overflowY: "auto",
        }}
      >
        {urlUserLinkendin.map((input, index) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: "16px",
              }}
              key={index + 12}
            >
              <Typography
                sx={{ color: "#181818", fontWeight: 700, paddingBottom: "8px" }}
              >
                Ideal Profile
              </Typography>
              <TextField
                autoComplete="off"
                sx={{
                  borderRadius: "20px",
                  color: "#212121",
                  "& label.Mui-focused": {
                    color: "#212121",
                    fontSize: "14px",
                  },
                  "& .MuiInputBase-input": {
                    padding: urlUserLinkendin[index].value
                      ? "25px 12px 8px 12px"
                      : "16.5px",
                    fontSize: "1rem",
                  },
                }}
                id="filled-multiline-static"
                label={!urlUserLinkendin[index].value ? "" : "LinkedIn Profile"}
                placeholder={`LinkedIn Profile`}
                variant="filled"
                value={urlUserLinkendin[index].value}
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => handleUrlChange(index, event)}
              />
              <Typography
                sx={{
                  padding: "8px 0px",
                  fontWeight: 500,
                  fontSize: "0.875rem",
                  lineHeight: "18px",
                }}
              >
                What`s exceptional about this profile?
                {!isOverviewTab && <span style={{ color: "#DC362E" }}>*</span>}
              </Typography>
              <TextField
                autoComplete="off"
                sx={{
                  borderRadius: "20px",
                  color: "#212121",
                  "& label.Mui-focused": {
                    color: "#212121",
                    fontSize: "14px",
                  },
                  "& .MuiInputBase-input": {
                    padding: comments[index].value
                      ? "25px 12px 8px 12px"
                      : "16.5px",
                    fontSize: "1rem",
                  },
                }}
                id="filled-multiline-static"
                label={!comments[index].value ? "" : "Comments"}
                placeholder={`Comments`}
                maxRows={4}
                variant="filled"
                value={comments[index].value}
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => handleCommentChange(index, event)}
              />
              {isOverviewTab && (
                <>
                  <Box sx={{ display: "flex", justifyContent: "start" }}>
                    <Button
                      variant="text"
                      sx={{
                        color: "#DC362E",
                        fontSize: "0.875rem",
                        lineHeight: "18px",
                      }}
                      onClick={() => openModal(index)}
                    >
                      Delete Ideal Profile
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          );
        })}
        <Button
          variant="contained"
          color="secondary"
          sx={{
            maxWidth: "176px",
            fontSize: "14px",
            height: "26px",
            color: "#fff",
            lineHeight: "18px",
            justifyContent: "space-between",
            margin: urlUserLinkendin.length === 0 ? "auto auto 0" : "unset",
          }}
          onClick={addNewProfile}
          disabled={urlUserLinkendin.length === 3}
        >
          <AddIcon sx={{ fontSize: "1rem", fontWeight: "bold" }} /> Add Another
          Profile
        </Button>
        <Typography
          sx={{
            fontSize: "0.875rem",
            lineHeight: "18px",
            fontWeight: "400",
            color: "#8F8F8F",
            textAlign: urlUserLinkendin.length === 0 ? "center" : "unset",
          }}
        >
          3 profiles max
        </Typography>
      </Grid>
      {isOverviewTab && (
        <>
          <Box
            sx={{
              backgroundColor: "transparent",
              zIndex: "5",
              position: "sticky",
              bottom: 0,
            }}
          >
            <Divider
              sx={{ color: "D9D9D9", marginX: "-16px", marginTop: "16px" }}
            ></Divider>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "20px 16px 0 0",
              }}
            >
              <Button
                sx={{
                  borderRadius: "10px",
                  color: "#181818",
                  border: "1px solid #ABABAB",
                  padding: "8px 16px",
                  maxHeight: "40px",
                  maxWidth: "82px",
                  marginRight: "16px",
                }}
                onClick={handleCancel}
                variant="text"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={isButtonDisabled}
                sx={{
                  color: "#fff",
                  borderRadius: "10px",
                  padding: "8px 16px",
                  maxHeight: "40px",
                  maxWidth: "136px",
                  "&:hover": {
                    backgroundColor: "#CE66FF",
                    boxShadow: "none",
                  },
                }}
                onClick={() => {
                  handleCancel();
                  handleUpdateSimilarProfiles(dataForRequest);
                }}
              >
                Save changes
              </Button>
            </Box>
          </Box>
        </>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "552px",
              borderRadius: "16px",
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.25rem",
                fontWeight: "500",
                lineHeight: "30px",
                padding: "8px 0px",
                color: "#000000",
              }}
            >
              Are you sure you want to delete?
            </Typography>
            <CloseOutlinedIcon
              onClick={handleClose}
              sx={{
                width: "1.6rem",
                height: "1.6rem",
                position: "absolute",
                right: "16px",
                top: "24px",
              }}
            />
          </Box>
        </DialogTitle>
        <Divider></Divider>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "24px",
                color: "#000000",
                paddingTop: "4px",
              }}
            >
              We can't revive this ideal profile once it's gone!
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ paddingRight: "16px", paddingBottom: "16px" }}>
            <Button
              variant="text"
              sx={{ color: "#181818", padding: "5px 16px" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                padding: "5px 16px",
                backgroundColor: "#8A00CC",
                border: "1px solid #ABABAB",
                color: "#FFF",
                "&:hover": {
                  backgroundColor: "#CE66FF",
                  boxShadow: "none",
                },
              }}
              onClick={() => {
                handleClose();
                handleDeleteIdealProfile(index);
              }}
              autoFocus
            >
              Delete Ideal Profile #{index ? index : null}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      {!isOverviewTab && (
        <Box
          sx={{
            display: "flex",
            gap: "19px",
            width: "100%",
            justifyContent: "flex-end",
            paddingTop: 2,
          }}
        >
          <Button
            onClick={() => {
              handleGoToNextStep();
            }}
            variant="text"
            color="secondary"
            sx={{
              height: "40px",
              boxShadow: "none",
              textTransform: "none",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            Skip
          </Button>
          <Button
            onClick={() => {
              handleGoToNextStep();
            }}
            disabled={
              comments[0].value.length === 0 ||
              urlUserLinkendin[0].value.length === 0 ||
              !isChanged
            }
            variant="contained"
            color="secondary"
            sx={{
              height: "40px",
              boxShadow: "none",
              textTransform: "none",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#CE66FF",
                boxShadow: "none",
              },
            }}
          >
            Continue
          </Button>
        </Box>
      )}
    </Grid>
  );
};

import { Box, Button, List, ListItem, Modal, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { VectorStrokeIcon } from "../../utilities/icons";
import {
  bodyStyle,
  headerStyle,
  modalStyle,
} from "../../utilities/modalStyles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { JobDescription } from "../roles";
import { AddNewRoleStep } from "../../types";
import { RoleModalRequest } from "../../utilities/roles";
import {
  JobDescriptionList,
  JobDescriptionListItems,
  OverviewMenu,
} from "../../enums/nav-items";
import { RoleStatus } from "../../enums/addRole";
import { ReviewedCandidates } from "../../types/tab";
import { getProjectsData } from "../../utilities/actions";

export interface JobDescriptionScProps {
  activeRoleRequest: RoleModalRequest;
  file?: File | null;
  handleUpdateJobDescriptionData: (data: RoleModalRequest) => void;
  onNavigate: (data: OverviewMenu | ReviewedCandidates) => void;
}

const listItems: JobDescriptionListItems[] = [
  {
    id: 1,
    name: JobDescriptionList.General_Information,
  },
  {
    id: 2,
    name: JobDescriptionList.Job_Details,
  },
  {
    id: 3,
    name: JobDescriptionList.Qualifications,
  },
  {
    id: 4,
    name: JobDescriptionList.Job_Description,
  },
];

export const JobDescriptionSc = ({
  activeRoleRequest,
  file,
  handleUpdateJobDescriptionData,
  onNavigate,
}: JobDescriptionScProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [jobDescriptionData, setJobDescriptionData] =
    useState<RoleModalRequest>();
  const generalInformation = useRef<HTMLDivElement | null>(null);
  const jobDetails = useRef<HTMLDivElement | null>(null);
  const qualifications = useRef<HTMLDivElement | null>(null);
  const jobDescription = useRef<HTMLDivElement | null>(null);
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (activeRoleRequest.projectId) {
      getProject(activeRoleRequest.projectId);
    }
  }, [activeRoleRequest]);

  const handleListItemClick = (itemName: JobDescriptionListItems) => {
    let targetRef: React.RefObject<HTMLDivElement> | null = null;

    switch (itemName.name) {
      case JobDescriptionList.General_Information:
        targetRef = generalInformation;
        break;
      case JobDescriptionList.Job_Details:
        targetRef = jobDetails;
        break;
      case JobDescriptionList.Qualifications:
        targetRef = qualifications;
        break;
      case JobDescriptionList.Job_Description:
        targetRef = jobDescription;
        break;
      default:
        break;
    }

    if (targetRef && targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getProject = (projectId: string) => {
    const getData = async (): Promise<void> => {
      let results = await getProjectsData(projectId);
      if (results) {
        setJobDescriptionData(results);
      }
    };
    getData();
  };

  const handleSubmitFile = (file: File | null) => {};
  const handleCancel = () => {
    setModalIsOpen(false);
  };
  const handleUpdateJobDesc = (jobDesData: RoleModalRequest): void => {
    setJobDescriptionData(jobDesData);
    const dataReq: RoleModalRequest = {
      ...jobDesData,
      searchCriteria: activeRoleRequest.searchCriteria,
      idealCandidateProfiles: activeRoleRequest.idealCandidateProfiles,
    };
    handleUpdateJobDescriptionData(dataReq);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "24px 40px",
          width: "100%",
          position: "sticky",
          top: 0,
          backgroundColor: "#F7F7FF",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#181818",
              fontSize: "1.5rem",
            }}
          >
            Job Description
          </Typography>
        </Box>

        <Box>
          <Box sx={{ display: "flex", height: "40px", alignItems: "center" }}>
            <Button
              variant="text"
              disabled={activeRoleRequest.status === RoleStatus.Processing}
              sx={{
                borderRadius: "10px",
                width: "116px",
                padding: "5px 20px 5px 15px",
                transition: "none",
                fontSize: "1rem",
                color:
                  activeRoleRequest.status === RoleStatus.Processing
                    ? "#ABABAB"
                    : "#FFF",
                backgroundColor:
                  activeRoleRequest.status === RoleStatus.Processing
                    ? "#F7F7FF"
                    : "#9F14E0",
                "& .MuiSvgIcon-root": {
                  fontSize: "22px",
                  marginRight: "8px",
                  color: "#FFF",
                },
                "&:hover": {
                  backgroundColor: "#CE66FF",
                  boxShadow: "none",
                },
              }}
              onClick={openModal}
            >
              <VectorStrokeIcon status={activeRoleRequest.status} />
              <Typography sx={{ fontSize: "1rem" }}>Update</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100vw",
          position: "absolute",
          borderBottom: "1px solid #D9D9D9",
        }}
      ></Box>
      {activeRoleRequest.status === RoleStatus.Processing ? (
        <Box sx={{ padding: "24px 40px" }}>
          <Typography
            sx={{
              color: "#000",
              fontWeight: 400,
              fontSize: "1.25rem",
              paddingBottom: "8px",
            }}
          >
            We're processing your job description...
          </Typography>
          <Typography sx={{ fontSize: "0.875rem" }}>
            Whenever you're ready, feel free to{" "}
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => onNavigate(OverviewMenu.Search_Parameters)}
            >
              add search parameters
            </span>{" "}
            or{" "}
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => onNavigate(OverviewMenu.Ideal_Candidate_Profiles)}
            >
              similar profiles.
            </span>
          </Typography>
          {/* <Box sx={{ margin: "16px 0" }}>
            <Button
              sx={{
                fontSize: "1rem",
                height: "36px",
                maxWidth: "178px",
                padding: "8px 16px",
                borderRadius: "10px",
                backgroundColor: "#9F14E0",
                "&:hover": {
                  backgroundColor: "#CE66FF",
                  boxShadow: "none",
                },
                color: "#FFFFFF",
              }}
            >
              Choose Another File 
            </Button>
          </Box> */}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            overflow: "auto",
            height: "calc(100vh - 291px)",
          }}
        >
          <Box>
            <List sx={{ position: "sticky", top: "20px" }}>
              {listItems.map((item: JobDescriptionListItems) => (
                <ListItem
                  sx={{
                    fontSize: "0.875rem",
                    color: "#8F8F8F",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#9F14E0",
                    },
                  }}
                  key={item.id}
                  onClick={() => handleListItemClick(item)}
                >
                  {item.name}
                </ListItem>
              ))}
            </List>
          </Box>
          <Box
            sx={{
              paddingTop: "12px",
              color: "#000",
              fontWeight: 400,
              fontSize: "1.25rem",
              marginX: "auto",
            }}
          >
            <Typography
              ref={generalInformation}
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "1.25rem",
                margin: "24px 0px 8px 0px",
              }}
            >
              General Information
            </Typography>
            <Box
              sx={{
                width: "686px",
                backgroundColor: "#FFFFFF",
                borderRadius: "5px",
                padding: "24px",
              }}
            >
              <Typography
                sx={{
                  color: "#181818",
                  fontSize: "0.875rem",
                  lineHeight: "1rem",
                }}
              >
                Date Created
              </Typography>
              <Typography
                sx={{
                  color: "#7A7A7A",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  lineHeight: "24px",
                }}
              >
                January 1, 2023 (1 Month Ago){" "}
              </Typography>
            </Box>
            <Typography
              ref={jobDetails}
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "1.25rem",
                margin: "24px 0px 8px 0px",
              }}
            >
              Job Details
            </Typography>
            <Box
              sx={{
                width: "686px",
                backgroundColor: "#FFFFFF",
                borderRadius: "5px",
                padding: "24px",
              }}
            >
              <Box sx={{ marginBottom: "16px" }}>
                <Typography
                  sx={{
                    color: "#181818",
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "1rem",
                  }}
                >
                  Job Title
                </Typography>
                <Typography
                  sx={{
                    color: "#7A7A7A",
                    fontWeight: 400,
                    fontSize: "0.875rem",
                    lineHeight: "24px",
                  }}
                >
                  {jobDescriptionData?.title ? jobDescriptionData.title : ""}
                </Typography>
              </Box>
              <Box sx={{ marginBottom: "16px" }}>
                <Typography
                  sx={{
                    color: "#181818",
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "1rem",
                  }}
                >
                  Role Category
                </Typography>
                <Typography
                  sx={{
                    color: "#7A7A7A",
                    fontWeight: 400,
                    fontSize: "0.875rem",
                    lineHeight: "24px",
                  }}
                >
                  {jobDescriptionData?.role ? jobDescriptionData.role : ""}
                </Typography>
              </Box>
              <Box sx={{ marginBottom: "16px" }}>
                <Typography
                  sx={{
                    color: "#181818",
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "1rem",
                  }}
                >
                  Location
                </Typography>
                <Typography
                  sx={{
                    color: "#7A7A7A",
                    fontWeight: 400,
                    fontSize: "0.875rem",
                    lineHeight: "24px",
                  }}
                >
                  {jobDescriptionData?.location
                    ? jobDescriptionData.location
                    : ""}
                </Typography>
              </Box>
              <Box sx={{ marginBottom: "16px" }}>
                <Typography
                  sx={{
                    color: "#181818",
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "1rem",
                  }}
                >
                  Work Environment
                </Typography>
                <Typography
                  sx={{
                    color: "#7A7A7A",
                    fontWeight: 400,
                    fontSize: "0.875rem",
                    lineHeight: "24px",
                  }}
                >
                  {jobDescriptionData?.environment
                    ? jobDescriptionData.environment
                    : ""}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#181818",
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "1rem",
                  }}
                >
                  Work Type
                </Typography>
                <Typography
                  sx={{
                    color: "#7A7A7A",
                    fontWeight: 400,
                    fontSize: "0.875rem",
                    lineHeight: "24px",
                  }}
                >
                  {jobDescriptionData?.department
                    ? jobDescriptionData.department
                    : ""}
                </Typography>
              </Box>
            </Box>
            <Typography
              ref={qualifications}
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "1.25rem",
                margin: "24px 0px 8px 0px",
              }}
            >
              Qualifications
            </Typography>
            <Box
              sx={{
                width: "686px",
                backgroundColor: "#FFFFFF",
                borderRadius: "5px",
                padding: "24px",
              }}
            >
              <Box sx={{ marginBottom: "16px" }}>
                <Typography
                  sx={{
                    color: "#181818",
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "1rem",
                  }}
                >
                  Required Degree
                </Typography>
                <Typography
                  sx={{
                    color: "#7A7A7A",
                    fontWeight: 400,
                    fontSize: "0.875rem",
                    lineHeight: "24px",
                  }}
                >
                  {jobDescriptionData?.degree}
                </Typography>
              </Box>
              <Box sx={{ marginBottom: "16px" }}>
                <Typography
                  sx={{
                    color: "#181818",
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "1rem",
                  }}
                >
                  Min Years of Experience
                </Typography>
                <Typography
                  sx={{
                    color: "#7A7A7A",
                    fontWeight: 400,
                    fontSize: "0.875rem",
                    lineHeight: "24px",
                  }}
                >
                  {jobDescriptionData?.minYearsExperience
                    ? jobDescriptionData.minYearsExperience
                    : ""}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginBottom:
                    jobDescriptionData?.skills &&
                    jobDescriptionData.skills.length > 0
                      ? "16px"
                      : "unset",
                }}
              >
                <Typography
                  sx={{
                    color: "#181818",
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "1rem",
                  }}
                >
                  Max Years of Experience
                </Typography>
                <Typography
                  sx={{
                    color: "#7A7A7A",
                    fontWeight: 400,
                    fontSize: "0.875rem",
                    lineHeight: "24px",
                  }}
                >
                  {jobDescriptionData?.maxYearsExperience
                    ? jobDescriptionData.maxYearsExperience
                    : ""}
                </Typography>
              </Box>
              {jobDescriptionData?.skills &&
                jobDescriptionData.skills.length > 0 && (
                  <Box>
                    <Typography
                      sx={{
                        color: "#181818",
                        fontWeight: 500,
                        fontSize: "0.875rem",
                        lineHeight: "1rem",
                        paddingBottom: "8px",
                      }}
                    >
                      Required Skills
                    </Typography>
                    <Box>
                      {jobDescriptionData?.skills
                        ? jobDescriptionData.skills.map(
                            (skill: string, index: number) => {
                              return (
                                <Button
                                  key={index}
                                  variant="outlined"
                                  color="secondary"
                                  sx={{
                                    minWidth: "42px",
                                    height: "26px",
                                    marginRight: "16px",
                                  }}
                                >
                                  {skill}
                                </Button>
                              );
                            }
                          )
                        : []}
                    </Box>
                  </Box>
                )}
            </Box>
            <Typography
              ref={jobDescription}
              sx={{
                color: "#000",
                fontWeight: 400,
                fontSize: "1.25rem",
                margin: "24px 0px 8px 0px",
              }}
            >
              Job Description
            </Typography>
            <Box
              sx={{
                width: "686px",
                backgroundColor: "#FFFFFF",
                borderRadius: "5px",
                padding: "24px 24px 12px 24px",
                marginBottom: "50px",
              }}
            >
              <Box sx={{ marginBottom: "16px" }}>
                <Typography
                  sx={{
                    color: "#181818",
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "1rem",
                    paddingBottom: "8px",
                  }}
                >
                  Description
                </Typography>
                <Typography
                  sx={{
                    color: "#7A7A7A",
                    fontWeight: 400,
                    fontSize: "0.875rem",
                    lineHeight: "24px",
                  }}
                >
                  {jobDescriptionData?.description
                    ? jobDescriptionData.description
                    : ""}
                </Typography>
              </Box>
              {/* <Box sx={{ marginBottom: "16px" }}>
                <Typography
                  sx={{
                    color: "#181818",
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "1rem",
                    paddingBottom: "8px",
                  }}
                >
                  Job Req URL
                </Typography>
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{ height: "26px" }}
                >
                  Job Req URL
                </Button>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#181818",
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "1rem",
                    paddingBottom: "8px",
                  }}
                >
                  Attached Documents
                </Typography>
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{ height: "26px" }}
                >
                  Job Req
                </Button>
              </Box> */}
            </Box>
          </Box>
        </Box>
      )}
      <Modal open={modalIsOpen} onClose={closeModal}>
        <Box sx={{ ...modalStyle }}>
          <Box
            sx={{
              ...headerStyle,
              borderBottom: "1px solid #D9D9D9",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                padding: 2,
              }}
            >
              <Box>
                <ArrowBackIosIcon
                  onClick={closeModal}
                  sx={{ paddingTop: "4px" }}
                />
              </Box>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 400,
                  width: "100%",
                  fontSize: "20px",
                  textAlign: "center",
                  margin: "0 auto",
                }}
              >
                Job Description
              </Typography>
              <CloseOutlinedIcon
                onClick={closeModal}
                sx={{ marginLeft: "auto" }}
              />
            </Box>
          </Box>

          <Box sx={bodyStyle}>
            <JobDescription
              isOverviewTab={true}
              handleUpload={handleSubmitFile}
              handleUpdateJobDescription={handleUpdateJobDesc}
              activeRoleRequest={jobDescriptionData}
              isSearchCriteriaTab={true}
              activeRoleStep={AddNewRoleStep.ReviewDataAndConfirm}
              handleCancel={handleCancel}
              sendRequest={() => {}}
            ></JobDescription>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

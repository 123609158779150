import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const ForwardIcon = (props: any) => {
	const { fillColor, ...otherProps } = props;
	return (
		<SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
			<path
				d="M8.23431 5.22707C7.9219 5.53081 7.9219 6.02328 8.23431 6.32702L14.0686 11.9993L8.23431 17.6715C7.9219 17.9753 7.9219 18.4677 8.23431 18.7715C8.54673 19.0752 9.05327 19.0752 9.36568 18.7715L15.7657 12.5492C16.0781 12.2455 16.0781 11.753 15.7657 11.4493L9.36568 5.22707C9.05327 4.92333 8.54673 4.92333 8.23431 5.22707Z"
				fill={fillColor}
			/>
		</SvgIcon>
	);
};

import React, { useState, useEffect } from "react"; // eslint-disable-line no-unused-vars
import {
  Box,
  CircularProgress,
  Grid,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import { Candidate, FilterCandidates, StatusMatrix } from "../types";
import { Login, Busy, Notification, Dashboard, Header } from "../components";
import { useAuth0 } from "@auth0/auth0-react";

import {
  getCandidate,
  getCandidates,
  getProjectIds,
  getProjectsData,
  saveIdealCandidateProfilesData,
  submitRole,
  submitRoleViaFile,
  updateAllCandidates,
  updateCandidate,
  updateSearchCriteriaData,
} from "../utilities/actions";
import jwt_decode from "jwt-decode";
import { NavItems } from "../enums/nav-items";
import { Status } from "../enums/candidate-status";
import { AddNewRole } from "../components/roles/addNewRole";
import {
  IdealCandidateProfiles,
  RoleModalRequest,
  SearchCriteria,
} from "../utilities/roles";
import { ResizableTextarea } from "../utilities/textAreaAutosize";
import { bodyStyle, headerStyle, modalStyle } from "../utilities/modalStyles";
import { ReviewedCandidates } from "../types/tab";
import { JobDescriptionDetails } from "../enums/addRole";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export interface MainProps {}

export const Main = ({}: MainProps): JSX.Element => {
  const theme = useTheme();
  const [busy, setBusy] = useState<boolean>(true);
  const [busyDialog, setBusyDialog] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [impersonationId, setImpersonationId] = useState<string>("");
  const [selectedCandidateId, setSelectedCandidateId] = useState<
    string | null
  >();
  const [dialogType, setDialogType] = useState<string | null>();
  const [candidateFilter, setCandidateFilter] = useState<string>(
    NavItems.Dashboard
  );
  const [currentRoute, setCurrentRoute] = useState<NavItems>(
    NavItems.Dashboard
  );
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const { isLoading, isAuthenticated, getAccessTokenSilently, logout } =
    useAuth0();
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [isCandidatesExist, setCandidatesExist] = useState<boolean>(true);

  const [breadCrubsLinks, setBreadCrubsLink] = useState<string[]>([]);
  const [isRejectModalOpen, setRejectModalIsOpen] = useState<boolean>(false);
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate | null>(
    null
  );
  const [searchCriteriaResults, setSearchCriteriaResults] =
    useState<SearchCriteria>();
  const [idealCandidatesResults, setIdealCandidatesResults] =
    useState<IdealCandidateProfiles>();
  const [isConfirmationPopupOpen, setIsConfirmationModalPopupOpen] =
    useState(false);
  const [selectedCandidateStatusMatrix, setSelectedCandidateStatusMatrix] =
    useState<StatusMatrix>(
      selectedCandidate?.statusMatrix || ({} as StatusMatrix)
    );
  const [statusComent, setStatusComent] = useState<string>("");
  const [isLoadingProjects, setIsLoadingProjects] = useState<boolean>(false);
  const [isDevAccount, setIsDevAccount] = useState<boolean>(false);
  const [addNewRoleDataRequest, setAddNewRoleDataRequest] =
    useState<RoleModalRequest>({} as RoleModalRequest);
  const [projectsData, setProjectsData] = useState<RoleModalRequest[]>([]);
  const [roleData, setRoleData] = useState<RoleModalRequest>();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const checkAccountIsDev = (id: string) => {
    const devAcc = "auth0|64932277e4e1f79e8cb58fd8";
    const placeholderAcc = "auth0|64c2d84eaeb11a63f95c9ba4";

    if (id === devAcc || id === placeholderAcc) {
      setIsDevAccount(true);
    }
  };

  const fetchProjects = async () => {
    const get = async (): Promise<string[]> => {
      try {
        let token = await getAccessTokenSilently();
        let claims: { permissions: string[]; sub: string } = jwt_decode(token);
        checkAccountIsDev(claims.sub);
        setIsLoadingProjects(true);
        const projectsIds = await getProjectIds(token);
        const projectsDataPromises = projectsIds.map((projectId) => {
          return getProjectsData(projectId);
        });

        const projectsData = await Promise.all(projectsDataPromises);

        setProjectsData(projectsData);

        setIsLoadingProjects(false);
        return projectsIds;
      } catch (error) {
        console.error("Request failed:", error);
        return [];
      }
    };

    if (isAuthenticated) {
      return get();
    } else {
      setBusy(false);
      return null;
    }
  };

  const fetchResults = async (projectId?: string) => {
    const get = async (): Promise<void> => {
      let token = await getAccessTokenSilently();
      console.log(token);
      let claims: { permissions: string[]; sub: string } = jwt_decode(token);
      checkAccountIsDev(claims.sub);
      setIsAdmin(claims?.permissions.indexOf("admin:candidates") >= 0);
      if (projectId) {
        let results = await getCandidates(projectId, token);
        setCandidates(results);
      }
    };
    if (isAuthenticated) {
      get();
    } else {
      setBusy(false);
    }
  };

  const handleSubmitRoleWithFile = (file: File | null, title: string) => {
    const post = async (): Promise<void> => {
      let token = await getAccessTokenSilently();
      let claims: { permissions: string[]; sub: string } = jwt_decode(token);
      checkAccountIsDev(claims.sub);
      setIsAdmin(claims?.permissions.indexOf("admin:candidates") >= 0);
      let results = await submitRoleViaFile(file, title, token);
      if (results) {
        fetchProjects();
      }
    };
    if (isAuthenticated) {
      post();
      setCurrentRoute(NavItems.Dashboard);
    }
  };

  const handleSubmitRoleWithData = (roleData: RoleModalRequest) => {
    const post = async (): Promise<void> => {
      let token = await getAccessTokenSilently();
      let claims: { permissions: string[]; sub: string } = jwt_decode(token);
      checkAccountIsDev(claims.sub);
      setIsAdmin(claims?.permissions.indexOf("admin:candidates") >= 0);
      let results = await submitRole(roleData, token);
      setRoleData(results);
    };
    if (isAuthenticated) {
      post();
      setCurrentRoute(NavItems.Dashboard);
    }
  };

  const handleUpdateJobDescriptionData = (jobDesData: RoleModalRequest) => {
    handleSubmitRoleWithData(jobDesData);
  };

  const saveIdealCandidateProfiles = (
    idealCandidateProfiles: IdealCandidateProfiles,
    projectId?: string
  ) => {
    console.log(idealCandidateProfiles, projectId);
    if (idealCandidateProfiles.idealProfiles && projectId) {
      let results = saveIdealCandidateProfilesData(
        idealCandidateProfiles,
        projectId
      );
      setIdealCandidatesResults(idealCandidateProfiles);
    }
  };

  const updateSearchParameters = (
    searchParameters: SearchCriteria,
    projectId?: string
  ) => {
    if (
      (searchParameters.notes ||
        searchParameters.needs ||
        searchParameters.qualified) &&
      projectId
    ) {
      let results = updateSearchCriteriaData(searchParameters, projectId);
      console.log(searchParameters);
      setSearchCriteriaResults(searchParameters);
    }
  };

  const handleCancel = (): void => {
    setCurrentRoute(NavItems.Dashboard);
  };

  const handleStatusCommentChange = (event: any): void => {
    setStatusComent((prevVal: string) => event.target.value);
  };

  const handleLogout = () => {
    logout();
  };

  const getSingleCandidate = async (
    projectId: string,
    candidateId: string
  ): Promise<Candidate | undefined> => {
    let results: Candidate | undefined = undefined;
    if (projectId && candidateId) {
      try {
        results = await getCandidate(projectId, candidateId);
        if (results) {
          setStatusComent(results.statusComments);
          setSelectedCandidate(results);
        }
      } catch (error) {
        console.error("Error fetching candidate:", error);
      }
    }
    return results;
  };

  const handleUpdateChangesOnPopupModal = async (
    statusComentRequest: string
  ) => {
    setRejectModalIsOpen(false);
    const updatedCandidate: Candidate = {
      ...selectedCandidate!,
      status: Status.Rejected,
      statusComments: statusComentRequest,
      statusMatrix: selectedCandidateStatusMatrix || ({} as StatusMatrix),
    };

    if (updatedCandidate) {
      await updateCandidateData(
        updatedCandidate,
        selectedCandidate!.id,
        selectedCandidate!.status
      );

      await fetchResults(selectedCandidate?.projectId);
    }
  };

  const handleUpdateCandidate = async (
    updtCandidateId: string,
    candidateData: Candidate,
    action: string
  ) => {
    await getSingleCandidate(candidateData!.projectId, candidateData!.id);

    if (action === Status.Accepted) {
      const updatedCandidate: Candidate = {
        ...candidateData!,
        statusComments: candidateData.statusComments,
        status: Status.Accepted,
      };
      await updateCandidateData(updatedCandidate, updtCandidateId, action);
      await fetchResults(candidateData.projectId);
    }

    if (action === "review") {
      setRejectModalIsOpen(true);
    }

    if (action === Status.Rejected) {
      setRejectModalIsOpen(true);
    }
  };

  const updateCandidateData = async (
    candidate: Candidate | null,
    candidateId: string,
    action: string
  ) => {
    setBusyDialog(true);
    if (candidate && candidateId) {
      let token = await getAccessTokenSilently();
      let results = await updateCandidate(
        candidate!.projectId,
        candidateId,
        candidate!,
        token
      );
    }

    let updateCandidates = candidates.slice();
    updateCandidates.map((updCandidate) => {
      if (updCandidate.id === candidateId) {
        updCandidate.status = candidate!.status;
        updCandidate.statusComments = candidate!.statusComments;
        updCandidate.statusMatrix = candidate!.statusMatrix;
      }
    });

    setCandidates(updateCandidates);
    setSelectedCandidateId(null);
    setDialogType(null);
    setBusyDialog(false);
  };

  const handleAcceptAll = async (
    filter: FilterCandidates,
    selectedTab: ReviewedCandidates
  ) => {
    const projectId = candidates[0].projectId;
    const acceptAllCandidates =
      filter === 70
        ? candidates
            .filter((candidate: Candidate) => {
              if (
                (candidate.status === Status.Pending || candidate.status === Status.Crawled) &&
                selectedTab === ReviewedCandidates.Qualified
              ) {
                return candidate.confidence * 100 >= 70;
              } else if (
                candidate.status === Status.Rejected &&
                selectedTab === ReviewedCandidates.Reviewed
              ) {
                return candidate.confidence * 100 >= 70;
              }
            })
            .map((candidate: Candidate) => candidate.id)
        : filter === 60
        ? candidates
            .filter((candidate: Candidate) => {
              if (
                (candidate.status === Status.Pending || candidate.status === Status.Crawled) &&
                selectedTab === ReviewedCandidates.Qualified
              ) {
                return candidate.confidence * 100 < 70;
              } else if (
                candidate.status === Status.Rejected &&
                selectedTab === ReviewedCandidates.Reviewed
              ) {
                return candidate.confidence * 100 < 70;
              }
            })
            .map((candidate: Candidate) => candidate.id)
        : candidates
            .filter((candidate: Candidate) => {
              if (
                (candidate.status === Status.Pending || candidate.status === Status.Crawled) &&
                selectedTab === ReviewedCandidates.Qualified
              ) {
                return candidate.status;
              } else if (
                candidate.status === Status.Rejected &&
                selectedTab === ReviewedCandidates.Reviewed
              ) {
                return candidate.status;
              }
            })
            .map((candidate: Candidate) => candidate.id);
    const candidatesData = candidates.map((candidate) => ({
      ...candidate,
      status:
        filter === 70
          ? candidate.confidence * 100 >= 70 &&
            candidate.status === Status.Rejected &&
            selectedTab === ReviewedCandidates.Reviewed
            ? Status.Accepted
            : candidate.confidence * 100 >= 70 &&
            (candidate.status === Status.Pending || candidate.status === Status.Crawled) &&
              selectedTab === ReviewedCandidates.Qualified
            ? Status.Accepted
            : candidate.status
          : filter === 60
          ? candidate.confidence * 100 < 70 &&
            candidate.status === Status.Rejected &&
            selectedTab === ReviewedCandidates.Reviewed
            ? Status.Accepted
            : candidate.confidence * 100 < 70 &&
            (candidate.status === Status.Pending || candidate.status === Status.Crawled)&&
              selectedTab === ReviewedCandidates.Qualified
            ? Status.Accepted
            : candidate.status
          : (candidate.status === Status.Pending || candidate.status === Status.Crawled)&&
            selectedTab === ReviewedCandidates.Qualified
          ? Status.Accepted
          : candidate.status === Status.Rejected &&
            selectedTab === ReviewedCandidates.Reviewed
          ? Status.Accepted
          : candidate.status,
    }));

    let results = await updateAllCandidates(
      projectId,
      Status.Accepted,
      acceptAllCandidates
    );

    setCandidates(candidatesData);
    setSelectedCandidateId(null);
    setDialogType(null);
    setBusyDialog(false);
  };
  useEffect(() => {
    fetchProjects();
    setCurrentRoute(NavItems.Dashboard);
  }, [isLoading]);

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 695,
    width: "90%",
    bgcolor: "background.paper",
    borderRadius: 6,
    outline: "none",
    overflow: "hidden",
    p: 1,
  };
  const confirmationStyleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 438,
    bgcolor: "background.paper",
    borderRadius: 6,
    outline: "none",
    overflow: "hidden",
    paddingX: 1,
  };

  const handleButtonGroupChoose = (label: string, key: string) => {
    const updatedCandidate: Candidate = JSON.parse(
      JSON.stringify(selectedCandidate)
    );
    setSelectedCandidateStatusMatrix({
      ...updatedCandidate.statusMatrix,
      ...selectedCandidateStatusMatrix,
      [key]: label,
    });
    setIsConfirmationModalPopupOpen(true);
  };

  const handleRejectUser = async (statusComentRequest: string) => {
    setRejectModalIsOpen(false);

    const updatedCandidate: Candidate = {
      ...selectedCandidate!,
      id: selectedCandidate!.id || "",
      status: Status.Rejected,
      statusComments: selectedCandidate?.statusComments || statusComentRequest,
      statusMatrix: selectedCandidateStatusMatrix || ({} as StatusMatrix),
    };

    if (updatedCandidate) {
      await updateCandidateData(
        updatedCandidate,
        selectedCandidate!.id,
        selectedCandidate!.status
      );
    }
  };

  const handleResetProgres = () => {
    setRejectModalIsOpen(false);
    setIsConfirmationModalPopupOpen(false);
    console.log("reseting...");
    setSelectedCandidateStatusMatrix({} as StatusMatrix);
  };

  const handleRouteChange = (route: NavItems) => {
    setCurrentRoute(route);
    setModalIsOpen(true);
  };

  const handleAddNewRoleRedirect = (
    data: JobDescriptionDetails,
    route: NavItems
  ) => {
    setCurrentRoute(route);
    const roleForRequest: RoleModalRequest = {
      projectId: "",
      title: data.jobTitle,
      description: data.notes,
      department: data.workType,
      location: data.location,
      environment: data.workEnviroment,
      role: data.roleCategory,
      degree: data.degree,
      minYearsExperience: data.minYearsOfExp.toString(),
      maxYearsExperience: data.maxYearsOfExp.toString(),
      skills: data.requiredSkils,
      status: "Active",
      stages: [],
      searchCriteria: [],
      idealCandidateProfiles: [],
    };
    setAddNewRoleDataRequest(roleForRequest);
    setCurrentRoute(route);
  };

  useEffect(() => {
    if (addNewRoleDataRequest) {
      handleSubmitRoleWithData(addNewRoleDataRequest);
      setTimeout(() => {
        fetchProjects();
      }, 200);
    }
  }, [addNewRoleDataRequest]);

  const routeSet = (link: string) => {
    if (link === "Dashboard") {
      fetchProjects();
    }
  };
  useEffect(() => {
    if (currentRoute === NavItems.AddNewRole) {
      openModal();
    }
  }, [currentRoute]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#F7F7FF",
        }}
      >
        {isAuthenticated && (
          <Header
            logout={handleLogout}
            showMenu={isAuthenticated}
            filter={candidateFilter}
            handleFilterChange={(filter) => {
              setCandidateFilter(filter);

              if (filter === NavItems.Dashboard) {
                setBreadCrubsLink([]);
              }
            }}
          />
        )}
        {busy || isLoading ? (
          isLoading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <CircularProgress size={50} />
            </Box>
          ) : (
            <Busy withoutText={isLoading} />
          )
        ) : (
          <Box>
            <Notification
              message={notification.message}
              type={notification.type}
              open={notification.message.length > 0}
              onDismiss={() => {
                setNotification({ message: "", type: "" });
              }}
            />

            {isAuthenticated ? (
              <>
                <Grid
                  container
                  maxWidth="95%"
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  sx={{ flexGrow: 1, mt: "40px" }}
                >
                  {/* {candidateFilter === NavItems.Profile && (
										<>
											<button
												className={styles["accept-button"]}
												onClick={() => {
													setModalOpen(true);
												}}
											>
												Accept All
											</button>
											{modalOpen && (
												<Modal
													setOpenModal={setModalOpen}
													handleConfirm={handleAcceptAll}
													title={modalData.title}
													body={modalData.body}
													cancelLabel={modalData.cancelLabel}
													confirmLabel={modalData.confirmLabel}
												/>
											)}
										</>
									)} */}
                </Grid>
                {isLoadingProjects ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100vh",
                    }}
                  >
                    <CircularProgress size={50} />
                  </Box>
                ) : (
                  <>
                    <Dashboard
                      isDevAccount={isDevAccount}
                      candidates={candidates}
                      isAdmin={isAdmin}
                      busy={busy}
                      roleData={roleData}
                      filter={candidateFilter}
                      breadCrumbsLink={breadCrubsLinks}
                      searchCriteriaResults={searchCriteriaResults}
                      idealCandidatesResults={idealCandidatesResults}
                      projectsData={projectsData}
                      isRejectModalOpen={isRejectModalOpen}
                      isConfirmationPopupOpen={isConfirmationPopupOpen}
                      handleButtonGroupChoose={handleButtonGroupChoose}
                      handleDisqualifyUserFromModal={handleRejectUser}
                      handleUpdateChangesDisqualifyModal={
                        handleUpdateChangesOnPopupModal
                      }
                      handleAcceptAll={handleAcceptAll}
                      handleGetCandidates={fetchResults}
                      handleOpenCandidateNotes={handleUpdateCandidate}
                      handleUpdateJobDescriptionData={
                        handleUpdateJobDescriptionData
                      }
                      saveIdealCandidateProfiles={saveIdealCandidateProfiles}
                      updateSearchParameters={updateSearchParameters}
                      handleImpersonate={async () => {
                        setBusy(true);
                        let token = await getAccessTokenSilently();
                        let results = await getCandidates(
                          impersonationId,
                          token
                        );
                        setCandidates(results);
                        setBusy(false);
                      }}
                      handleRouteChange={handleRouteChange}
                      handleResetProgres={handleResetProgres}
                      setCurrentRoute={routeSet}
                    />

                    {currentRoute === NavItems.AddNewRole && (
                      <Modal open={modalIsOpen} onClose={closeModal}>
                        <Box
                          sx={{
                            ...modalStyle,
                            height: "fit-content",
                          }}
                        >
                          <Box
                            sx={{
                              ...headerStyle,
                              borderBottom: "1px solid #D9D9D9",
                              cursor: "pointer",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center",
                                padding: 2,
                              }}
                            >
                              <Box></Box>
                              <Typography
                                sx={{
                                  color: "#000",
                                  fontWeight: 700,
                                  width: "100%",
                                  fontSize: "20px",
                                  textAlign: "center",
                                  margin: "0 auto",
                                }}
                              >
                                Job Description
                              </Typography>
                              <CloseOutlinedIcon
                                onClick={closeModal}
                                sx={{ marginLeft: "auto" }}
                              />
                            </Box>
                          </Box>

                          <Box sx={bodyStyle}>
                            {" "}
                            <AddNewRole
                              handleSubmitRoleWithFile={
                                handleSubmitRoleWithFile
                              }
                              sendRequest={handleAddNewRoleRedirect}
                              handleCancel={handleCancel}
                            ></AddNewRole>
                          </Box>
                        </Box>
                      </Modal>
                    )}
                  </>
                )}
              </>
            ) : (
              <Login />
            )}
          </Box>
        )}
      </div>
    </>
  );
};
